export const questionType = Object.freeze({
  DATE: 0,
  INTEGER: 1,
  TEXT: 2,
  MULTITEXT: 3,
  SELECT: 4,
  DROPDOWN: 5,
  BOOLEAN: 6,
  CHECKBOX: 7,
  OPTION: 8,
  PLAINTEXT: 9,
  OCCUPIER: 10,
});
