import Stack from '@mui/material/Stack';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import React, { useState } from 'react';
import { style } from '../../../../../style/style';
import {
  getAccessToken,
  WebServiceClient,
} from '../../../../../services/WebServiceClient';
import { downloadFile } from '../../../../../utils/DownloadFile';
import { LocalStorageService } from '../../../../../services/LocalStorageService';

const STEPS = Object.freeze({
  initial: 1,
});

export const MortgageDeedReceived = props => {
  const [step, setStep] = useState(STEPS.initial);
  const org = JSON.parse(LocalStorageService.get('organisation'));
  const renderStep = step => {
    switch (step) {
      case STEPS.initial:
        return (
          <>
            <Stack>
              <p style={{ fontSize: '12px', margin: '0', whiteSpace: 'pre-line' }}>
                {props.milestone.bodyText}
              </p>
              <Button
                variant="contained"
                style={{
                  color: 'white',
                  // height: '40px',
                  // width: '100px',
                  margin: '10px',
                }}
                sx={style.darkGreenButton}
                onClick={async () => {
                  const config = {
                    headers: { Authorization: getAccessToken() },
                  };
                  const params = {
                    orgId: org.id,
                    siteId: null,
                    documentName: props.milestone.toPortal.find(
                      x => x.dataTag === 'DownloadDocument',
                    ).file,
                  };
                  let ret = await WebServiceClient.get(
                    `/supporting-document/${props.mortgage.Mortgage.id}`,
                    params,
                    config,
                  );

                  if (ret.status != 200) {
                    /// error
                  } else {
                    downloadFile(ret.data.fileUrl, props.milestone.file);
                  }
                }}
              >
                Download Document
              </Button>
            </Stack>
          </>
        );
    }
  };

  return (
    <Paper style={style.remortgage}>
      <div>
        <p style={{ fontSize: '16px', margin: '5' }}>Mortgage deed issued</p>
        <div style={{ display: 'flex' }}>
          <div key={props.index}>{renderStep(step)}</div>
        </div>
      </div>
    </Paper>
  );
};
