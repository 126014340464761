import { styled } from "@mui/material/styles";
import TextField from "@mui/material/TextField";

export const CssTextField = styled(TextField)({
  "& .MuiOutlinedInput-root": {
    paddingTop: "0px",
    "& input": {
      color: "#070606",
      fontWeight: 600,
      padding: "5px",
      "&::placeholder": {
        fontSize: "12px",
        color: "#070606",
        textAlign: "center",
      },
    },
    "& fieldset": {
      border: "2px solid #418ED1",
      borderRadius: "6px",
      boxShadow: "0 0 2px 1px #418ED1",
      //backgroundColor: "transparent",
    },
    "&:hover fieldset": {
      border: "2px solid #418ED1",
    },
    "&.Mui-focused fieldset": {
      //border: "2px solid #418ED1",
    },
    "input.error ": {
      borderColor: "red",
    },
  },
});
