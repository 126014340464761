import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Paper from '@mui/material/Paper';
import React, { useState } from 'react';
import { style } from '../../../../../style/style';
import CssCheckbox from '../components/CssCheckbox';
import DateSelect from '../components/DateSelect';
import dayjs from 'dayjs';
var utc = require("dayjs/plugin/utc");
dayjs.extend(utc);

const STEPS = Object.freeze({
  initial: 1,
});

export const UnexpectedERC = props => {
  const [outputs, setOutputs] = useState({
    ercExpiryDate: null,
  });
  const [optionSelection, setOptionSelection] = useState({
    expire: false,
    dontExpire: false,
    waiveERC: false,
  });
  const [Option, setOption] = useState('');
  const [step, setStep] = useState(STEPS.initial);

  const onChangeCheckbox = e => {
    let optionSelectionNew = { ...optionSelection };
    optionSelectionNew['expire'] = false;
    optionSelectionNew['dontExpire'] = false;
    optionSelectionNew['waiveERC'] = false;
    optionSelectionNew[e.target.name] = !!e.target.value;
    setOptionSelection(optionSelectionNew);

    setOption(e.target.name);
  };

  const renderStep = step => {
    switch (step) {
      case STEPS.initial:
        return (
          <Stack sx={{ minWidth: 0 }}>
            <p style={{ fontSize: '12px', margin: '1', whiteSpace: 'pre-line' }}>
              {props.milestone.bodyText}
            </p>
            <div>
              <p style={{ fontSize: '12px', margin: '3' }}>
                1. What's the ERC expiry date?
              </p>
            </div>
            <div style={{ padding: 5 }}>
              <DateSelect
                name={'ercExpiryDate'}
                label="ERC Expiry Date"
                date={outputs['ercExpiryDate']}
                setDate={date => {
                  const updatedQuestions = { ...outputs };
                  date = dayjs(date).utc(true).toISOString()
                  updatedQuestions['ercExpiryDate'] = date;
                  setOutputs(updatedQuestions);
                }}
              />
            </div>

            <div>
              <p style={{ fontSize: '12px', margin: '3' }}>
                2. Do you want to wait for the ERC to expire before we complete?
              </p>
            </div>
            <div>
              <CssCheckbox
                name="expire"
                label="Yes please wait until my ERC has expired before completing"
                checked={optionSelection['expire']}
                onClick={e => {
                  onChangeCheckbox(e);
                }}
              />
            </div>
            <CssCheckbox
              name="dontExpire"
              label="No, I am happy to pay the ERC and want to complete before it expires"
              checked={optionSelection['dontExpire']}
              onClick={e => {
                onChangeCheckbox(e);
              }}
            />
            <CssCheckbox
              name="waiveERC"
              label="I am remortgaging to the same lender and as part of the agreement I am expecting them to waive the requirement for me to pay the ERC on the existing account"
              checked={optionSelection['waiveERC']}
              onClick={e => {
                onChangeCheckbox(e);
              }}
            />
            <Button
              variant="contained"
              style={{
                color: 'white',
                // height: '40px',
                // width: '45%',
                margin: '10px',
              }}
              sx={style.darkGreenButton}
              onClick={() => {
                return props.submitFnc(props.index, {
                  Option: Option,
                  ERCExpiryDate: outputs['ercExpiryDate'],
                });
              }}
              disabled={
                (!optionSelection['waiveERC'] &&
                  !optionSelection['dontExpire'] &&
                  !optionSelection['expire']) ||
                !outputs.ercExpiryDate
              }
            >
              Submit
            </Button>
          </Stack>
        );
    }
  };

  return (
    <div>
      <Paper style={style.remortgage}>
        <p style={{ fontSize: '16px', margin: '5' }}>
          Unexpected Early Repayment Charge
        </p>
        <div style={{ display: 'flex' }}>
          <div key={props.index}>{renderStep(step)}</div>
        </div>
      </Paper>
    </div>
  );
};
