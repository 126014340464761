import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Formik } from "formik";

// components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import { CardMedia } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Link from "@mui/material/Link";
import Alerts from "../shared/Alerts";
import CircularProgress from "@mui/material/CircularProgress";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import HelpIcon from "@mui/icons-material/Help";
import InputAdornment from "@mui/material/InputAdornment";
// data
import { signup } from "../../resources/signup-data";
// style
import { style } from "../../style/style";
import { styled } from "@mui/material/styles";
import { create_schema } from "../../config/validation";
import { WebServiceClient } from "../../services/WebServiceClient";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import { color } from "../../style/color";
// services

const CssTextField = styled(TextField)({
  "& .MuiOutlinedInput-root": {
    paddingTop: "0px",
    "& input": {
      color: "white",
      "&::placeholder": {
        color: "white",
        textAlign: "center",
      },
    },
    "& fieldset": {
      borderColor: "white",
      backgroundColor: "transparent",
    },
    "&:hover fieldset": {
      borderColor: "white",
    },
    "&.Mui-focused fieldset": {
      borderColor: "white",
    },
  },
});

export default function Create() {
  const navigate = useNavigate();
  const location = useLocation();
  const [alert, setAlert] = useState({});
  const [loading, setLoading] = useState(false);

  const [signupData, setSignupData] = useState(signup.create);
  const onClickLink = (values) => {
    navigate("/landing");
  };

  const onSubmit = (values) => {
    const data = {
      email: values.email,
      matterReference: values.reference,
      postcode: values.postcode,
    };

    WebServiceClient.post("/knights-auth/register-borrower", data, {}, {})
      .then(function (response) {
        if (response?.data?.userType === "EXISTING_USER") {
          navigate("/login", {
            state: {
              email: values.email,
              alert: {
                open: true,
                severity: "success",
                message: signup.message.activate,
                action: false,
              },
            },
          });
        } else if (response?.data?.userType === "NEW_USER") {
          navigate("/create-password", {
            state: {
              email: values.email,
              reference: values.reference,
              postcode: values.postcode,
              token: response?.data?.passwordResetToken,
            },
          });
        } else {
          navigate("/login", {
            state: {
              email: location.state.email,
              alert: {
                open: true,
                severity: "error",
                message: "Account already exists. Please login.",
                action: false,
              },
            },
          });
        }
      })
      .catch(function (error) {
        console.debug(error)
        if (error.response.status === 400) {
          navigate("/login", {
            state: {
              email: values.email,
              alert: {
                open: true,
                severity: "error",
                message: signup.errors.invalid_credentials,
                action: false,
              },
            },
          });
        } else {
          setAlert({
            open: true,
            severity: "error",
            message: signup.errors.error_activate,
            action: false,
          });
        }
      });
  };

  const handleTermsClick = (e) => {
    window.open(
      "https://www.knightsplc.com/our-policies/acceptable-use-policy/",
      "_blank"
    );
  };

  return (
    <Grid item xs={12}>
      {loading ? (
        <CircularProgress
          size={68}
          sx={{
            color: "white",
            position: "absolute",
            margin: "auto",
            zIndex: 1,
          }}
        />
      ) : (
        <Card sx={style.signup} elevation={0}>
          <CardMedia style={style.logo} alt="Integrar Logo" />
          <CardHeader
            title=""
            subheader={signupData.subTitle}
            titleTypographyProps={{ variant: "h2" }}
            subheaderTypographyProps={{
              variant: "h5",
              color: "secondary.main",
            }}
          />
          <Formik
            validationSchema={create_schema}
            initialValues={{
              reference: "",
              postcode: "",
              email: "",
              terms: false,
            }}
            onSubmit={(values) => {
              onSubmit(values);
            }}
          >
            {({ values, errors, touched, handleChange, handleSubmit }) => {
              return (
                <form noValidate onSubmit={handleSubmit}>
                  <CardContent>
                    {signupData.description !== "" ? (
                      <Typography variant="caption">
                        {signupData.description}
                      </Typography>
                    ) : null}
                    <CssTextField
                      name="reference"
                      placeholder={signupData.reference_label}
                      sx={style.cssTextField}
                      style={{ marginTop: "20px" }}
                      value={values.reference}
                      onChange={handleChange}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <Tooltip title="Your reference number will start with ****/*** (for example: W1542/12345) and can be found in the subject header of our email correspondence to you">
                              <IconButton>
                                <HelpIcon />
                              </IconButton>
                            </Tooltip>
                          </InputAdornment>
                        ),
                      }}
                    />
                    <Card
                      className="error"
                      sx={
                        errors.postcode && touched.reference && errors.reference
                          ? style.error
                          : ""
                      }
                    >
                      {errors.reference &&
                        touched.reference &&
                        errors.reference}
                    </Card>
                    <CssTextField
                      name="postcode"
                      placeholder={signupData.postcode_label}
                      sx={style.cssTextField}
                      value={values.postcode}
                      onChange={handleChange}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <Tooltip title="Please provide the postcode of the property to be re-mortgaged">
                              <IconButton>
                                <HelpIcon />
                              </IconButton>
                            </Tooltip>
                          </InputAdornment>
                        ),
                      }}
                    />
                    <Card
                      className="error"
                      sx={
                        errors.postcode && touched.postcode && errors.postcode
                          ? style.error
                          : ""
                      }
                    >
                      {errors.postcode && touched.postcode && errors.postcode}
                    </Card>
                    <CssTextField
                      name="email"
                      placeholder={signupData.email_label}
                      sx={style.cssTextField}
                      value={values.email}
                      onChange={handleChange}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <Tooltip title="Please check and enter the email address you have used for this mortgage application, if you need further assistance please call 0113 513 5375">
                              <IconButton>
                                <HelpIcon />
                              </IconButton>
                            </Tooltip>
                          </InputAdornment>
                        ),
                      }}
                    />
                    <Card
                      className="error"
                      sx={
                        errors.email && touched.email && errors.email
                          ? style.error
                          : ""
                      }
                    >
                      {errors.email && touched.email && errors.email}
                    </Card>

                    <FormControlLabel
                      control={
                        <Checkbox
                          onChange={handleChange}
                          name="terms"
                          value={values.terms}
                          color="secondary"
                          sx={{
                            color: "white",
                          }}
                        />
                      }
                      label={
                        <span
                          onClick={() => handleTermsClick()}
                          style={{
                            textDecoration: "underline",
                            color: color.smoke,
                          }}
                        >
                          {signupData.terms_condition_label}
                        </span>
                      }
                    />
                    <Card
                      className="error"
                      sx={
                        errors.terms && touched.terms && errors.terms
                          ? style.error
                          : ""
                      }
                    >
                      {errors.terms && touched.terms && errors.terms}
                    </Card>
                    <CardActions
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                      }}
                    >
                      <Button
                        type="submit"
                        variant="contained"
                        color="button"
                        sx={{ width: "350px", color: "white" }}
                      >
                        {signupData.submit_label}
                      </Button>
                    </CardActions>
                  </CardContent>

                  <CardContent>
                    <Box direction="row">
                      <Link
                        style={{ cursor: "pointer" }}
                        underline="hover"
                        variant="caption2"
                        color="secondary.main"
                        onClick={() => onClickLink()}
                      >
                        {signupData.link_label}
                      </Link>
                    </Box>
                  </CardContent>
                </form>
              );
            }}
          </Formik>
        </Card>
      )}
      <Alerts alert={alert} setAlert={setAlert} />
    </Grid>
  );
}
