import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Formik } from "formik";

// components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Link from "@mui/material/Link";
import { CardMedia } from "@mui/material";
import Alerts from "../shared/Alerts";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import HelpIcon from "@mui/icons-material/Help";
import InputAdornment from "@mui/material/InputAdornment";
// style
import { style } from "../../style/style";
import { styled } from "@mui/material/styles";

// data
import { SignupUtils } from "../../utils/SignupUtils";
import { SignupType } from "../../enums/SignupType";
import { email_reset_schema } from "../../config/validation";
import { signup } from "../../resources/signup-data";

// services
import { WebServiceClient } from "../../services/WebServiceClient";

const CssTextField = styled(TextField)({
  "& .MuiOutlinedInput-root": {
    paddingTop: "0px",
    "& input": {
      color: "white",
      "&::placeholder": {
        color: "white",
        textAlign: "center",
      },
    },
    "& fieldset": {
      borderColor: "white",
      backgroundColor: "transparent",
    },
    "&:hover fieldset": {
      borderColor: "white",
    },
    "&.Mui-focused fieldset": {
      borderColor: "white",
    },
  },
});

export default function EmailReset() {
  const navigate = useNavigate();
  const location = useLocation();
  const [alert, setAlert] = useState({});

  const [signupData, setSignupData] = useState(
    SignupUtils.getSignupData(SignupType.RESET)
  );

  const onSubmit = (values) => {
    const data = {
      email: values.email,
    };

    WebServiceClient.post(
      "/authentication/request/password-reset",
      data,
      {},
      {}
    )
      .then(function (response) {
        navigate("/login", {
          state: {
            email: values.email,
            // password: values.password,
            alert: {
              open: true,
              severity: "success",
              message: signup.message.reset,
              action: false,
            },
          },
        });
      })
      .catch(function (error) {
        if (error?.response?.status === 400) {
          setAlert({
            open: true,
            severity: "error",
            message: signup.errors.reset,
          });
        }
      });
  };

  const onClickLink = (values) => {
    navigate("/login");
  };

  return (
    <Grid item xs={12}>
      <Card sx={style.signup} elevation={0}>
        <CardMedia style={style.logo} alt="Integrar Logo" />
        <CardHeader
          title=""
          subheader={signupData.subTitle}
          titleTypographyProps={{ variant: "h2" }}
          subheaderTypographyProps={{
            variant: "h5",
            color: "secondary.main",
          }}
        />
        <Formik
          validationSchema={email_reset_schema}
          initialValues={{
            email: location.state ? location.state.email : "",
          }}
          onSubmit={(values) => {
            onSubmit(values);
          }}
        >
          {({ values, errors, touched, handleChange, handleSubmit }) => (
            <form noValidate onSubmit={handleSubmit}>
              <CardContent>
                <CssTextField
                  name="email"
                  placeholder={signupData.email_label}
                  sx={style.cssTextField}
                  value={values.email}
                  onChange={handleChange}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Tooltip title="Please check and enter the email address you have used for this mortgage application, if you need further assistance please call 0113 513 5375">
                          <IconButton>
                            <HelpIcon />
                          </IconButton>
                        </Tooltip>
                      </InputAdornment>
                    ),
                  }}
                />
                <Card
                  className="error"
                  sx={
                    errors.email && touched.email && errors.email
                      ? style.error
                      : ""
                  }
                >
                  {errors.email && touched.email && errors.email}
                </Card>
              </CardContent>
              <CardActions
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <Button
                  type="submit"
                  variant="contained"
                  color="button"
                  sx={style.buttonStyle}
                >
                  {signupData.submit_label}
                </Button>
              </CardActions>
              <CardContent>
                <Box direction="row">
                  <Link
                    underline="hover"
                    variant="caption2"
                    color="secondary.main"
                    onClick={() => onClickLink()}
                  >
                    {signupData.link_label}
                  </Link>
                </Box>
              </CardContent>
            </form>
          )}
        </Formik>
      </Card>
      <Alerts alert={alert} setAlert={setAlert} />
    </Grid>
  );
}
