import { useEffect, useState } from "react";
import ReactGA from 'react-ga4'

// components
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Paper from "@mui/material/Paper";
import { Button } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import DownloadIcon from "@mui/icons-material/Download";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

// setting
import { timeAgo } from "../../../config/setting";
// data
import { color } from "../../../style/color";

// component
import UploadDocumentDialog from "../../dialogs/UploadDocumentDialog";

// services
import { WebServiceClient, getAccessToken } from "../../../services/WebServiceClient";
import { LocalStorageService } from "../../../services/LocalStorageService";
// style
import { style } from "../../../style/style";
import { styled } from "@mui/material/styles";

export default function YourDocuments(props) {
  const token = getAccessToken();
  const org = JSON.parse(LocalStorageService.get("organisation"));
  const user = JSON.parse(LocalStorageService.get("user"));

  const [expanded, setExpanded] = useState("panel1");
  const [openDialog, setOpenDialog] = useState(false);
  const [records, setRecords] = useState([]);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const getRecords = () => {
    const config = {
      headers: { Authorization: token },
    };
    const params = {
      orgId: org.id,
      siteId: null,
      where: {
        owner: user.id,
      },
    };
    WebServiceClient.get("/record", params, config)
      .then(function (response) {
        if (response.data) {
          let records = response.data.map((r) => {
            return {
              type: r.description,
              date: r.createdAt,
              url: r.fileUrl,
            };
          });
          setRecords(records);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  useEffect(() => {
    getRecords();
  }, []);

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: '/your-documents' })
  })

  const CssAccordion = styled(Accordion)({
    marginBottom: "15px",
    borderRadius: "4px",
    "& .Mui-expanded ": {
      borderRadius: "4px",
      backgroundColor: "#90cbb5",
      minHeight: "48px !important",
      maxHeight: "48px !important",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      margin: "0",
    },
    "& .MuiAccordion-rounded": {
      marginBottom: "10px",
    },
    "& .MuiAccordionSummary-root": {
      padding: "0 10px",
      paddingBottom: 0,
      borderRadius: "4px",
      "& .MuiTypography-root": {
        color: "white !important",
      },
    },
    "& .MuiAccordionDetails-root": {
      padding: "20px",
      textAlign: "left",
      backgroundColor: color.pastelGreen,
    },
    "& .MuiSvgIcon-root": {
      color: "white",
    },
    "& .MuiTypography-root": {
      color: "white",
      paddingBottom: "10px",
    },
    "& .MuiOutlinedInput-root": {
      paddingTop: "0px",
      "& input": {
        color: "white",
        "&::placeholder": {
          color: "white",
          textAlign: "center",
        },
      },
    },
  });
  const CssTableRow = styled(TableRow)({
    root: {
      height: "20px",
    },
  });

  return (
    <Paper style={style.remortgage}>
      <div style={{ padding: "20px" }}>
        <CssAccordion
          expanded={expanded === "panel1"}
          onChange={handleChange("panel1")}
        >
          <AccordionSummary
            style={{ height: "30px", backgroundColor: color.brandLightGreen }}
            expandIcon={<ExpandMoreIcon />}
          >
            <Typography style={{ color: "black" }}>
              Upload a document{" "}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            {/*<Typography
              style={{ color: "black", textAlign: "left", fontWeight: "900" }}
            >
              1. Marriage certificate
            </Typography>
            <Typography style={{ color: "black", textAlign: "left" }}>
              Donec placerat, lectus sed mattis semper, neque lectus feugiat
              lectus, varius pulvinar diam eros in elit. Pellentesque convallis
              laoreet laoreet.
  </Typography>*/}
            <Button
              variant="contained"
              style={{ backgroundColor: color.brandDarkBlue }}
              onClick={() => {
                setOpenDialog(true);
              }}
            >
              Upload Document
            </Button>
            <UploadDocumentDialog
              openDialog={openDialog}
              setOpenDialog={setOpenDialog}
              mortgage={props.mortgage}
              setExpanded={setExpanded}
              setRecords={setRecords}
              records={records}
            />
          </AccordionDetails>
        </CssAccordion>
        <CssAccordion
          expanded={expanded === "panel2"}
          onChange={handleChange("panel2")}
        >
          <AccordionSummary
            style={{ height: "30px", backgroundColor: color.brandLightGreen }}
            expandIcon={<ExpandMoreIcon />}
          >
            <Typography>Documents uploaded</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell
                      style={{
                        color: "black",
                        fontWeight: 900,
                        fontSize: "16px",
                      }}
                    >
                      Type
                    </TableCell>
                    <TableCell
                      style={{
                        color: "black",
                        fontWeight: 900,
                        fontSize: "16px",
                      }}
                    >
                      Date Added
                    </TableCell>
                    <TableCell
                      style={{
                        color: "black",
                        fontWeight: 900,
                        fontSize: "16px",
                      }}
                    >
                      Action
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {records.length
                    ? records
                        .sort((a, b) => a.date < b.date)
                        .map((f, index) => (
                          <TableRow
                            key={index}
                            style={{
                              height: "10px",
                              backgroundColor:
                                index % 2 === 0 ? "whitesmoke" : "",
                            }}
                          >
                            <TableCell align="left">{f.type}</TableCell>
                            <TableCell align="left">
                              {timeAgo(f.date)}
                            </TableCell>
                            <TableCell align="left">
                              <IconButton
                                onClick={() => window.open(f.url, "_blank")}
                              >
                                <DownloadIcon
                                  style={{ color: "gray" }}
                                  fontSize="medium"
                                />
                              </IconButton>
                            </TableCell>
                          </TableRow>
                        ))
                    : null}
                </TableBody>
              </Table>
            </TableContainer>
          </AccordionDetails>
        </CssAccordion>
      </div>
    </Paper>
  );
}
