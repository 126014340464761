import Stack from '@mui/material/Stack';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import React, { useState } from 'react';
import { style } from '../../../../../style/style';
import CssCheckbox from '../components/CssCheckbox';

const STEPS = Object.freeze({
  initial: 1,
});

export const MoreThanOneTitleNumber = props => {
  const [outputs, setOutputs] = useState({ paid: false });
  const [step, setStep] = useState(STEPS.initial);

  const onChangeCheckbox = e => {
    const updatedQuestions = { ...outputs };
    updatedQuestions[e.target.name] = !updatedQuestions[e.target.name];
    setOutputs(updatedQuestions);
  };

  const renderStep = step => {
    switch (step) {
      case STEPS.initial:
        return (
          <>
            <p style={{ fontSize: '12px', margin: '1', whiteSpace: 'pre-line' }}>
              {props.milestone.bodyText}
            </p>
            <CssCheckbox
              name="paid"
              label="I confirm that payment has been made."
              checked={outputs['paid']}
              onClick={e => {
                onChangeCheckbox(e);
              }}
            />
            <Button
              variant="contained"
              style={{
                color: 'white',
                // height: '40px',
                // width: '100px',
                margin: '10px',
              }}
              sx={style.darkGreenButton}
              onClick={() => {
                return props.submitFnc(props.index, outputs);
              }}
              disabled={!outputs.paid}
            >
              Submit
            </Button>
          </>
        );
    }
  };

  return (
    <Paper style={style.remortgage}>
      <div>
        <p style={{ fontSize: '16px', margin: '5' }}>
          More Than One Title Number
        </p>
        <div style={{ display: 'flex' }}>
          <div key={props.index}>{renderStep(step)}</div>
        </div>
      </div>
    </Paper>
  );
};
