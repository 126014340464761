// React
import React, { useState, useEffect } from "react";

// Appt
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
// style
import { color } from "../../../../style/color";
import { Typography } from "@mui/material";

function QuestionCheckbox(props) {
  const handleChange = (event) => {
    props.handleOnChange(event.target.checked, props.question);
  };

  const getValue = () => {
    if (props.formValues && props.formValues[props.question.name]) {
      return props.formValues[props.question.name];
    }
    return false;
  };

  return (
    <div style={{ textAlign: "left" }}>
      <FormControlLabel
        control={
          <Checkbox
            sx={{
              "& .MuiSvgIcon-root": { fontSize: 40 },
              color: color.brandDarkBlue,
              "&.Mui-checked": {
                color: color.brandDarkBlue,
              },
            }}
            checked={getValue()}
            onChange={handleChange}
          />
        }
        label={<Typography variant="">{props.question.label}</Typography>}
      />
    </div>
  );
}

export default QuestionCheckbox;
