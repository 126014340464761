import { createTheme } from "@mui/material/styles";
import { color } from "./color";

export const theme = createTheme({
  palette: {
    primary: {
      main: color.gray,
    },
    secondary: {
      main: color.smoke,
    },
    button: {
      main: color.olive,
    },
    complete: {
      main: color.brandOlive,
    },
    incomplete: {
      main: color.red,
    },
    progress: {
      main: color.brandDarkGreen,
    },
  },
  spacing: [100, 300, 20],
  typography: {
    fontFamily: "Karla",
    fontWeightLight: 300,
    //fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontWeightBold: 700,
    h2: {
      color: color.smoke,
      textAlign: "center",
    },
    h5: {
      textAlign: "center",
      paddingTop: "50px",
      fontSize: "30px",
      fontWeight: "700",
    },
    h6: {
      textAlign: "left",
      paddingLeft: "10px",
      marginTop: "-5px",
      fontSize: "15px",
      color: color.black,
    },
    title: {
      textAlign: "left",
      paddingLeft: "10px",
      fontWeight: "1000",
      fontSize: "30px",
      color: color.smoke,
      letterSpacing: "-1px",
    },
    body1: {
      textAlign: "center",
      // paddingTop: "50px",
      // paddingBottom: "10px",
      fontSize: "20px",
      color: color.black,
    },
    body2: {
      fontSize: "15px",
      //color: color.brandOlive,
      letterSpacing: "-1px",
      fontWeight: "1000",
    },
    subtitle: {
      textAlign: "left",
      paddingLeft: "10px",
      paddingBottom: "5px",
      fontSize: "15px",
      color: color.smoke,
      letterSpacing: "-1px",
      fontWeight: "1000",
      marginTop: "-10px",
    },
    caption: {
      textAlign: "center",
      //paddingTop: "15px",
      fontSize: "18px",
      color: color.smoke,
    },
    caption2: {
      textAlign: "center",
      paddingTop: "30px",
      fontSize: "16px",
    },
    tabs: {
      fontSize: "20px",
      color: color.smoke,
    },
  },
});
