import React, { useState, useEffect } from "react";
import TextField from "@mui/material/TextField";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { Typography } from "@mui/material";
import { styled } from "@mui/material/styles";

const CssTextField = styled(TextField)({
  "& .MuiOutlinedInput-root": {
    paddingTop: "0px",
    "& input": {
      color: "#070606",
      fontWeight: 600,
      padding: "5px",
      "&::placeholder": {
        color: "#070606",
        textAlign: "center",
      },
    },
    "& fieldset": {
      border: "2px solid #418ED1",
      borderRadius: "6px",
      boxShadow: "0 0 2px 1px #418ED1",
      //backgroundColor: "transparent",
    },
    "&:hover fieldset": {
      border: "2px solid #418ED1",
    },
    "&.Mui-focused fieldset": {
      //border: "2px solid #418ED1",
    },
    "input.error ": {
      borderColor: "red",
    },
  },
});

export default function QuestionDate2(props) {
  let v = props.formValuesMulti[props.question.name]
    ? props.formValuesMulti[props.question.name]
    : null;
  const [value, setValue] = useState(v);
  const [currentError, setCurrentError] = useState(null);
  const [errorDate, setErrorDate] = useState(false);
  const handleChange = (newValue) => {
    setValue(newValue);
    props.setFormValuesMulti((prevState) => ({
      ...prevState,
      [props.question.name]: newValue,
    }));
    props.handleOnChange(newValue, props.question);
  };

  const getValue = () => {
    if (
      props.formValues !== undefined &&
      props.formValues[props.question.name] !== null &&
      props.formValues[props.question.name] !== undefined &&
      props.formValues[props.question.name] !== ""
    ) {
      return props.formValues[props.question.name];
    }
    return null;
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DatePicker
        onError={(reason, value) => {
          if (reason) {
            setCurrentError("Please introduce a valid date");
            setErrorDate(true);
          } else {
            setCurrentError(null);
            setErrorDate(false);
          }
        }}
        inputFormat="dd/MM/yyyy"
        value={getValue()}
        onChange={(newValue) => {
          handleChange(newValue);
        }}
        renderInput={(params) => (
          <CssTextField
            error={errorDate}
            helperText={currentError ?? currentError}
            {...params}
            sx={{ width: "300px" }}
            inputProps={{
              ...params.inputProps,
              placeholder: props.question.questionText,
            }}
          />
        )}
      />
    </LocalizationProvider>
  );
}
