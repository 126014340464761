import { useContext, useEffect, useState } from "react";

// mui
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
// data
import { form } from "../../resources/form-data";

// services
import { LocalStorageService } from "../../services/LocalStorageService";

export default function MatterReferenceDialog(props) {
  const handleChange = (event) => {
    props.setMatter(event.target.value);
    const mortgageSelected = props.mortgages.find(
      (m) => m.MatterReference === event.target.value
    );
    props.setMortgage(mortgageSelected);
  };

  const handleContinue = () => {
    LocalStorageService.set("matterRef", props.matter);
    LocalStorageService.set("mortgage", props.mortgage);
    props.setOpen(false);
  };

  return (
    <div>
      <Dialog open={props.open}>
        <DialogTitle>{form.matter_dialog.description}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {form.matter_dialog.title_label}
          </DialogContentText>
          <FormControl fullWidth>
            <Select value={props.matter} onChange={handleChange}>
              {props.mortgages?.map((item, index) => (
                <MenuItem
                  style={{ color: "black" }}
                  key={index}
                  value={item.MatterReference}
                >
                  {item.Mortgage.RegMatterReference}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleContinue()}>
            {form.matter_dialog.button_label}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
