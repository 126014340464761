import { useEffect, useState } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import ReactGA from 'react-ga4';

// components
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ErrorIcon from "@mui/icons-material/Error";
import StepContent from "@mui/material/StepContent";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";

// data
import { color } from "../../../style/color";
import { style } from "../../../style/style";
import { form } from "../../../resources/form-data";

// services
import { LocalStorageService } from "../../../services/LocalStorageService";

export default function YourInformation(props) {
  const navigate = useNavigate();
  const user = JSON.parse(LocalStorageService.get("user"));
  const mortgageStorage = JSON.parse(LocalStorageService.get("mortgage"));
  console.log(mortgageStorage);

  const openReset = () => {
    navigate("/reset", {
      state: {
        reference: props.mortgage.MatterReference,
        postcode: user.postcode,
        email: user.username,
      },
    });
  };

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: '/your-information' })
  }, []);
  return (
    <Paper style={style.remortgage}>
      <Grid container>
        <Grid item xs={12}>
          <Stack
            direction="column"
            justifyContent="flex-start"
            alignItems="flex-start"
            spacing={0}
            textAlign="left"
          >
            {props.mortgage.Mortgage && (
              <Typography style={style.bodyBoldTitle} sx={{ paddingTop: "0" }}>
                {form.your_information.title_label}{" "}
                {props.mortgage.Mortgage.RegMatterReference}
              </Typography>
            )}

            <Grid item xs={12} style={{ width: "65%" }}>
              <Typography style={style.bodyText} sx={{ paddingTop: "10px" }}>
                {form.your_information.username_label}
              </Typography>
              <Typography style={style.bodyBold} sx={{ paddingBottom: "10px" }}>
                {user.username}
              </Typography>
              <Button
                type="submit"
                variant="contained"
                sx={style.LightBlueButton}
                onClick={() => openReset()}
                //href="/reset"
              >
                {form.your_information.change_label}
              </Button>
              <Typography
                style={{
                  fontSize: "25px",
                  fontWeight: "900",
                  color: "black",
                  textAlign: "left",
                }}
                sx={{ paddingTop: "20px" }}
              >
                My Details
              </Typography>
              <Box style={{ paddingTop: "10px" }}>
                {" "}
                <Typography
                  style={{
                    fontSize: "18px",
                    fontWeight: "900",
                    color: "black",
                    textAlign: "left",
                  }}
                  //sx={{ display: "inline" }}
                >
                  Personal Details
                </Typography>
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="flex-start"
                >
                  <Typography
                    style={{
                      fontSize: "18px",
                      color: "black",
                      textAlign: "left",
                    }}
                  >
                    {form.your_information.name_label}
                  </Typography>
                  <div>
                    <Typography
                      style={{
                        fontSize: "18px",
                        color: "black",
                        //textAlign: "left",
                      }}
                    >
                      {user.firstname} {user.lastname}
                    </Typography>
                  </div>
                </Stack>
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="flex-start"
                >
                  <Typography
                    style={{
                      fontSize: "18px",
                      color: "black",
                      //textAlign: "left",
                    }}
                  >
                    {form.your_information.dob_label}
                  </Typography>
                  <div>
                    <Typography
                      style={{
                        fontSize: "18px",
                        color: "black",
                        //textAlign: "left",
                      }}
                    >
                      {user.dob}
                    </Typography>
                  </div>
                </Stack>
              </Box>
              <Box style={{ paddingTop: "10px" }}>
                <Typography
                  style={{
                    fontSize: "18px",
                    fontWeight: "900",
                    color: "black",
                    textAlign: "left",
                  }}
                >
                  Address
                </Typography>
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="flex-start"
                >
                  <Typography
                    style={{
                      fontSize: "18px",
                      color: "black",
                      textAlign: "left",
                    }}
                  >
                    Address Line 1:
                  </Typography>
                  <Typography
                    style={{
                      fontSize: "18px",
                      color: "black",
                      textAlign: "left",
                    }}
                  >
                    {mortgageStorage?.Mortgage?.PropertyDetails?.RemortgageAddressLine1 || ''}
                  </Typography>
                </Stack>
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="flex-start"
                >
                  <Typography
                    style={{
                      fontSize: "18px",
                      color: "black",
                      textAlign: "left",
                    }}
                  >
                    Address Line 2:
                  </Typography>
                  <Typography
                    style={{
                      fontSize: "18px",
                      color: "black",
                      textAlign: "left",
                    }}
                  >
                    {mortgageStorage?.Mortgage?.PropertyDetails?.RemortgageAddressLine2 || ''}
                  </Typography>
                </Stack>
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="flex-start"
                >
                <Typography
                  style={{
                    fontSize: "18px",
                    color: "black",
                    textAlign: "left",
                  }}
                >
                  Address Line 3:
                </Typography>
                  <Typography
                    style={{
                      fontSize: "18px",
                      color: "black",
                      textAlign: "left",
                    }}
                  >
                    {mortgageStorage?.Mortgage?.PropertyDetails?.RemortgageAddressLine3 || ''}
                  </Typography>
                </Stack>
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="flex-start"
                >
                  <Typography
                    style={{
                      fontSize: "18px",
                      color: "black",
                      textAlign: "left",
                    }}
                  >
                    Address Line 4:
                  </Typography>
                  <Typography
                    style={{
                      fontSize: "18px",
                      color: "black",
                      textAlign: "left",
                    }}
                  >
                    {mortgageStorage?.Mortgage?.PropertyDetails?.RemortgageAddressLine4 || ''}
                  </Typography>
                </Stack>
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="flex-start"
                >
                  <Typography
                    style={{
                      fontSize: "18px",
                      color: "black",
                      textAlign: "left",
                    }}
                  >
                    Postcode:
                  </Typography>
                  <Typography
                    style={{
                      fontSize: "18px",
                      color: "black",
                      textAlign: "left",
                    }}
                  >
                    {mortgageStorage?.Mortgage?.PropertyDetails?.RemortgageAddressPostcode || ''}
                  </Typography>
                </Stack>
                
              </Box>
              <Box style={{ paddingTop: "10px" }}>
                <Typography
                  style={{
                    fontSize: "18px",
                    fontWeight: "900",
                    color: "black",
                    textAlign: "left",
                  }}
                >
                  Contact Details
                </Typography>
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="flex-start"
                >
                  <Typography
                    style={{
                      fontSize: "18px",
                      color: "black",
                      textAlign: "left",
                    }}
                  >
                    Email:
                  </Typography>
                  <Typography
                    style={{
                      fontSize: "18px",
                      color: "black",
                      textAlign: "left",
                    }}
                  >
                    {user.email}
                  </Typography>
                </Stack>
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="flex-start"
                >
                  <Typography
                    style={{
                      fontSize: "18px",
                      color: "black",
                      textAlign: "left",
                    }}
                  >
                    Home Phone:
                  </Typography>
                  <Typography
                    style={{
                      fontSize: "18px",
                      color: "black",
                      textAlign: "left",
                    }}
                  >
                    {user.homephone}
                  </Typography>
                </Stack>
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="flex-start"
                >
                  <Typography
                    style={{
                      fontSize: "18px",
                      color: "black",
                      textAlign: "left",
                    }}
                  >
                    Mobile:
                  </Typography>
                  <Typography
                    style={{
                      fontSize: "18px",
                      color: "black",
                      textAlign: "left",
                    }}
                  >
                    {user.mobile}
                  </Typography>
                </Stack>
              </Box>
              {/*<Typography style={style.bodyText}>Personal Details</Typography>
              <Typography style={style.bodyText} sx={{ display: "inline" }}>
                <Box component="span" style={style.bodyBold}>
                  {form.your_information.name_label}
                </Box>
                <Box sx={{ display: "inline"}}>
                {user.firstname} {user.lastname}
                </Box>
              </Typography>
              <Typography style={style.bodyText}>
                <Box component="span" style={style.bodyBold}>
                  {form.your_information.dob_label}{" "}
                </Box>
                {user.dob}
              </Typography>
              <Typography style={style.bodyBold} sx={{ paddingTop: "10px" }}>
                {form.your_information.address_label}
              </Typography>
              <Typography style={style.bodyText}>
                {user.addressLineOne}
                <br />
                {user.addressLineTwo}
                <br />
                {user.postcode}
              </Typography>*/}
            </Grid>
          </Stack>
        </Grid>
      </Grid>
    </Paper>
  );
}
