import Stack from '@mui/material/Stack';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import React, { useState } from 'react';
import { style } from '../../../../../style/style';
import { CssTextField } from '../components/CssTextField';

const STEPS = Object.freeze({
  initial: 1,
});

export const UnableToFindProperty = props => {
  const [step, setStep] = useState(STEPS.initial);
  const [outputs, setOutputs] = useState({
    TitleNumber: '',
  });

  const onChange = e => {
    const updatedQuestions = { ...outputs };
    updatedQuestions[e.target.name] = e.target.value;

    setOutputs(updatedQuestions);
  };

  const renderStep = step => {
    switch (step) {
      case STEPS.initial:
        return (
          <>
            <p style={{ fontSize: '12px', margin: '1', whiteSpace: 'pre-line' }}>
              {props.milestone.bodyText}
            </p>

            <CssTextField
              style={{ marginTop: '10px' }}
              name={'TitleNumber'}
              placeholder="Title Number"
              value={outputs['TitleNumber']}
              onChange={e => {
                onChange(e);
              }}
            />

            <Button
              variant="contained"
              style={{
                color: 'white',
                margin: '10px',
              }}
              sx={style.darkGreenButton}
              onClick={() => {
                return props.submitFnc(props.index, outputs);
              }}
              disabled={!outputs.TitleNumber}
            >
              Submit
            </Button>
          </>
        );
    }
  };

  return (
    <div>
      <Paper style={style.remortgage}>
        <p style={{ fontSize: '16px', margin: '5' }}>
          Please confirm property Title Number
        </p>
        <div style={{ display: 'flex' }}>
          <div key={props.index}>{renderStep(step)}</div>
        </div>
      </Paper>
    </div>
  );
};
