import Stack from '@mui/material/Stack';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import React, { useState } from 'react';
import { style } from '../../../../../style/style';
import DragDropFileDialog from '../../../../dialogs/DragDropFileDialog';
import CssCheckbox from '../components/CssCheckbox';
import { CssTextField } from '../components/CssTextField';
import {
  getAccessToken,
  WebServiceClient,
} from '../../../../../services/WebServiceClient';
import { downloadFile } from '../../../../../utils/DownloadFile';
import { LocalStorageService } from '../../../../../services/LocalStorageService';

const STEPS = Object.freeze({
  initial: 1,
  downloadAuth: 2,
  uploadAuth: 3,
  uploadRegister: 4,
  uploadEvidence: 5,
});

export const MismatchNameOnTitleAndInstruction = props => {
  const [outputs, setOutputs] = useState({
    Option: '',
  });

  const [errors, setErrors] = useState({
    BorrowerFullName: null,
  });
  const [optionSelection, setOptionSelection] = useState({
    nameDiffersIWillSort: false,
    nameDiffersAuthForm: false,
    nameDiffersShowEvidence: false,
  });
  const org = JSON.parse(LocalStorageService.get('organisation'));
  const [openDialog, setOpenDialog] = useState(false);

  const [step, setStep] = useState(STEPS.initial);
  const onChangeCheckbox = e => {
    const optionSelectionNew = { ...optionSelection };

    optionSelectionNew['nameDiffersIWillSort'] = false;
    optionSelectionNew['nameDiffersAuthForm'] = false;
    optionSelectionNew['nameDiffersShowEvidence'] = false;

    optionSelectionNew[e.target.name] = !!e.target.value;
    setOptionSelection(optionSelectionNew);

    let newOutputs = { ...outputs };

    if (optionSelectionNew['nameDiffersIWillSort']) {
      newOutputs['Option'] = 1;
    } else if (optionSelectionNew['nameDiffersAuthForm']) {
      newOutputs['Option'] = 2;
    } else if (optionSelectionNew['nameDiffersShowEvidence']) {
      newOutputs['Option'] = 3;
    }
    setOutputs(newOutputs);
  };

  const onChange = e => {
    const updatedQuestions = { ...outputs };
    updatedQuestions[e.target.name] = e.target.value;

    setOutputs(updatedQuestions);
  };

  const renderStep = step => {
    switch (step) {
      case STEPS.uploadRegister:
        return (
          <>
            <p style={{ fontSize: '12px', margin: '1' }}>
              Please upload a copy of the correctly amended register.
            </p>
            <Button
              variant="contained"
              style={{
                color: 'white',
                margin: '10px',
              }}
              sx={style.darkGreenButton}
              onClick={() => {
                setOpenDialog(true);
              }}
            >
              Upload Document
            </Button>

            <Dialog open={openDialog}>
              <DialogContent>
                <DragDropFileDialog
                  documentSelected={'UTR'}
                  documentType="updatedtitleregister"
                  handleClose={cancelled => {
                    setOpenDialog(false);
                    if (!cancelled) {
                      return props.submitFnc(props.index, outputs);
                    }
                  }}
                  setIsUpload={() => {}}
                  mortgage={props.mortgage}
                  setRecords={() => {}}
                  records={[]}
                  setExpanded={() => {}}
                />
              </DialogContent>
            </Dialog>
          </>
        );
      case STEPS.uploadAuth:
        return (
          <>
            <p style={{ fontSize: '12px', margin: '1' }}>
              Please upload the signed authority form.
            </p>
            <Button
              style={{
                color: 'white',
                margin: '10px',
              }}
              sx={style.darkGreenButton}
              onClick={() => {
                setOpenDialog(true);
              }}
            >
              Upload Document
            </Button>

            <Dialog open={openDialog}>
              <DialogContent>
                <DragDropFileDialog
                  documentSelected={'LR'}
                  handleClose={cancelled => {
                    setOpenDialog(false);
                    if (!cancelled) {
                      setStep(STEPS.uploadEvidence);
                    }
                  }}
                  setIsUpload={() => {}}
                  mortgage={props.mortgage}
                  setRecords={() => {}}
                  records={[]}
                  setExpanded={() => {}}
                />
              </DialogContent>
            </Dialog>
          </>
        );
      case STEPS.uploadEvidence:
        return (
          <>
            <p style={{ fontSize: '12px', margin: '1' }}>
              Please upload a scanned PDF of suitable evidence e.g passport, driving license, Change of Name Deed or birth certificate.
            </p>
            <Button
              style={{
                color: 'white',
                margin: '10px',
              }}
              sx={style.darkGreenButton}
              onClick={() => {
                setOpenDialog(true);
              }}
            >
              Upload Document
            </Button>

            <Dialog open={openDialog}>
              <DialogContent>
                <DragDropFileDialog
                  documentSelected={'ECFN'}
                  handleClose={cancelled => {
                    setOpenDialog(false);
                    if (!cancelled) {
                      return props.submitFnc(props.index, outputs);
                    }
                  }}
                  setIsUpload={() => {}}
                  mortgage={props.mortgage}
                  setRecords={() => {}}
                  records={[]}
                  setExpanded={() => {}}
                />
              </DialogContent>
            </Dialog>
          </>
        );
      case STEPS.downloadAuth:
        return (
          <>
            <p style={{ fontSize: '12px', margin: '1' }}>
              Please download the authority form to provide us consent to update
              the Land Registry on your behalf.
            </p>
            <Button
              variant="contained"
              style={{
                color: 'white',
                margin: '10px',
              }}
              sx={style.darkGreenButton}
              onClick={async () => {
                const config = {
                  headers: { Authorization: getAccessToken() },
                };
                const params = {
                  orgId: org.id,
                  siteId: null,
                  documentName: props.milestone.toPortal[2]['Option2'][1].file,
                };
                let ret = await WebServiceClient.get(
                  `/supporting-document/${props.mortgage.Mortgage.id}`,
                  params,
                  config,
                );

                if (ret.status != 200) {
                  /// error
                } else {
                  downloadFile(ret.data.fileUrl, props.milestone.file);
                }
              }}
            >
              Download
            </Button>
            <Button
              variant="contained"
              style={{
                color: 'white',
                margin: '10px',
              }}
              sx={style.darkGreenButton}
              onClick={() => {
                return setStep(STEPS.uploadAuth);
              }}
            >
              Continue {'>>'}
            </Button>
          </>
        );
      case STEPS.initial:
        return (
          <>
            <Stack>
              <p style={{ fontSize: '12px', margin: '1', whiteSpace: 'pre-line' }}>
                {props.milestone.bodyText}
              </p>

              <CssTextField
                style={{ marginTop: '10px' }}
                name={'BorrowerFullName'}
                placeholder="Correct Legal Name"
                value={outputs['BorrowerFullName']}
                error={errors.BorrowerFullName}
                helperText={errors.BorrowerFullName}
                onChange={e => {
                  onChange(e);
                }}
              />

              <CssCheckbox
                name="nameDiffersIWillSort"
                label="My name is incorrect on the mortgage instructions; I will contact my lender to amend and resend the mortgage offer. I will also amend the Mortgage Deed."
                checked={optionSelection['nameDiffersIWillSort']}
                onClick={e => {
                  onChangeCheckbox(e);
                }}
              />
              <CssCheckbox
                name="nameDiffersAuthForm"
                label="My name is incorrect on the Land Registry register. Please update the Land Registry on my behalf. I will provide the relevant evidence and signed authority form."
                checked={optionSelection['nameDiffersAuthForm']}
                onClick={e => {
                  onChangeCheckbox(e);
                }}
              />
              <CssCheckbox
                name="nameDiffersShowEvidence"
                label="My name is incorrect on the Land Registry register. I will update the Land Registry myself and upload the updated register here."
                checked={optionSelection['nameDiffersShowEvidence']}
                onClick={e => {
                  onChangeCheckbox(e);
                }}
              />

              <Button
                variant="contained"
                style={{
                  color: 'white',
                  margin: '10px',
                }}
                sx={style.darkGreenButton}
                disabled={
                  !optionSelection['nameDiffersIWillSort'] &&
                  !optionSelection['nameDiffersAuthForm'] &&
                  !optionSelection['nameDiffersShowEvidence']
                }
                onClick={() => {
                  props.submitFnc(props.index, outputs, false, false);

                  if (!outputs['BorrowerFullName']) {
                    setErrors({
                      BorrowerFullName: 'Full Name is required!',
                    });
                    return;
                  }

                  if (optionSelection['nameDiffersIWillSort']) {
                    return props.submitFnc(props.index, outputs);
                  }

                  if (optionSelection['nameDiffersAuthForm']) {
                    return setStep(STEPS.downloadAuth);
                  }

                  if (optionSelection['nameDiffersShowEvidence']) {
                    return setStep(STEPS.uploadRegister);
                  }
                }}
              >
                Submit
              </Button>
            </Stack>
          </>
        );
    }
  };

  return (
    <Paper style={style.remortgage}>
      <div>
        <p style={{ fontSize: '16px', margin: '5' }}>Name mismatch</p>
        <div style={{ display: 'flex' }}>
          <div key={props.index}>{renderStep(step)}</div>
        </div>
      </div>
    </Paper>
  );
};
