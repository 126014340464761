export const occupierNameKeys = [
    "OccupierNamesName",
    "OccupierNamesRelationship",
    "OccupierNamesAge",
    "OccupierNamesName2",
    "OccupierNamesRelationship2",
    "OccupierNamesAge2",
    "OccupierNamesName3",
    "OccupierNamesRelationship3",
    "OccupierNamesAge3",
    "OccupierNamesName4",
    "OccupierNamesRelationship4",
    "OccupierNamesAge4",
    "OccupierNamesName5",
    "OccupierNamesRelationship5",
    "OccupierNamesAge5",
    "OccupierNamesName6",
    "OccupierNamesRelationship6",
    "OccupierNamesAge6",
    "OccupierNamesName7",
    "OccupierNamesRelationship7",
    "OccupierNamesAge7",
    "OccupierNamesName8",
    "OccupierNamesRelationship8",
    "OccupierNamesAge8",
    "OccupierNamesName9",
    "OccupierNamesRelationship9",
    "OccupierNamesAge9",
    "OccupierNamesName10",
    "OccupierNamesRelationship10",
    "OccupierNamesAge10"
  ]