// React
import React, { useState, useEffect } from "react";

// Appt
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

// style
import { color } from "../../../../style/color";

function QuestionSelect(props) {
  const handleChange = (event) => {
    props.setFormValuesMulti((prevState) => ({
      ...prevState,
      [props.question.name]: event.target.value,
    }));
    props.handleOnChange(event.target.value, props.question);
  };

  const getValue = () => {
    if (
      props.formValues !== undefined &&
      props.formValues[props.question.name] !== null &&
      props.formValues[props.question.name] !== undefined &&
      props.formValues[props.question.name] !== ""
    ) {
      return props.formValues[props.question.name];
    }
    return "";
  };

  return (
    <FormControl fullWidth>
      <Select
        IconComponent={KeyboardArrowDownIcon}
        value={getValue()}
        onChange={handleChange}
        sx={{
          borderRadius: 3,
          backgroundColor: color.brandDarkBlue,
          color: "white",
          height: "40px",
          "& .MuiSelect-icon": {
            color: "white",
          },
        }}
      >
        {props.question.actions.map((a) => (
          <MenuItem sx={{ color: "black" }} value={a.key}>
            {a.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

export default QuestionSelect;