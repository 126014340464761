import { useEffect, useState } from 'react';
import ReactGA from 'react-ga4';

// components
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import { CssTextField } from '../milestones/components/CssTextField';
import Alerts from '../../../shared/Alerts';
// data
import { data } from '../../../../config/data';

import { color } from '../../../../style/color';
import { style } from '../../../../style/style';
import { styled } from '@mui/material/styles';
import { postMessage } from '../../../../services/MessageService';

const CssButtonDarkBlue = styled(Button)({
  '& .MuiButton-root': {
    backgroundColor: color.brandDarkBlue,
    color: 'white',
    width: 'fit-content',
    fontSize: '16px',
    textTransform: 'unset',
    borderRadius: '10px',
    '&:hover fieldset': {
      backgroundColor: color.brandLightBlue,
    },
  },
});

export default function SendMessage(props) {
  const [subject, setSubject] = useState('');
  const [message, setMessage] = useState('');
  const [alert, setAlert] = useState({});

  return (
    <Paper style={style.messagingWrapper}>
      <Grid container>
        <Grid item xs={12}>
          <Stack
            direction="column"
            justifyContent="flex-start"
            alignItems="flex-start"
            spacing={0}
            textAlign="left"
          >
            <Typography style={style.bodyBoldTitle} sx={{ paddingTop: '0' }}>
              Subject
            </Typography>
            <CssTextField
              style={{ width: '350px' }}
              value={subject}
              onChange={e => {
                setSubject(e.target.value);
              }}
            />

            <Typography style={style.bodyBoldTitle} sx={{ paddingTop: '0', marginTop: '10px' }}>
              Message
            </Typography>
            <CssTextField
              value={message}
              style={{ width: '350px' }}
              multiline
              minRows={4}
              onChange={e => {
                setMessage(e.target.value);
              }}
            />
            <Box sx={{ marginTop: '10px' }}>
              <Button
                variant="contained"
                style={{
                  color: 'white',
                  margin: '10px',
                }}
                sx={style.darkBlueButton}
                onClick={async () => {
                  setAlert({
                    open: true,
                    message: 'Message Sent!',
                    severity: 'success',
                  });
                  await postMessage(
                    props.mortgage.Mortgage.RegMatterReference,
                    subject,
                    message,
                  );
                  setSubject('');
                  setMessage('');
                  props.onCancel()
                }}
                disabled={!subject || !message}
              >
                Send
              </Button>
              <Button
                variant="contained"
                sx={ style.darkGreyButton }
                onClick={() => { props.onCancel()}}
              >
                Back
              </Button>
            </Box>
          </Stack>
        </Grid>
      </Grid>
      <Alerts alert={alert} setAlert={setAlert} />
    </Paper>
  );
}
