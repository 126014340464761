import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Formik } from "formik";

// mui
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Alerts from "../shared/Alerts";
import CircularProgress from "@mui/material/CircularProgress";

// style
import { style } from "../../style/style";
import { styled } from "@mui/material/styles";
import { color } from "../../style/color";

// data
import { SignupUtils } from "../../utils/SignupUtils";
import { SignupType } from "../../enums/SignupType";
import { login_schema } from "../../config/validation";
import { signup } from "../../resources/signup-data";

//services
import { LocalStorageService } from "../../services/LocalStorageService";
import { CardMedia } from "@mui/material";
import {
  setAccessToken,
  WebServiceClient,
} from "../../services/WebServiceClient";
import Box from "@mui/material/Box";
import Link from "@mui/material/Link";
import InputAdornment from "@mui/material/InputAdornment";
import { BsEyeFill, BsEyeSlashFill } from "react-icons/bs";

const CssTextField = styled(TextField)({
  "& .MuiOutlinedInput-root": {
    paddingTop: "0px",
    "& input": {
      color: "white",
      "&::placeholder": {
        color: "white",
        textAlign: "center",
      },
    },
    "& fieldset": {
      borderColor: "white",
      backgroundColor: "transparent",
    },
    "&:hover fieldset": {
      borderColor: "white",
    },
    "&.Mui-focused fieldset": {
      borderColor: "white",
    },
    "input.error ": {
      borderColor: "red",
    },
  },
});

export default function Login(props) {
  const navigate = useNavigate();
  const location = useLocation();
  const signupData = SignupUtils.getSignupData(SignupType.LOGIN);
  const [alert, setAlert] = useState({});
  const [loading, setLoading] = useState(false);
  const [passVisible, setPassVisible] = useState(false);

  const onSubmit = async (values) => {
    const data = {
      username: values.email,
      password: values.password,
    };

    LocalStorageService.clear();

    WebServiceClient.post("/authentication/login", data, {}, {})
      .then(function (response) {
        setLoading(true);
        setAccessToken(response.data.token);
        LocalStorageService.set("user", response.data.user);
        LocalStorageService.set(
          "organisation",
          response.data.userOrganisations[0]
        );
        navigate("/dashboard");
        setLoading(false);
      })
      .catch(function (error) {
        if (error?.response?.status === 401) {
          setAlert({
            open: true,
            severity: "error",
            message: signup.errors.unauthorized,
            action: true,
          });
        }
      });
  };

  const onActivate = () => {
    navigate("/activate");
  };

  const onReset = () => {
    navigate("/reset");
  };

  useEffect(() => {
    if (location.state && location.state.alert) {
      setAlert({
        open: location.state.alert.open,
        severity: location.state.alert.severity,
        message: location.state.alert.message,
        action: location.state.alert.action,
      });
    } 
  }, []);

  return (
    <Grid item xs={12}>
      {loading ? (
        <CircularProgress
          size={68}
          sx={{
            color: "white",
            position: "absolute",
            margin: "auto",
            zIndex: 1,
          }}
        />
      ) : (
        <Card sx={style.signup} elevation={0}>
          <CardMedia style={style.logo} alt="Integrar Logo" />
          <CardHeader
            title=""
            subheader={signupData.subTitle}
            titleTypographyProps={{ variant: "h2" }}
            subheaderTypographyProps={{
              variant: "h5",
              color: "secondary.main",
            }}
          />
          <Formik
            validationSchema={login_schema}
            initialValues={{
              email: location.state !== null ? location.state.email : "",
              password: location.state !== null ? location.state.password : "",
            }}
            onSubmit={(values) => {
              onSubmit(values);
            }}
          >
            {({ values, errors, touched, handleChange, handleSubmit }) => (
              <form noValidate onSubmit={handleSubmit}>
                <CardContent
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <CssTextField
                    type="email"
                    name="email"
                    placeholder={signupData.email_label}
                    sx={style.cssTextField}
                    value={values.email}
                    onChange={handleChange}
                  />

                  <Card
                    className="error"
                    sx={
                      errors.email && touched.email && errors.email
                        ? style.error
                        : ""
                    }
                  >
                    {errors.email && touched.email && errors.email}
                  </Card>
                  <CssTextField
                    type={passVisible ? "text" : "password"}
                    name="password"
                    placeholder={signupData.password_label}
                    sx={style.cssTextField}
                    value={values.password}
                    onChange={handleChange}
                    InputProps={{
                      [`endAdornment`]: (
                        <div
                          onClick={() => setPassVisible(!passVisible)}
                          style={{
                            cursor: "pointer",
                          }}
                        >
                          {passVisible ? (
                            <InputAdornment position={"end"}>
                              <BsEyeFill
                                style={{
                                  width: 40,
                                  height: 40,
                                  paddingRight: 10,
                                }}
                              />
                            </InputAdornment>
                          ) : (
                            <InputAdornment position={"end"}>
                              <BsEyeSlashFill
                                style={{
                                  width: 40,
                                  height: 40,
                                  paddingRight: 10,
                                }}
                              />
                            </InputAdornment>
                          )}
                        </div>
                      ),
                    }}
                  />
                  <Card
                    className="error"
                    sx={
                      errors.password && touched.password && errors.password
                        ? style.error
                        : ""
                    }
                  >
                    {errors.password && touched.password && errors.password}
                  </Card>
                </CardContent>
                <CardActions
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <Button
                    type="submit"
                    variant="contained"
                    color="button"
                    sx={{
                      width: "350px",
                      color: "white",
                      marginBottom: "10px",
                    }}
                  >
                    {signupData.submit_label}
                  </Button>
                  <Box direction="row" style={{ marginBottom: "40px" }}>
                    <Link
                      underline="hover"
                      variant="caption2"
                      color="secondary.main"
                      onClick={() => onReset()}
                      style={{
                        paddingTop: "0px",
                        cursor: "pointer",
                      }}
                    >
                      {signupData.forgotten_label}
                    </Link>
                  </Box>
                  <Box direction="row">
                    <Link
                      underline="hover"
                      variant="caption2"
                      color="secondary.main"
                      onClick={() => {
                        navigate("/landing");
                      }}
                      style={{
                        paddingTop: "0px",
                        cursor: "pointer",
                      }}
                    >
                      &lt; Back
                    </Link>
                  </Box>
                </CardActions>
              </form>
            )}
          </Formik>
        </Card>
      )}
      <Alerts alert={alert} setAlert={setAlert} />
    </Grid>
  );
}
