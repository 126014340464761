import shadows from "@mui/material/styles/shadows";
import background from "../assets/Integrar_background_desktop.png";
import logo from "../assets/cropped-Integrar-Logo.png";
import darkLogo from "../assets/dark-integrar-logo.png";
import { color } from "./color";
// import { autocompleteClasses } from "@mui/material";
// import { textAlign } from "@mui/system";

export const style = {
  //background images
  app: {
    backgroundImage: `url(${background})`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center center",
    backgroundAttachment: "fixed",
    padding: "100px 0",
    position: "relative",
    height: "100%",
  },
  dashboard: {
    padding: "100px 0",
    width: "112vw",
  },
  logo: {
    backgroundImage: `url(${logo})`,
    backgroundSize: "contain",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center center",
    height: 60,
  },
  darkLogo: {
    backgroundImage: `url(${darkLogo})`,
    backgroundSize: "contain",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "left",
    height: 40,
    width: "200px",
    margin: "20px",
  },
  darkGreen: {
    backgroundColor: color.brandDarkGreen,
    paddingTop: 0,
    paddingBottom: 0,
  },
  darkGreenButton: {
    backgroundColor: color.brandDarkGreen,
    "&:hover": {
      backgroundColor: color.brandHoverDarkBlue,
    },
  },
  darkGreenSideBar: {
    backgroundColor: color.brandDarkGreen,
    paddingTop: 0,
    paddingBottom: 0,
    "&:hover": {
      backgroundColor: color.brandHoverDarkBlue,
    },
  },

  olive: {
    backgroundColor: color.brandOlive,
    paddingTop: 0,
    paddingBottom: 0,
  },
  oliveButton: {
    backgroundColor: color.brandOlive,
    "&:hover": {
      backgroundColor: color.brandHoverDarkBlue,
    },
  },
  lightGreen: {
    backgroundColor: color.brandLightGreen,
    paddingTop: 0,
    paddingBottom: 0,
  },
  lightGreenButton: {
    backgroundColor: color.brandLightGreen,
    "&:hover": {
      backgroundColor: color.brandHoverDarkBlue,
    },
  },
  lightBlue: {
    backgroundColor: color.brandLightBlue,
    paddingTop: 0,
    paddingBottom: 0,
  },
  lightBlueButton: {
    backgroundColor: color.brandLightBlue,
    "&:hover": {
      backgroundColor: color.brandHoverDarkBlue,
    },
  },
  darkBlue: {
    backgroundColor: color.brandDarkBlue,
    paddingTop: 0,
    paddingBottom: 0,
  },
  darkBlueButton: {
    backgroundColor: color.brandDarkBlue,
    borderRadius: "10px",
    color: "white",
    width: "fit-content",
    fontSize: "16px",
    textTransform: "unset",
    "& :hover": {
      backgroundColor: color.brandHoverDarkBlue,
    },
  },
  darkGreyButton: {
    backgroundColor: 'rgb(89, 89, 89)',
    borderRadius: "10px",
    color: "white",
    width: "fit-content",
    fontSize: "16px",
    textTransform: "unset"
  },
  LightBlueButton: {
    backgroundColor: color.brandLightBlue,
    borderRadius: "10px",
    color: "white",
    width: "fit-content",
    fontSize: "16px",
    textTransform: "unset",
    "& :hover": {
      backgroundColor: color.brandHoverDarkBlue,
    },
  },
  sideBar: {
    padding: "10px 0",
    minHeight: "650px",
  },
  activeSideBar: {
    width: "350px",
    borderRadius: "0",
    marginLeft: "auto",
    marginRight: "auto",
    "&::before": {
      content: "''",
      position: "absolute",
      width: "25px",
      height: "25px",
      right: "-12px",
      transform: "rotate(45deg)",
    },
  },
  appBar: {
    flexDirection: "row",
    justifyContent: "space-between",
    fontSize: "40px",
    alignItems: "center",
    padding: "10px",
    backgroundColor: "white",
    color: "#b7b8bd",
    svg: {
      fontSize: "40px",
    },
  },
  step: {
    textAlign: "left",
  },
  remortgage: {
    padding: "10px",
    borderRadius: "10px",
    margin: "10px"
    // overflow: "scroll",
  },

  staticPage: {
    maxWidth: "1080px",
    width: "80%",
    margin: "auto",
    padding: "20px",
  },

  //typography
  header: {
    fontSize: "30px",
    fontWeight: "700",
  },
  subheader: {
    fontSize: "18px",
  },
  cssTextField: {
    width: "350px",
    fontSize: "20px",
    paddingBottom: "12px",
    marginLeft: "auto",
    marginRight: "auto",
  },
  link: {
    fontSize: "16px",
  },
  admin: {
    width: 400,
    margin: "auto",
    backgroundColor: "transparent",
  },
  signup: {
    width: 400,
    margin: "auto",
    backgroundColor: "transparent",
  },
  error: {
    background: "#ff4438",
    color: "white",
    marginBottom: "15px",
    marginTop: "10px",
    width: "350px",
    borderRadius: "0",
    marginLeft: "auto",
    marginRight: "auto",
    "&::before": {
      content: "''",
      position: "absolute",
      marginTop: "-9px",
      width: 0,
      height: 0,
      borderBottom: "9px solid #ff4438",
      borderLeft: "15px solid transparent",
      borderRight: "15px  solid transparent",
    },
  },
  mortgageDashboard: {
    maxWidth: "1080px",
    margin: "auto",
    padding: "20px",
  },
  //body typography
  //body text column
  bodyTextColumn: {
    width: "150px",
  },
  //body text
  bodyText: {
    fontSize: "16px",
    color: "black",
    textAlign: "left",
  },
  //bold body text
  bodyBold: {
    fontWeight: "700",
    fontSize: "16px",
    color: "black",
    textAlign: "left",
  },
  //bold body Title
  bodyBoldTitle: {
    fontWeight: "700",
    fontSize: "18px",
    color: "black",
    textAlign: "left",
  },
  //italics
  bodyItalics: {
    fontStyle: "italic",
    letterSpacing: "0",
  },
  //buttons
  buttonStyle: {
    color: "white",
    width: "350px",
    fontSize: "16px",
    borderRadius: "10px",
  },
  buttonStyleShort: {
    color: "white",
    width: "fit-content",
    fontSize: "16px",
    textTransform: "unset",
    borderRadius: "10px",
  },
  questionNavigationButton: {
    backgroundColor: color.brandDarkGreen,
    color: "white",
    padding: "10px",
    borderRadius: "10px",
    maxWidth: "220px",
    //marginTop: "10px",
    width: "120px",
    "&:hover": {
      backgroundColor: color.brandHoverDarkBlue,
    },
  },

  questionBackButton: {
    backgroundColor: color.gray,
    color: "white",
    padding: "10px",
    borderRadius: "10px",
    maxWidth: "220px",
    //marginTop: "10px",
    width: "120px",
    "&:hover": {
      backgroundColor: color.brandHoverDarkBlue,
    },
  },
  backButton: {
    color: "white",
  },
  questionNavButtonRow: {
    width: "215px",
    margin: "auto",
    display: "flex",
    justifyContent: "space-between",
    paddingTop: "15px",
    borderRadius: "10px",
  },
  booleanButton: {
    display: "flex",
    flexDirection: "column",
    backgroundColor: color.pastelGreen,
    borderRadius: "24px",
    width: "45%",
    fontSize: "1.25rem !important",
    "&:lastChild": {
      marginRight: "0",
    },
    "&:hover": {
      backgroundColor: color.hoverGreen,
      color: "white",
    },
  },
  booleanButtonRow: {
    width: "215px",
    display: "flex",
    flexDirection: "row",
    marginBottom: "10px",
    marginLeft: "auto",
    marginRight: "auto",
    justifyContent: "space-between",
    // "&:hover": {
    //   backgroundColor: color.hoverGreen,
    //   color: "white",
    // },
  },
  booleanIcon: {
    fontSize: "40px",
    color: color.lightGray,
    "&:hover": {
      color: "white",
    },
  },
  optionButton: {
    flexDirection: "column",
    backgroundColor: color.pastelGreen,
    borderRadius: "45px",
    width: "25%",
    margin: "10px",
    height: "150px",
    fontSize: "1.15rem !important",
    "&:lastChild": {
      marginRight: "0",
    },
    "&:hover": {
      backgroundColor: color.hoverGreen,
      color: "white",
    },
  },
  optionButtonRow: {
    flexDirection: "row",
    marginBottom: "10px",
    marginLeft: "auto",
    marginRight: "auto",
    justifyContent: "space-between",
  },
  //
  breadcrumb: {
    marginTop: "-6px",
    marginBottom: "10px",
    fontSize: "13px",
  },
  //question styling
  questionHeader: {
    color: "black",
    textAlign: "left",
    fontSize: "25px",
    fontWeight: "700",
  },
  question: {
    backgroundColor: color.pastelGreen,
    borderRadius: "24px 24px 24px 0",
    color: "black",
    marginBottom: "10px",
    padding: "10px",
  },
  section: {
    color: "black",
    marginBottom: "10px",
    textAlign: "left",
    fontWeight: "900",
  },
  multipleChoiceAnswer: {
    backgroundColor: "#64ba98",
    borderRadius: "24px",
    color: "black",
  },
  multipleChoiceIcon: {
    color: color.lightGray,
  },
  clickedAnswer: {
    color: "white",
  },
  //submit success page
  successPage: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "310px",
    margin: "auto",
    padding: "10px",
  },
  trophyBox: {
    backgroundColor: color.brandDarkGreen,
    borderRadius: "50%",
    color: "white",
    padding: "20px",
    height: "250px",
    width: "250px",
    textAlign: "center",
    marginBottom: "10px",
  },
  smileyIcon: {
    fontSize: "50px",
    color: color.gray,
  },
  successPageText: {
    color: "black",
  },
  successButton: {
    marginTop: "10px",
    backgroundColor: color.gray,
    color: "white",
    padding: "10px",
    width: "100%",
    borderRadius: "10px",
    "&:hover": {
      backgroundColor: color.hoverGrey,
      color: "white",
    },
  },
  // timeout box
  timeoutStyle: {
    borderRadius: "10px",
  },
  questionError: {
    color: color.white,
    backgroundColor: color.red,
    marginBottom: "15px",
  },
  questionErrorMulti: {
    color: color.white,
    backgroundColor: color.red,
    marginBottom: "15px",
    width: "300px",
    marginTop: "10px",
    marginLeft: "auto",
    marginRight: "auto",
  },
  messagingWrapper: {
    boxShadow: 'none',
    marginTop: '20px'
  },
  messagingStack: {
    boxShadow: 'none'
  }
};
