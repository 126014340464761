export class RemortgageUtils {
  static getBorrowers(borrowers) {
    const borrowersLenght = borrowers.length;
    var text = "";
    var previous = false;

    if (this.hasBorrowerInfo(borrowers[0])) {
      text = borrowers[0].firstname + " " + borrowers[0].lastname;
      previous = true;
    }

    if (borrowersLenght > 1) {
      for (let i = 1; i < borrowersLenght; i++) {
        if (this.hasBorrowerInfo(borrowers[i])) {
          if (previous) {
            text += ", " + borrowers[i].firstname + " " + borrowers[i].lastname;
          } else {
            text = borrowers[i].firstname + " " + borrowers[i].lastname;
            previous = true;
          }
        }
      }
    }
    return text;
  }

  static hasBorrowerInfo(borrower) {
    return (
      borrower.firstname !== null &&
      borrower.firstname !== "" &&
      borrower.lastname !== null &&
      borrower.firstname !== ""
    );
  }
}
