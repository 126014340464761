// React
import React from "react";

// Appt
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

// style

function CssSelect(props) {
  const { data, placeholder, value } = props;
  return (
    <FormControl fullWidth>
      <Select
        IconComponent={KeyboardArrowDownIcon}
        displayEmpty
        onClick={(e) => {
          e.stopPropagation();
        }}
        sx={{ ...matchTextBoxStyle }}
        renderValue={(selected) => {
          if (selected.length === 0) {
            return (
              <p style={{ color: "#979797", fontWeight: 600 }}>{placeholder}</p>
            );
          }

          return value;
        }}
        {...props}
      >
        {data?.map((item) => (
          <MenuItem sx={{ color: "black" }} value={item}>
            {item.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

// this makes it look the same as the text boxes so the styling doesn't look different,
// it only looks like this if a prop is passed called matchTextBox so the rest will look like they used to
const matchTextBoxStyle = {
  paddingTop: "0px",
  width: 300,
  height: 40,
  color: "#070606",
  fontWeight: 600,
  "& fieldset": {
    border: "2px solid #418ED1",
    borderRadius: "6px",
    boxShadow: "0 0 2px 1px #418ED1",
  },
  "&:hover": {
    "&& fieldset": {
      border: "2px solid #418ED1",
      borderRadius: "6px",
      boxShadow: "0 0 2px 1px #418ED1",
    },
  },
  "&.Mui-focused": {
    "&& fieldset": {
      border: "2px solid #418ED1",
      borderRadius: "6px",
      boxShadow: "0 0 2px 1px #418ED1",
    },
  },
};

export default CssSelect;
