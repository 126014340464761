import { questionType } from "../enums/QuestionType";
import { actionType } from "../enums/ActionType";
import { LocalStorageService } from "../services/LocalStorageService";

export const questionnaire = {
  continue_button_label: "Continue >>",
  back_button_label: "<< Back",
  extra_questions: [
    "JapaneseKnotwood",
    "earlyRepayment1",
    "earlyRepayment2",
    "earlyRepayment3",
    "Borrower1FirstName",
    "Borrower1MiddleName",
    "Borrower1Surname",
    "Borrower2FirstName",
    "Borrower2MiddleName",
    "Borrower2Surname",
    "managementCompanyLandlordDescription",
    "managementCompanyLandlordInfoName",
    "managementCompanyLandlordInfoAddress",
    "managementCompanyLandlordInfoEmail",
    "managementCompanyLandlordInfoPhone",
    "Borrower2Surname",
    "BuildingsInsurance",
  
    // "OccupierNames",
    
    "OccupierNamesName",
    "OccupierNamesRelationship",
    "OccupierNamesAge",
    "OccupierNamesName2",
    "OccupierNamesRelationship2",
    "OccupierNamesAge2",
    "OccupierNamesName3",
    "OccupierNamesRelationship3",
    "OccupierNamesAge3",
    "OccupierNamesName4",
    "OccupierNamesRelationship4",
    "OccupierNamesAge4",
    "OccupierNamesName5",
    "OccupierNamesRelationship5",
    "OccupierNamesAge5",
    "OccupierNamesName6",
    "OccupierNamesRelationship6",
    "OccupierNamesAge6",
    "OccupierNamesName7",
    "OccupierNamesRelationship7",
    "OccupierNamesAge7",
    "OccupierNamesName8",
    "OccupierNamesRelationship8",
    "OccupierNamesAge8",
    "OccupierNamesName9",
    "OccupierNamesRelationship9",
    "OccupierNamesAge9",
    "OccupierNamesName10",
    "OccupierNamesRelationship10",
    "OccupierNamesAge10",
  ],
  questions: [
    // {
    //   next: 65, // 2nd payment date
    //   name: "",
    //   questionId: 8,
    //   section: "About You",
    //   questionText: "Are you a developer trying to jump to a question?",
    //   questionType: questionType.TEXT
    // },
    // {
    //   next: 53, // are there occupiers
    //   name: "",
    //   questionId: 8,
    //   section: "About You",
    //   questionText: "Are you a developer trying to jump to a question?",
    //   questionType: questionType.TEXT
    // },
    {
      next: 61,
      name: "",
      questionId: 8,
      section: "About You",
      questionText: "Borrower 1 Personal Information",
      questionType: questionType.MULTITEXT,
      actions: [
        {
          name: "Borrower1Salutation",
          questionId: 0,
          questionText: "Title",
          questionType: questionType.TEXT,
          required: true,
          error: "Please fill this question",
        },
        {
          name: "Borrower1FirstName",
          questionId: 0,
          questionText: "First Name",
          questionType: questionType.TEXT,
          required: true,
          error: "Please fill this question",
        },
        {
          name: "Borrower1MiddleName",
          questionId: 0,
          questionText: "Middle Name",
          questionType: questionType.TEXT,
          required: false,
          error: "Please fill this question",
        },
        {
          name: "Borrower1Surname",
          questionId: 0,
          questionText: "Surname",
          questionType: questionType.TEXT,
          required: true,
          error: "Please fill this question",
        },

      ],
      required: true,
      error: "Please fill all questions",
    },
    {
      next: 9,
      name: "",
      questionId: 61,
      section: "About You",
      questionText: "Borrower 1 Personal Information",
      questionType: questionType.MULTITEXT,
      actions: [
        {
          name: "Borrower1HomeTelNo",
          questionId: 0,
          questionText: "Home Phone Number",
          questionType: questionType.TEXT,
          type: "phone",
        },
        {
          name: "Borrower1MobileTelNo",
          questionId: 0,
          questionText: "Mobile Number",
          questionType: questionType.TEXT,
          required: true,
          error: "Please fill this question",
          type: "phone",
        },
        {
          name: "Borrower1Email",
          questionId: 0,
          questionText: "Email Address",
          questionType: questionType.TEXT,
          required: true,
          type: "email",
          error: "Please fill this question",
        },
        {
          name: "Borrower1DOB",
          questionId: 0,
          questionText: "Date of birth",
          questionType: questionType.DATE,
          required: true,
          error: "You must be at least 18 years old",
        },
      ],
      required: true,
      error: "Please fill all required questions",
    },
    {
      next: 100,
      name: "",
      questionId: 9,
      section: "About You",
      questionText: "Borrower 1 Personal Information",
      questionType: questionType.MULTITEXT,
      actions: [
        {
          name: "Borrower1Occupation",
          questionId: 0,
          questionText: "Occupation",
          questionType: questionType.TEXT,
          required: true,
          error: "Please fill this question",
        },
        {
          name: "Borrower1PassportNo",
          questionId: 0,
          questionText: "Passport Number",
          questionType: questionType.TEXT,
          error: "Passport or Driving Licence number required",
        },
        {
          name: "Borrower1DrivingLicenceNo",
          questionId: 0,
          questionText: "Driving License Number",
          questionType: questionType.TEXT,
          error: "Passport or Driving Licence number required",
        },
      ],
      required: false,
      error: "Please fill all the required questions",
    },
    {
      next: 101,
      name: "",
      questionId: 100,
      section: "About You",
      questionText: "Borrower 2 Personal Information",
      questionType: questionType.MULTITEXT,
      actions: [
        {
          name: "Borrower2Salutation",
          questionId: 0,
          questionText: "Title",
          questionType: questionType.TEXT,
          required: true,
          error: "Please fill this question",
        },
        // {
        //   name: "Borrower2Name",
        //   questionId: 0,
        //   questionText: "First, middle and surname",
        //   questionType: questionType.TEXT,
        //   required: true,
        //   error: "Please fill this question",
        // },
        {
          name: "Borrower2FirstName",
          questionId: 0,
          questionText: "Firstname",
          questionType: questionType.TEXT,
          required: true,
          error: "Please fill this question",
        },
        {
          name: "Borrower2MiddleName",
          questionId: 0,
          questionText: "MiddleName",
          questionType: questionType.TEXT,
          required: false,
          error: "Please fill this question",
        },
        {
          name: "Borrower2Surname",
          questionId: 0,
          questionText: "Surname",
          questionType: questionType.TEXT,
          required: true,
          error: "Please fill this question",
        },
      ],
      required: true,
      error: "Please fill all questions",
    },
    {
      next: 102,
      name: "",
      questionId: 101,
      section: "About You",
      questionText: "Borrower 2 Personal Information",
      questionType: questionType.MULTITEXT,
      actions: [
        {
          name: "Borrower2HomeTelNo",
          questionId: 0,
          questionText: "Home Phone Number",
          questionType: questionType.TEXT,
          type: "phone",
        },
        {
          name: "Borrower2MobileTelNo",
          questionId: 0,
          questionText: "Mobile Number",
          questionType: questionType.TEXT,
          required: true,
          error: "Please fill this question",
          type: "phone",
        },
        {
          name: "Borrower2Email",
          questionId: 0,
          questionText: "Email Address",
          questionType: questionType.TEXT,
          required: true,
          type: "email",
          error: "Please fill this question",
        },
        {
          name: "Borrower2DOB",
          questionId: 0,
          questionText: "Date of birth",
          questionType: questionType.DATE,
          required: true,
          error: "You must be at least 18 years old",
        },
      ],
      required: true,
      error: "Please fill all the required questions",
    },
    {
      next: 10,
      name: "",
      questionId: 102,
      section: "About You",
      questionText: "Borrower 2 Personal Information",
      questionType: questionType.MULTITEXT,
      actions: [
        {
          name: "Borrower2Occupation",
          questionId: 0,
          questionText: "Occupation",
          questionType: questionType.TEXT,
          required: true,
          error: "Please fill this question",
        },
        {
          name: "Borrower2PassportNo",
          questionId: 0,
          questionText: "Passport Number",
          questionType: questionType.TEXT,
          error: "Passport or Driving Licence number required",
        },
        {
          name: "Borrower2DrivingLicenceNo",
          questionId: 0,
          questionText: "Driving License Number",
          questionType: questionType.TEXT,
          error: "Passport or Driving Licence number required",
        },
      ],
      required: false,
      error: "Please fill all the required questions",
    },
    {
      next: 11,
      questionId: 10,
      name: "RemortgageAddressCorrect",
      section: "About You",
      questionText:
        "Is the mortgage address the same as the address shown below? ",
      questionType: questionType.BOOLEAN,
      isMoreInfo: true,
      actions: [
        {
          type: actionType.MOREINFO,
          when: false,
          what: {
            name: "",
            questionId: 0,
            questionText: "",
            questionType: questionType.MULTITEXT,
            actions: [
              {
                name: "RemortgageHouseNo",
                questionId: 0,
                questionText: "House Number and House Name",
                questionType: questionType.TEXT,
                required: true,
                error: "Please fill this question",
              },
              {
                name: "RemortgageAddressStreet",
                questionId: 0,
                questionText: "Street Name",
                questionType: questionType.TEXT,
                required: true,
                error: "Please fill this question",
              },
              {
                name: "RemortgageAddressTown",
                questionId: 0,
                questionText: "Postal Town",
                questionType: questionType.TEXT,
                required: true,
                error: "Please fill this question",
              },
              {
                name: "RemortgageAddressArea",
                questionId: 0,
                questionText: "County",
                questionType: questionType.TEXT,
                required: true,
                error: "Please fill this question",
              },
              {
                name: "RemortgageAddressPostcode",
                questionId: 0,
                questionText: "Postcode",
                questionType: questionType.TEXT,
                required: true,
                error: "Please fill this question",
              },
            ],
          },
        },
        {
          type: actionType.NONE,
          when: true,
        },
      ],
      required: true,
      error: "Please choose an answer",
    },
    {
      next: 12,
      name: "MortgageOnPropertyQ11",
      questionId: 11,
      section: "About your current mortgage",
      questionText: "Do you have a mortgage on this property?",
      questionType: questionType.BOOLEAN,
      actions: [
        {
          type: actionType.NONE,
          when: true,
        },
        {
          type: actionType.GOTO,
          when: false,
          where: 19,
        },
      ],
      required: true,
      error: "Please choose an answer",
    },
    {
      next: 13,
      name: "Lender1Name",
      questionId: 12,
      section: "About your current mortgage",
      questionText: "Who is your current mortgage lender?",
      questionType: questionType.TEXT,
      actions: [],
      required: true,
      error: "Please fill this question",
    },
    {
      next: 14,
      name: "Lender1AccountNumber",
      questionId: 13,
      section: "About your current mortgage",
      questionText: "What is the mortgage account number?",
      questionType: questionType.TEXT,
      actions: [],
      required: true,
      error: "Please fill this question",
    },
    {
      next: 15,
      name: "Lender1AmountOutstanding",
      questionId: 14,
      section: "About your current mortgage",
      questionText: "Approximately how much is outstanding on the mortgage?",
      questionType: questionType.TEXT,
      adornments: "£", maxLength: 15,
      isNumber: false,
      actions: [],
      required: true,
      isCurrency: true,
      error: "Please fill this question",
    },
    {
      next: 16,
      name: "Lender1PaymentDate",
      questionId: 15,
      section: "About your current mortgage",
      questionText: "What is your monthly payment date?",
      questionType: questionType.SELECT,
      actions: [
        {
          type: actionType.NONE,
          key: "I'm unsure",
          label: "I'm unsure",
        },
        {
          type: actionType.NONE,
          key: "Day1",
          label: "1st every month",
        },
        {
          type: actionType.NONE,
          key: "Day2",
          label: "2nd every month",
        },
        {
          type: actionType.NONE,
          key: "Day3",
          label: "3rd every month",
        },
        {
          type: actionType.NONE,
          key: "Day4",
          label: "4th every month",
        },
        {
          type: actionType.NONE,
          key: "Day5",
          label: "5th every month",
        },
        {
          type: actionType.NONE,
          key: "Day6",
          label: "6th every month",
        },
        {
          type: actionType.NONE,
          key: "Day7",
          label: "7th every month",
        },
        {
          type: actionType.NONE,
          key: "Day8",
          label: "8th every month",
        },
        {
          type: actionType.NONE,
          key: "Day9",
          label: "9th every month",
        },
        {
          type: actionType.NONE,
          key: "Day10",
          label: "10th every month",
        },
        {
          type: actionType.NONE,
          key: "Day11",
          label: "11th every month",
        },
        {
          type: actionType.NONE,
          key: "Day12",
          label: "12th every month",
        },
        {
          type: actionType.NONE,
          key: "Day13",
          label: "13th every month",
        },
        {
          type: actionType.NONE,
          key: "Day14",
          label: "14th every month",
        },
        {
          type: actionType.NONE,
          key: "Day15",
          label: "15th every month",
        },
        {
          type: actionType.NONE,
          key: "Day16",
          label: "16th every month",
        },
        {
          type: actionType.NONE,
          key: "Day17",
          label: "17th every month",
        },
        {
          type: actionType.NONE,
          key: "Day18",
          label: "18th every month",
        },
        {
          type: actionType.NONE,
          key: "Day19",
          label: "19th every month",
        },
        {
          type: actionType.NONE,
          key: "Day20",
          label: "20th every month",
        },
        {
          type: actionType.NONE,
          key: "Day21",
          label: "21st every month",
        },
        {
          type: actionType.NONE,
          key: "Day22",
          label: "22nd every month",
        },
        {
          type: actionType.NONE,
          key: "Day23",
          label: "23rd every month",
        },
        {
          type: actionType.NONE,
          key: "Day24",
          label: "24th every month",
        },
        {
          type: actionType.NONE,
          key: "Day25",
          label: "25th every month",
        },
        {
          type: actionType.NONE,
          key: "Day26",
          label: "26th every month",
        },
        {
          type: actionType.NONE,
          key: "Day27",
          label: "27th every month",
        },
        {
          type: actionType.NONE,
          key: "Day28",
          label: "28th every month",
        },
        {
          type: actionType.NONE,
          key: "Day29",
          label: "29th every month",
        },
        {
          type: actionType.NONE,
          key: "Day30",
          label: "30th every month",
        },
        {
          type: actionType.NONE,
          key: "Day31",
          label: "31st every month",
        },
      ],
      required: false,
    },
    {
      next: 17,
      name: "Lender1RepaymentStatus",
      questionId: 16,
      section: "About your current mortgage",
      questionText:
        "Will your new mortgage be replacing this mortgage?",
      questionType: questionType.BOOLEAN,
      actions: [
        {
          type: actionType.NONE,
          when: true,
        },
        {
          type: actionType.NONE,
          when: false,
        },
      ],
      required: true,
      error: "Please choose an answer",
    },
    {
      next: 18,
      name: "Lender1ErcStatus",
      questionId: 17,
      section: "About your current mortgage",
      questionText: "Does your current mortgage have Early repayment charge? ",
      questionType: questionType.BOOLEAN,
      actions: [
        {
          type: actionType.GOTO,
          when: false,
          where: 20,
        },
        {
          type: actionType.GOTO,
          when: true,
          where: 18,
        },
      ],
      required: true,
      error: "Please choose an answer",
    },
    {
      next: 20,
      name: "Lender1ErcDate",
      questionId: 18,
      section: "About your current mortgage",
      questionText: "When does the Early Repayment Charge expire?",
      questionType: questionType.DATE,
      actions: [],
      required: true,
      error: "Please choose a date",
    },
    {
      next: 19,
      name: "Lender1ErcInstructions",
      questionId: 20,
      section: "About your new mortgage",
      questionText:
        "If there is an Early Repayment Charge showing on your existing mortgage do you want to wait for this to expire before completion of the new mortgage?",
      questionType: questionType.BOOLEAN,
      actions: [
        {
          type: actionType.NONE,
          when: false,
        },
        {
          type: actionType.NONE,
          when: true,
        },
      ],
      required: true,
      error: "Please choose an answer",
    },
    {
      next: 20,
      name: "earlyRepayment1",
      questionId: 19,
      section: "About your current mortgage",
      questionText:
        "Do you have any other charges or additional mortgages on the property?",
      questionType: questionType.BOOLEAN,
      actions: [
        {
          type: actionType.GOTO,
          when: false,
          where: 21,
        },
        {
          type: actionType.GOTO,
          when: true,
          where: 62,
        },
      ],
      required: true,
      error: "Please choose an answer",
    },

    {
      next: 63,
      name: "Lender2Name",
      questionId: 62,
      section: "About your current mortgage",
      questionText: "Who is your 2nd mortgage lender?",
      questionType: questionType.TEXT,
      actions: [],
      required: true,
      error: "Please type some text",
    },
    {
      next: 64,
      name: "Lender2AccountNumber",
      questionId: 63,
      section: "About your current mortgage",
      questionText: "What is the 2nd mortgage account number?",
      questionType: questionType.TEXT,
      actions: [],
      required: true,
      error: "Please type some text",
    },
    {
      next: 65,
      name: "Lender2AmountOutstanding",
      questionId: 64,
      section: "About your current mortgage",
      questionText:
        "Approximately how much is outstanding on the 2nd mortgage?",
      questionType: questionType.TEXT,
      adornments: "£", maxLength: 15,
      isNumber: false,
      actions: [],
      required: true,
      isCurrency: true,
      error: "Please fill this question",
    },
    {
      next: 66,
      name: "Lender2PaymentDate",
      questionId: 65,
      section: "About your current mortgage",
      questionText: "What is your 2nd monthly payment date?",
      questionType: questionType.SELECT,
      actions: [
        {
          type: actionType.NONE,
          key: "I'm unsure",
          label: "I'm unsure",
        },
        {
          type: actionType.NONE,
          key: "Day1",
          label: "1st every month",
        },
        {
          type: actionType.NONE,
          key: "Day2",
          label: "2nd every month",
        },
        {
          type: actionType.NONE,
          key: "Day3",
          label: "3rd every month",
        },
        {
          type: actionType.NONE,
          key: "Day4",
          label: "4th every month",
        },
        {
          type: actionType.NONE,
          key: "Day5",
          label: "5th every month",
        },
        {
          type: actionType.NONE,
          key: "Day6",
          label: "6th every month",
        },
        {
          type: actionType.NONE,
          key: "Day7",
          label: "7th every month",
        },
        {
          type: actionType.NONE,
          key: "Day8",
          label: "8th every month",
        },
        {
          type: actionType.NONE,
          key: "Day9",
          label: "9th every month",
        },
        {
          type: actionType.NONE,
          key: "Day10",
          label: "10th every month",
        },
        {
          type: actionType.NONE,
          key: "Day11",
          label: "11th every month",
        },
        {
          type: actionType.NONE,
          key: "Day12",
          label: "12th every month",
        },
        {
          type: actionType.NONE,
          key: "Day13",
          label: "13th every month",
        },
        {
          type: actionType.NONE,
          key: "Day14",
          label: "14th every month",
        },
        {
          type: actionType.NONE,
          key: "Day15",
          label: "15th every month",
        },
        {
          type: actionType.NONE,
          key: "Day16",
          label: "16th every month",
        },
        {
          type: actionType.NONE,
          key: "Day17",
          label: "17th every month",
        },
        {
          type: actionType.NONE,
          key: "Day18",
          label: "18th every month",
        },
        {
          type: actionType.NONE,
          key: "Day19",
          label: "19th every month",
        },
        {
          type: actionType.NONE,
          key: "Day20",
          label: "20th every month",
        },
        {
          type: actionType.NONE,
          key: "Day21",
          label: "21st every month",
        },
        {
          type: actionType.NONE,
          key: "Day22",
          label: "22nd every month",
        },
        {
          type: actionType.NONE,
          key: "Day23",
          label: "23rd every month",
        },
        {
          type: actionType.NONE,
          key: "Day24",
          label: "24th every month",
        },
        {
          type: actionType.NONE,
          key: "Day25",
          label: "25th every month",
        },
        {
          type: actionType.NONE,
          key: "Day26",
          label: "26th every month",
        },
        {
          type: actionType.NONE,
          key: "Day27",
          label: "27th every month",
        },
        {
          type: actionType.NONE,
          key: "Day28",
          label: "28th every month",
        },
        {
          type: actionType.NONE,
          key: "Day29",
          label: "29th every month",
        },
        {
          type: actionType.NONE,
          key: "Day30",
          label: "30th every month",
        },
        {
          type: actionType.NONE,
          key: "Day31",
          label: "31st every month",
        },
      ],
      required: false,
    },
    {
      next: 67,
      name: "Lender2RepaymentStatus",
      questionId: 66,
      section: "About your current mortgage",
      questionText:
        "Will your new mortgage be replacing this mortgage?",
      questionType: questionType.BOOLEAN,
      actions: [
        {
          type: actionType.NONE,
          when: true,
        },
        {
          type: actionType.NONE,
          when: false,
        },
      ],
      required: true,
      error: "Please choose an answer",
    },
    {
      next: 68,
      name: "Lender2ErcStatus",
      questionId: 67,
      section: "About your current mortgage",
      questionText: "Does your 2nd mortgage have Early repayment charges? ",
      questionType: questionType.BOOLEAN,
      actions: [
        {
          type: actionType.GOTO,
          when: false,
          where: 87,
        },
        {
          type: actionType.NONE,
          when: true,
        },
      ],
      required: true,
      error: "Please choose an answer",
    },
    {
      next: 87,
      name: "Lender2ErcDate",
      questionId: 68,
      section: "About your current mortgage",
      questionText: "When does the 2nd Early Repayment Charge expire?",
      questionType: questionType.DATE,
      actions: [],
      required: true,
      error: "Please choose a date",
    },
    {
      next: 69,
      name: "Lender2ErcInstructions",
      questionId: 87,
      section: "About your new mortgage",
      questionText:
        "If there is an Early Repayment Charge showing on your existing mortgage do you want to wait for this to expire before completion of the new mortgage?",
      questionType: questionType.BOOLEAN,
      actions: [
        {
          type: actionType.NONE,
          when: false,
        },
        {
          type: actionType.NONE,
          when: true,
        },
      ],
      required: true,
      error: "Please choose an answer",
    },
    {
      next: 70,
      name: "earlyRepayment2",
      questionId: 69,
      section: "About your current mortgage",
      questionText:
        "Do you have any other charges or additional mortgages on the property?",
      questionType: questionType.BOOLEAN,
      actions: [
        {
          type: actionType.GOTO,
          when: false,
          where: 21,
        },
        {
          type: actionType.GOTO,
          when: true,
          where: 70,
        },
      ],
      required: true,
      error: "Please choose an answer",
    },
    {
      next: 71,
      name: "Lender3Name",
      questionId: 70,
      section: "About your current mortgage",
      questionText: "Who is your 3rd mortgage lender?",
      questionType: questionType.TEXT,
      actions: [],
      required: true,
      error: "Please type some text",
    },
    {
      next: 72,
      name: "Lender3AccountNumber",
      questionId: 71,
      section: "About your current mortgage",
      questionText: "What is the 3rd mortgage account number?",
      questionType: questionType.TEXT,
      actions: [],
      required: true,
      error: "Please type some text",
    },
    {
      next: 73,
      name: "Lender3AmountOutstanding",
      questionId: 72,
      section: "About your current mortgage",
      questionText:
        "Approximately how much is outstanding on the 3rd mortgage?",
      questionType: questionType.TEXT,
      adornments: "£", maxLength: 15,
      isNumber: false,
      actions: [],
      required: true,
      isCurrency: true,
      error: "Please fill this question",
    },
    {
      next: 74,
      name: "Lender3PaymentDate",
      questionId: 73,
      section: "About your current mortgage",
      questionText: "What is your 3rd monthly payment date?",
      questionType: questionType.SELECT,
      actions: [
        {
          type: actionType.NONE,
          key: "I'm unsure",
          label: "I'm unsure",
        },
        {
          type: actionType.NONE,
          key: "Day1",
          label: "1st every month",
        },
        {
          type: actionType.NONE,
          key: "Day2",
          label: "2nd every month",
        },
        {
          type: actionType.NONE,
          key: "Day3",
          label: "3rd every month",
        },
        {
          type: actionType.NONE,
          key: "Day4",
          label: "4th every month",
        },
        {
          type: actionType.NONE,
          key: "Day5",
          label: "5th every month",
        },
        {
          type: actionType.NONE,
          key: "Day6",
          label: "6th every month",
        },
        {
          type: actionType.NONE,
          key: "Day7",
          label: "7th every month",
        },
        {
          type: actionType.NONE,
          key: "Day8",
          label: "8th every month",
        },
        {
          type: actionType.NONE,
          key: "Day9",
          label: "9th every month",
        },
        {
          type: actionType.NONE,
          key: "Day10",
          label: "10th every month",
        },
        {
          type: actionType.NONE,
          key: "Day11",
          label: "11th every month",
        },
        {
          type: actionType.NONE,
          key: "Day12",
          label: "12th every month",
        },
        {
          type: actionType.NONE,
          key: "Day13",
          label: "13th every month",
        },
        {
          type: actionType.NONE,
          key: "Day14",
          label: "14th every month",
        },
        {
          type: actionType.NONE,
          key: "Day15",
          label: "15th every month",
        },
        {
          type: actionType.NONE,
          key: "Day16",
          label: "16th every month",
        },
        {
          type: actionType.NONE,
          key: "Day17",
          label: "17th every month",
        },
        {
          type: actionType.NONE,
          key: "Day18",
          label: "18th every month",
        },
        {
          type: actionType.NONE,
          key: "Day19",
          label: "19th every month",
        },
        {
          type: actionType.NONE,
          key: "Day20",
          label: "20th every month",
        },
        {
          type: actionType.NONE,
          key: "Day21",
          label: "21st every month",
        },
        {
          type: actionType.NONE,
          key: "Day22",
          label: "22nd every month",
        },
        {
          type: actionType.NONE,
          key: "Day23",
          label: "23rd every month",
        },
        {
          type: actionType.NONE,
          key: "Day24",
          label: "24th every month",
        },
        {
          type: actionType.NONE,
          key: "Day25",
          label: "25th every month",
        },
        {
          type: actionType.NONE,
          key: "Day26",
          label: "26th every month",
        },
        {
          type: actionType.NONE,
          key: "Day27",
          label: "27th every month",
        },
        {
          type: actionType.NONE,
          key: "Day28",
          label: "28th every month",
        },
        {
          type: actionType.NONE,
          key: "Day29",
          label: "29th every month",
        },
        {
          type: actionType.NONE,
          key: "Day30",
          label: "30th every month",
        },
        {
          type: actionType.NONE,
          key: "Day31",
          label: "31st every month",
        },
      ],
      required: false,
    },
    {
      next: 75,
      name: "Lender3RepaymentStatus",
      questionId: 74,
      section: "About your current mortgage",
      questionText:
        "Will your new mortgage be replacing this mortgage?",
      questionType: questionType.BOOLEAN,
      actions: [
        {
          type: actionType.NONE,
          when: true,
        },
        {
          type: actionType.NONE,
          when: false,
        },
      ],
      required: true,
      error: "Please choose an answer",
    },
    {
      next: 76,
      name: "Lender3ErcStatus",
      questionId: 75,
      section: "About your current mortgage",
      questionText: "Does your 3rd mortgage have Early repayment charges? ",
      questionType: questionType.BOOLEAN,
      actions: [
        {
          type: actionType.GOTO,
          when: false,
          where: 88,
        },
        {
          type: actionType.NONE,
          when: true,
        },
      ],
      required: true,
      error: "Please choose an answer",
    },
    {
      next: 88,
      name: "Lender3ErcDate",
      questionId: 76,
      section: "About your current mortgage",
      questionText: "When does the 3rd Early Repayment Charge expire?",
      questionType: questionType.DATE,
      actions: [],
      required: true,
      error: "Please choose a date",
    },
    {
      next: 77,
      name: "Lender3ErcInstructions",
      questionId: 88,
      section: "About your new mortgage",
      questionText:
        "If there is an Early Repayment Charge showing on your existing mortgage do you want to wait for this to expire before completion of the new mortgage?",
      questionType: questionType.BOOLEAN,
      actions: [
        {
          type: actionType.NONE,
          when: false,
        },
        {
          type: actionType.NONE,
          when: true,
        },
      ],
      required: true,
      error: "Please choose an answer",
    },
    {
      next: 78,
      name: "earlyRepayment3",
      questionId: 77,
      section: "About your current mortgage",
      questionText:
        "Do you have any other charges or additional mortgages on the property?",
      questionType: questionType.BOOLEAN,
      actions: [
        {
          type: actionType.GOTO,
          when: false,
          where: 21,
        },
        {
          type: actionType.GOTO,
          when: true,
          where: 78,
        },
      ],
      required: true,
      error: "Please choose an answer",
    },
    {
      next: 79,
      name: "Lender4Name",
      questionId: 78,
      section: "About your current mortgage",
      questionText: "Who is your 4th mortgage lender?",
      questionType: questionType.TEXT,
      actions: [],
      required: true,
      error: "Please type some text",
    },
    {
      next: 80,
      name: "Lender4AccountNumber",
      questionId: 79,
      section: "About your current mortgage",
      questionText: "What is the 4th mortgage account number?",
      questionType: questionType.TEXT,
      actions: [],
      required: true,
      error: "Please type some text",
    },
    {
      next: 81,
      name: "Lender4AmountOutstanding",
      questionId: 80,
      section: "About your current mortgage",
      questionText:
        "Approximately how much is outstanding on the 4th mortgage?",
      questionType: questionType.TEXT,
      adornments: "£", maxLength: 15,
      isNumber: false,
      actions: [],
      required: true,
      isCurrency: true,
      error: "Please fill this question",
    },
    {
      next: 82,
      name: "Lender4PaymentDate",
      questionId: 81,
      section: "About your current mortgage",
      questionText: "What is your 4th monthly payment date?",
      questionType: questionType.SELECT,
      actions: [
        {
          type: actionType.NONE,
          key: "I'm unsure",
          label: "I'm unsure",
        },
        {
          type: actionType.NONE,
          key: "Day1",
          label: "1st every month",
        },
        {
          type: actionType.NONE,
          key: "Day2",
          label: "2nd every month",
        },
        {
          type: actionType.NONE,
          key: "Day3",
          label: "3rd every month",
        },
        {
          type: actionType.NONE,
          key: "Day4",
          label: "4th every month",
        },
        {
          type: actionType.NONE,
          key: "Day5",
          label: "5th every month",
        },
        {
          type: actionType.NONE,
          key: "Day6",
          label: "6th every month",
        },
        {
          type: actionType.NONE,
          key: "Day7",
          label: "7th every month",
        },
        {
          type: actionType.NONE,
          key: "Day8",
          label: "8th every month",
        },
        {
          type: actionType.NONE,
          key: "Day9",
          label: "9th every month",
        },
        {
          type: actionType.NONE,
          key: "Day10",
          label: "10th every month",
        },
        {
          type: actionType.NONE,
          key: "Day11",
          label: "11th every month",
        },
        {
          type: actionType.NONE,
          key: "Day12",
          label: "12th every month",
        },
        {
          type: actionType.NONE,
          key: "Day13",
          label: "13th every month",
        },
        {
          type: actionType.NONE,
          key: "Day14",
          label: "14th every month",
        },
        {
          type: actionType.NONE,
          key: "Day15",
          label: "15th every month",
        },
        {
          type: actionType.NONE,
          key: "Day16",
          label: "16th every month",
        },
        {
          type: actionType.NONE,
          key: "Day17",
          label: "17th every month",
        },
        {
          type: actionType.NONE,
          key: "Day18",
          label: "18th every month",
        },
        {
          type: actionType.NONE,
          key: "Day19",
          label: "19th every month",
        },
        {
          type: actionType.NONE,
          key: "Day20",
          label: "20th every month",
        },
        {
          type: actionType.NONE,
          key: "Day21",
          label: "21st every month",
        },
        {
          type: actionType.NONE,
          key: "Day22",
          label: "22nd every month",
        },
        {
          type: actionType.NONE,
          key: "Day23",
          label: "23rd every month",
        },
        {
          type: actionType.NONE,
          key: "Day24",
          label: "24th every month",
        },
        {
          type: actionType.NONE,
          key: "Day25",
          label: "25th every month",
        },
        {
          type: actionType.NONE,
          key: "Day26",
          label: "26th every month",
        },
        {
          type: actionType.NONE,
          key: "Day27",
          label: "27th every month",
        },
        {
          type: actionType.NONE,
          key: "Day28",
          label: "28th every month",
        },
        {
          type: actionType.NONE,
          key: "Day29",
          label: "29th every month",
        },
        {
          type: actionType.NONE,
          key: "Day30",
          label: "30th every month",
        },
        {
          type: actionType.NONE,
          key: "Day31",
          label: "31st every month",
        },
      ],
      required: false,
    },
    {
      next: 83,
      name: "Lender4RepaymentStatus",
      questionId: 82,
      section: "About your current mortgage",
      questionText:
        "Will your new mortgage be replacing this mortgage?",
      questionType: questionType.BOOLEAN,
      actions: [
        {
          type: actionType.NONE,
          when: true,
        },
        {
          type: actionType.NONE,
          when: false,
        },
      ],
      required: true,
      error: "Please choose an answer",
    },
    {
      next: 84,
      name: "Lender4ErcStatus",
      questionId: 83,
      section: "About your current mortgage",
      questionText: "Does your 4th mortgage have Early repayment charges? ",
      questionType: questionType.BOOLEAN,
      actions: [
        {
          type: actionType.GOTO,
          when: false,
          where: 89,
        },
        {
          type: actionType.NONE,
          when: true,
        },
      ],
      required: true,
      error: "Please choose an answer",
    },
    {
      next: 89,
      name: "Lender4ErcDate",
      questionId: 84,
      section: "About your current mortgage",
      questionText: "When does the 4th Early Repayment Charge expire?",
      questionType: questionType.DATE,
      actions: [],
      required: true,
      error: "Please choose a date",
    },
    {
      next: 21,
      name: "Lender4ErcInstructions",
      questionId: 89,
      section: "About your new mortgage",
      questionText:
        "If there is an Early Repayment Charge showing on your existing mortgage do you want to wait for this to expire before completion of the new mortgage?",
      questionType: questionType.BOOLEAN,
      actions: [
        {
          type: actionType.NONE,
          when: false,
        },
        {
          type: actionType.NONE,
          when: true,
        },
      ],
      required: true,
      error: "Please choose an answer",
    },
    {
      next: 22,
      name: "PreferredCompletionDate",
      questionId: 21,
      section: "About your new mortgage",
      questionText:
        "Please provide a preferred completion date to complete the remortgage",
      questionType: questionType.DATE,
      actions: [],
      required: true,
      error: "Please choose a date",
    },

    {
      next: 23,
      name: "SurplusOrShortfall",
      questionId: 22,
      section: "About your new mortgage",
      questionText:
        "Are you expecting any money due back on completion (surplus) or you might have to make up a shortfall?",
      questionType: questionType.OPTION,
      actions: [
        {
          type: actionType.GOTO,
          when: "Surplus",
          label: "Surplus",
          where: 85,
        },
        {
          type: actionType.GOTO,
          when: "Shortfall",
          label: "Shortfall",
          where: 86,
        },
        {
          type: actionType.GOTO,
          when: "I'm unsure",
          label: "I'm unsure",
          where: 24,
        },
      ],
      required: true,
      error: "Please choose an option",
    },

    {
      next: 24,
      name: "SurplusOrShortfallAmount",
      questionId: 85,
      section: "About your new mortgage",
      questionText: "What is your expected surplus?",
      questionType: questionType.TEXT,
      isCurrency: true,
      adornments: "£", maxLength: 18,
      isNumber: false,
      required: true,
      error: "Please choose an option",
    },

    {
      next: 23,
      name: "SurplusOrShortfallAmount",
      questionId: 86,
      section: "About your new mortgage",
      questionText: "What is your expected shortfall?",
      questionType: questionType.TEXT,
      isCurrency: true,
      adornments: "£", maxLength: 18,
      isNumber: false,
      required: true,
      error: "Please choose an option",
    },
    {
      next: 24,
      name: "SurplusMethod",
      questionId: 23,
      section: "About your new mortgage",
      questionText:
        "If you are borrowing less that you currently owe how do you intend to repay the balance of the existing loan?",
      questionType: questionType.OPTION,
      actions: [
        {
          type: actionType.NONE,
          when: "CreditCard",
          label: "Credit card",
          icon: "",
        },
        {
          type: actionType.NONE,
          when: "Gift",
          label: "Gift from family or friend",
          icon: "",
        },
        {
          type: actionType.NONE,
          when: "Inheritance",
          label: "Inheritance",
          icon: "",
        },
        {
          type: actionType.NONE,
          when: "UnsecuredLoan",
          label: "Unsecured loan",
          icon: "",
        },
        {
          type: actionType.NONE,
          when: "Own",
          label: "Own savings",
          icon: "",
        },
        {
          type: actionType.NONE,
          when: "Maturity",
          label: "Maturity of investments",
          icon: "",
        },
        {
          type: actionType.NONE,
          when: "utilising",
          label: "Utilising business account",
          icon: "",
        },
        {
          type: actionType.NONE,
          when: "Other",
          label: "Other",
          icon: "",
        },
      ],
      required: true,
      error: "Please choose an option",
    },

    {
      next: 25,
      name: "BankDetails",
      questionId: 24,
      section: "About your new mortgage",
      questionText:
        "Please enter your bank details allowing us to return any funds to you, if there are any, after completion. We will send any surplus to you directly to your bank account unless you ask us to send by another method. Please refer to the additional fee list in this regard",
      questionType: questionType.MULTITEXT,
      actions: [
        {
          name: "BorrowerBankName",
          questionId: 0,
          questionText: "Bank Name",
          questionType: questionType.TEXT,
          error: "This question is required",
          required: true,
        },
        {
          name: "BorrowerBankAccountName",
          questionId: 0,
          questionText: "Name on Account",
          questionType: questionType.TEXT,
          error: "This question is required",
          required: true,
        },
        {
          name: "BorrowerBankAccountNumber",
          questionId: 0,
          questionText: "Account Number",
          questionType: questionType.TEXT,
          maxLength: 8,
          isNumber: true,
          error: "This question is required",
          required: true,
        },
        {
          name: "BorrowerSortCode",
          questionId: 0,
          questionText: "Sort Code",
          questionType: questionType.TEXT,
          maxLength: 6,
          isNumber: true,
          error: "This question is required",
          required: true,
        },
      ],
      required: true,
      error: "Please fill all questions",
    },
    {
      next: 26,
      name: "BarclaysToleranceInstructions",
      questionId: 25,
      section: "About your new mortgage",
      questionText:
        "Barclays may offer to lend you up to £2000 in addition to the amount shown on your mortgage to cover any unexpected shortfall to repay your existing mortgage. This is called Tolerance. If there is a shortfall and the tolerance facility is shown in your mortgage offer do you wish to use his option?",
      questionType: questionType.BOOLEAN,
      actions: [
        {
          type: actionType.NONE,
          when: false,
        },
        {
          type: actionType.NONE,
          when: true,
        },
      ],
      required: true,
      error: "Please choose an answer",
    },
    {
      next: 27,
      name: "NoticesReceived",
      questionId: 26,
      section: "About your property",
      questionText:
        "Have you received any notices about the property from any third party, such as local authority, which might affect the property or its value?",
      questionType: questionType.BOOLEAN,
      isMoreInfo: true,
      actions: [
        {
          type: actionType.NONE,
          when: false,
        },
        {
          type: actionType.MOREINFO,
          what: {
            name: "NoticesReceivedInfo",
            questionId: 0,
            questionText: "Please provide more information",
            questionType: questionType.TEXT,
            rows: 5,
          },
          when: true,
          required: true,
          error: "my question"
        },
      ],
      required: true,
      error: "Please choose an answer",
    },
    {
      next: 28,
      name: "AdditionalLandStatus",
      questionId: 27,
      section: "About your property",
      questionText:
        "Have you bought or do you own any additional land that forms part of the property to be included in this mortgage? This could include a separate garage, parking space or part of the garden?",
      questionType: questionType.BOOLEAN,
      isMoreInfo: true,
      actions: [
        {
          type: actionType.GOTO,
          when: false,
          where: 29,
        },
        {
          type: actionType.MOREINFO,
          when: true,
          what: {
            name: "AdditionalLandStatusInfo",
            questionId: 0,
            questionText: "Please provide more information",
            questionType: questionType.TEXT,
            rows: 5,
          },
        },
      ],
      required: true,
      error: "Please choose an answer",
    },
    {
      next: 29,
      name: "AdditionalLandPurchaseDate",
      questionId: 28,
      section: "About your property",
      questionText: "When did you purchase this additional land?",
      questionType: questionType.DATE,
      actions: [],
    },
    {
      next: 30,
      name: "PropertyComplaints",
      questionId: 29,
      section: "About your property",
      questionText:
        "Are you aware of any complaints about the property, such as unresolved boundary issues, planning disputes, or tenancy claims?",
      questionType: questionType.BOOLEAN,
      isMoreInfo: true,
      actions: [
        {
          type: actionType.NONE,
          when: false,
        },
        {
          type: actionType.MOREINFO,
          when: true,
          what: {
            name: "PropertyComplaintsInfo",
            questionId: 0,
            questionText: "Please provide more information",
            questionType: questionType.TEXT,
            rows: 5,
          },
        },
      ],
      required: true,
      error: "Please choose an answer",
    },
    {
      next: 31,
      name: "JapaneseKnotwood",
      questionId: 30,
      section: "About your property",
      questionText:
        "Do you have an existing management plan in place for Japanese Knotweed growing on or near to the property?",
      questionType: questionType.BOOLEAN,
      isMoreInfo: true,
      actions: [
        {
          type: actionType.NONE,
          when: false,
        },
        {
          type: actionType.MOREINFO,
          when: true,
          what: {
            name: "JapaneseKnotwoodInfo",
            questionId: 0,
            questionText: "Please provide more information",
            questionType: questionType.TEXT,
            rows: 5,
          },
        },
      ],
      required: true,
      error: "Please choose an answer",
    },
    {
      next: 32,
      name: "BuildingsInsurance",
      questionId: 31,
      section: "About your property",
      questionText:
        "Do you currently have building insurance?",
      questionType: questionType.BOOLEAN,
      isMoreInfo: true,
      required: true,
      error: "Please answer the question",
      actions: [
        {
          type: actionType.NONE,
          when: false,
        },
        {
          type: actionType.MOREINFO,
          when: true,
          what: {
            name: "BuildingsInsuranceExpiryDate",
            questionId: 0,
            questionText: "Policy expiry date",
            questionType: questionType.DATE,
            error: "This question is required",
            required: true,
          },
        }
      ],
    },
    {
      next: 33,
      name: "Tenure",
      questionId: 32,
      section: "About your Leasehold or Commonhold property ",
      questionText: "What is the tenure of the property?",
      questionType: questionType.OPTION,
      actions: [
        {
          type: actionType.GOTO,
          when: "Freehold",
          where: 46,
          label: "Freehold",
          icon: "",
        },
        {
          type: actionType.GOTO,
          when: "Leasehold",
          label: "Leasehold",
          where: 33,
          icon: "",
        },
      ],
    },
    {
      next: 34,
      name: "managementCompanyLandlord",
      questionId: 33,
      section: "About your Leasehold or Commonhold property",
      questionText:
        "Do you have a management company in addition to a landlord?",
      questionType: questionType.BOOLEAN,
      isMoreInfo: true,
      actions: [
        {
          type: actionType.NONE,
          when: false,
        },
        {
          type: actionType.MOREINFO,
          when: true,
          what: {
            name: "",
            questionText: "",
            questionType: questionType.MULTITEXT,
            actions: [
              {
                name: "managementCompanyLandlordDescription",
                questionId: 0,
                questionText: "Please provide more information about the management company",
                questionType: questionType.PLAINTEXT,
              },
              {
                name: "managementCompanyLandlordInfoName",
                questionId: 0,
                questionText: "Name",
                questionType: questionType.TEXT
              },
              {
                name: "managementCompanyLandlordInfoAddress",
                questionId: 0,
                questionText: "Address",
                questionType: questionType.TEXT
              },
              {
                name: "managementCompanyLandlordInfoEmail",
                questionId: 0,
                questionText: "Email",
                questionType: questionType.TEXT,
                type: "email"
              },
              {
                name: "managementCompanyLandlordInfoPhone",
                questionId: 0,
                questionText: "Phone Number",
                questionType: questionType.TEXT,
                type: "phone"
              },
            ]
          }
        },
      ],
      required: true,
      error: "Please choose an answer",
    },
    {
      next: 35,
      name: "LeaseTermRemaining",
      questionId: 34,
      section: "About your Leasehold or Commonhold property",
      questionText: "What is the term remaining on the leasehold interest?",
      questionType: questionType.TEXT,
      isNumber: true,
      actions: [],
    },
    {
      next: 36,
      name: "ConfirmationGroundRentPaid",
      questionId: 35,
      section: "About your Leasehold or Commonhold property",
      questionText: "Is your ground rent and service charge paid up to date?",
      questionType: questionType.BOOLEAN,
      isMoreInfo: true,
      actions: [
        {
          type: actionType.MOREINFO,
          when: false,
          what: {
            name: "ConfirmationGroundRentPaidInfo",
            questionId: 0,
            questionText: "Please provide more information",
            questionType: questionType.TEXT,
            rows: 5,
            required: true
          },
        },
        {
          type: actionType.NONE,
          when: true,
        },
      ],
      required: true,
      error: "Please choose an answer",
    },
    {
      next: 37,
      name: "ConfirmationNoGroundRentDisputes",
      questionId: 36,
      section: "About your Leasehold or Commonhold property",
      questionText:
        "Are you aware of any dispute with the landlord or the management company?",
      questionType: questionType.BOOLEAN,
      isMoreInfo: true,
      actions: [
        {
          type: actionType.NONE,
          when: false,
        },
        {
          type: actionType.MOREINFO,
          when: true,
          what: {
            name: "ConfirmationNoGroundRentDisputesInfo",
            questionId: 0,
            questionText: "Please provide more information",
            questionType: questionType.TEXT,
            rows: 5,
          },
        },
      ],
      required: true,
      error: "Please choose an answer",
    },
    {
      next: 38,
      name: "SharedOwnershipStatus",
      multi: [],
      questionId: 37,
      section: "About your Leasehold or Commonhold property",
      questionText: "Do you own your property under a shared ownership lease?",
      questionType: questionType.BOOLEAN,
      actions: [
        {
          type: actionType.GOTO,
          when: false,
          where: 46,
        },
        {
          type: actionType.GOTO,
          when: true,
          where: 38,
        },
      ],
      required: true,
      error: "Please choose an answer",
    },
    {
      next: 39,
      name: "SharedOwnershipPercentage",
      questionId: 38,
      section: "About your Leasehold or Commonhold property",
      questionText: "What percentage do you own?",
      questionType: questionType.TEXT,
      required: true,
      actions: [],
    },
    {
      next: 40,
      name: "HousingAssociationDetails",
      questionId: 39,
      section: "About your Leasehold or Commonhold property",
      questionText: "Please provide details of the Housing Association ",
      questionType: questionType.TEXT,
      rows: 5,
      actions: [],
    },
    {
      next: 41,
      name: "PurchasingFurtherPercentage",
      questionId: 40,
      section: "About your Leasehold or Commonhold property",
      questionText:
        "Are you purchasing a further percentage of the shared ownership lease?",
      questionType: questionType.BOOLEAN,
      actions: [
        {
          type: actionType.GOTO,
          when: false,
          where: 46,
        },
        {
          type: actionType.NONE,
          when: true,
        },
      ],
      required: true,
      error: "Please choose an answer",
    },
    {
      next: 42,
      name: "FurtherPurchasePercentage",
      questionId: 41,
      section: "About your Leasehold or Commonhold property",
      questionText: "What further percentage are you buying?",
      questionType: questionType.TEXT,
      actions: [],
    },
    {
      next: 43,
      name: "FurtherPurchasePrice",
      questionId: 42,
      section: "About your Leasehold or Commonhold property",
      questionText: "What price are you paying?",
      questionType: questionType.TEXT,
      adornments: "£", maxLength: 18,
      isCurrency: true,
      isNumber: false,
      required: true,
      actions: [],
    },
    {
      next: 44,
      name: "HousingAssociationOfferDate",
      questionId: 43,
      section: "About your Leasehold or Commonhold property",
      questionText:
        "What is the date of the offer from the Housing Association?",
      questionType: questionType.DATE,
      actions: [],
      required: true,
      error: "Please introduce a date",
    },
    {
      next: 45,
      name: "HousingAssociationOfferExpiry",
      questionId: 44,
      section: "About your Leasehold or Commonhold property",
      questionText: "When does the offer from the Housing Association expire?",
      questionType: questionType.DATE,
      required: true,
      actions: [],
    },
    {
      next: 46,
      name: "HousingAssociationValuation",
      questionId: 45,
      section: "About your Leasehold or Commonhold property",
      questionText:
        "What is the Housing Associations valuation of your property?",
      questionType: questionType.TEXT,
      adornments: "£", maxLength: 18,
      isNumber: false,
      isCurrency: true,
      required: true,
      actions: [],
    },
    {
      next: 51,
      name: "AddingRemovingLegalOwner",
      questionId: 46,
      section: "Property Ownership",
      questionText:
        "Are you adding or removing anyone as a legal owner of the property?",
      questionType: questionType.OPTION,
      actions: [
        {
          type: actionType.GOTO,
          when: "Adding",
          label: "Adding",
          where: 50,
        },
        {
          type: actionType.GOTO,
          when: "Removing",
          label: "Removing",
          where: 47,
        },
        {
          type: actionType.GOTO,
          when: "Neither",
          label: "Neither",
          where: 51,
        },
      ],

      required: true,
      error: "Please choose an answer",
    },

    // leaving
    {
      next: 48,
      name: "PassedAway",
      questionId: 47,
      section: "Property Ownership",
      questionText: "Have any of the current owners passed away?",
      questionType: questionType.BOOLEAN,
      isMoreInfo: true,
      actions: [
        {
          type: actionType.NONE,
          when: false,
        },
        {
          type: actionType.MOREINFO,
          when: true,
          what: {
            text: "Please send copies (not originals) of the Death Certificate and Probate to us",
            questionType: questionType.PLAINTEXT,
          },
        },
      ],
      required: true,
      error: "Please choose an answer",
    },
    {
      next: 50,
      name: "LeavingPartyOccupier",
      questionId: 48,
      section: "Property Ownership",
      questionText:
        "Will the person(s) being removed as legal owner live at the property after completion of the mortgage?",
      questionType: questionType.BOOLEAN,
      actions: [
        {
          type: actionType.NONE,
          when: false,
        },
        {
          type: actionType.NONE,
          when: true,
        },
      ],
      required: true,
      error: "Please choose an answer",
    },

    // all
    {
      next: 51,
      name: "MatrimonialProceedings",
      questionId: 50,
      section: "Property Ownership",
      questionText: "Is the transfer part of matrimonial proceedings?",
      questionType: questionType.BOOLEAN,
      isMoreInfo: true,
      actions: [
        {
          type: actionType.NONE,
          when: false,
        },
        {
          type: actionType.MOREINFO,
          when: true,
          what: {
            name: "transferDescription",
            questionId: 0,
            questionText:
              "Please provide law firm name, address and contact details",
            questionType: questionType.TEXT,
            rows: 5,
          },
        },
      ],
      required: true,
      error: "Please choose an answer",
    },

    {
      next: 51,
      name: "Consideration",
      questionId: 49,
      section: "Property Ownership",
      questionText:
        "Are you providing or receiving any payment for the transfer?",
      questionType: questionType.BOOLEAN,
      isMoreInfo: true,
      actions: [
        {
          type: actionType.NONE,
          when: false,
        },
        {
          type: actionType.MOREINFO,
          when: true,
          what: {
            name: "ConsiderationAmount",
            questionId: 0,
            questionText: "Please provide more information",
            questionType: questionType.TEXT,
            adornments: "£", maxLength: 18,
            isCurrency: true,
            isNumber: false,
            // rows: 5,
          },
        },
      ],
      required: true,
      error: "Please choose an answer",
    },
    {
      next: 52,
      name: "OccupyingOnCompletion",
      questionId: 51,
      section: "Occupiers",
      questionText:
        "Are you going to be living in the property after completion of this mortgage?",
      questionType: questionType.BOOLEAN,
      actions: [
        {
          type: actionType.NONE,
          when: false,
        },
        {
          type: actionType.GOTO,
          when: true,
          where: 53,
        },
      ],
      required: true,
      error: "Please choose an answer",
    },
    {
      next: 53,
      name: "LetOnCompletion",
      questionId: 52,
      section: "Occupiers",
      questionText:
        "Will the property be let at the date of completion of the remortgage?",
      questionType: questionType.BOOLEAN,
      actions: [
        {
          type: actionType.NONE,
          when: false,
        },
        {
          type: actionType.NONE,
          when: true,
        },
      ],
      required: true,
      error: "Please choose an answer",
    },
    {
      next: 54,
      name: "OccupierStatus",
      questionId: 53,
      section: "Occupiers",
      questionText:
        "Apart from the borrowers, does anyone else aged 17 or over live at the property such as spouse, partner, child, lodger or carer? Do not include children under the age of 17.",
      questionType: questionType.BOOLEAN,
      actions: [
        {
          type: actionType.NONE,
          when: false,
        },
        {
          type: actionType.GOTO,
          when: true,
          where: 58,
},
      ],
      required: true,
      error: "Please choose an answer",
    },
    {
      next: 57,
      name: "FinancialAdvisorQ54",
      questionId: 54,
      section: "Financial Advisor",
      questionText:
        "Has a financial advisor helped you to select or apply for the mortgage?",
      questionType: questionType.BOOLEAN,
      isMoreInfo: true,
      actions: [
        {
          type: actionType.NONE,
          when: false,
        },
        {
          type: actionType.MOREINFO,
          when: true,
          what: {
            name: "BrokerEmail",
            questionId: 0,
            questionText: "Email",
            type: "email",
            questionType: questionType.TEXT,
          },
          required: true
        },
      ],
      required: false,
      error: "Please choose an answer",
    },

    {
      next: 56,
      name: "DmdInstructions",
      questionId: 57,
      skipTo: 55,
      section: "Digital Mortgage Deed",
      questionText:
        "Barclays have agreed with the Land Registry to allow some customers to complete their Mortgage Deed online. If you wish to use this service please select Opt In, otherwise select Opt Out.",
      questionType: questionType.OPTION,
      actions: [
        {
          type: actionType.NONE,
          when: "Opt In",
          label: "Opt In",
        },
        {
          type: actionType.NONE,
          when: "Opt Out",
          label: "Opt Out",
        },
      ],
      required: true,
      error: "Please choose an option",
    },

    {
      next: 55,
      questionId: 56,
      name: "TidInstructions",
      section: "Title Information Document",
      questionText:
        "Following completion of your remortgage we will send you a copy of the title to your property as registered by the land registry. The regulatory body recommends this as best practice as it provides you with the evidence that the transaction has been completed correctly. This is an important document and should be retained by you for future reference. There is a charge of £{TID CHARGE} plus VAT for providing this document. If you do not require this document please tick this box",
      questionType: questionType.CHECKBOX,
      actions: [
        {
          type: actionType.NONE,
          when: true,
        },
        {
          type: actionType.NONE,
          when: false,
        },
      ],
      required: false,
      error: "Please check this box",
    },
    {
      next: "SubmitPage",
      questionId: 55,
      name: "SignatureBorrower1",
      section: "Authorisation",
      questionText: `We authorise:
          a) That the law firm can pass on to any relevant third parties any appropriate information relating to this transaction.
          b) The law firm to obtain information from our existing lender(s), to include redemption statements and our title deeds.We accept that our existing lender(s) may charge a fee for providing this information and that we are responsible for this fee.We are aware that the fee may remain on our account even if we do not complete.
          c) That the law firm may contact us by email, and we accept that the security of messages cannot be guaranteed.
          d) The law firm to send surplus funds to us using the details provided in the questionnaire.
        We confirm:
          e) That we have never been made the subject of a Bankruptcy Order and have not had or are not aware of any pending Action or Order of Bankruptcy against us.
          f) That the property will be insured for the period of the mortgage and all risks are covered as required under the terms of our mortgage offer.Your new lender will be named as the joint policy holder or have its interests noted.The property will be insured for at least the minimum amount stated in our mortgage offer.
          g) That the information provided in this questionnaire is true to the best of our knowledge and can be used by the law firm to complete our mortgage.We understand that we are responsible to notify if any of the information provided changes between now and completion of the mortgage.",`,
      questionType: questionType.CHECKBOX,
      actions: [
        {
          type: actionType.NONE,
          when: true,
        },
        {
          type: actionType.NONE,
          when: false,
        },
      ],
      required: true,
      error: "Please check this box",
      label: "I agree to the above",
    },
    {
      next: 54,
      name: "OccupierNames",
      questionId: 58,
      section: "Occupiers",
      questionText:
        "Please provide more information, including the name, relationship to each person and age.",
      questionType: questionType.OCCUPIER,
      actions: [
        {
          name: "OccupierNamesName",
          questionId: 0,
          questionText: "Name",
          questionType: questionType.TEXT,
        },
        {
          questionType: questionType.SELECT,
          matchTextBox: true,
          name: "OccupierNamesRelationship",
          questionText: "Relationship",
          actions: [
            {
              type: actionType.NONE,
              key: "Dependant",
              label: "Dependant",
            },
            {
              type: actionType.NONE,
              key: "Family Member",
              label: "Family Member",
            },
            {
              type: actionType.NONE,
              key: "Friend",
              label: "Friend",
            },
            {
              type: actionType.NONE,
              key: "Other",
              label: "Other",
            },
          ],
        },
        {
          name: "OccupierNamesAge",
          questionId: 0,
          questionText: "Age",
          questionType: questionType.TEXT,
        },
      ],

      error: "Please choose an answer",
    },
  ],
};
