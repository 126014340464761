// React
import React, { useState, useEffect } from "react";

// Appt
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";

//icons
import ThumbUpOffAltIcon from "@mui/icons-material/ThumbUpOffAlt";
import ThumbDownOffAltIcon from "@mui/icons-material/ThumbDownOffAlt";

// style
import { style } from "../../../../style/style";
import { color } from "../../../../style/color";

function QuestionBoolean(props) {
  const handlerYes = (actions) => {
    props.setFormValuesMulti((prevState) => ({
      ...prevState,
      [props.question.name]: true,
    }));
    if (actions.some((ac) => ac.when)) {
      const action = actions.find((ac) => ac.when);
      props.getAction(action);
      props.handleOnChange(true, props.question);
    }
  };

  const handlerNo = (actions) => {
    props.setFormValuesMulti((prevState) => ({
      ...prevState,
      [props.question.name]: false,
    }));
    if (actions.some((ac) => !ac.when)) {
      const action = actions.find((ac) => !ac.when);
      props.getAction(action);
      props.handleOnChange(false, props.question);
    }
  };

  const getColor = (answer) => {
    if (props.formValues) {
      if (
        (props.formValues[props.question.name] === true && answer) ||
        (props.formValues[props.question.name] === false && !answer)
      ) {
        return color.brandDarkGreen;
      }

      return "";
    }
  };

  return (
    <Box sx={style.booleanButtonRow}>
      <IconButton
        sx={style.booleanButton}
        onClick={() => handlerYes(props.question.actions)}
        style={{
          backgroundColor: getColor(true),
        }}
      >
        <ThumbUpOffAltIcon sx={style.booleanIcon} />
        Yes
      </IconButton>

      <IconButton
        sx={style.booleanButton}
        onClick={() => handlerNo(props.question.actions)}
        style={{
          backgroundColor: getColor(false),
        }}
      >
        <ThumbDownOffAltIcon sx={style.booleanIcon} />
        No
      </IconButton>
    </Box>
  );
}

export default QuestionBoolean;
