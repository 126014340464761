import React, { useState } from 'react';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import TextField from '@mui/material/TextField';
import styled from '@emotion/styled';
import Typography from '@mui/material/Typography';

const CssSelect = styled(DatePicker)(props => {
  const { style } = props;
  return style
    ? { ...style }
    : `& .MuiOutlinedInput-root {
            paddingTop: "0px",
              width: 300,
              height: 40,
              color: "#070606",
              fontWeight: 600,
              "& fieldset": {
                border: "2px solid #418ED1",
                borderRadius: "6px",
                boxShadow: "0 0 2px 1px #418ED1",
              },
              "&:hover": {
                "&& fieldset": {
                  border: "2px solid #418ED1",
                  borderRadius: "6px",
                  boxShadow: "0 0 2px 1px #418ED1",
                },
              },
              "&.Mui-focused": {
                "&& fieldset": {
                  border: "2px solid #418ED1",
                  borderRadius: "6px",
                  boxShadow: "0 0 2px 1px #418ED1",
                },
              },
              }`;
});

function DateSelect(props) {
  const { label, date, setDate, title } = props;
  const [currentError, setCurrentError] = useState(null);
  const [errorDate, setErrorDate] = useState(false);
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <div>
        <Typography {...props} source={'inputLabel'}>
          {title}
        </Typography>
        <CssSelect
          label={label}
          inputFormat="dd/MM/yyyy"
          timezone="Europe/London"
          value={date}
          onChange={setDate}
          onError={reason => {
            if (reason) {
              setCurrentError('Please input a valid date');
              setErrorDate(true);
            } else {
              setCurrentError(null);
              setErrorDate(false);
            }
          }}
          renderInput={props => (
            <TextField
              sx={{
                '& .MuiOutlinedInput-root': {
                  marginTop: '5px',
                  '& input': {
                    color: '#070606',
                    fontWeight: 600,
                    padding: '5px',
                    '&::placeholder': {
                      fontSize: '12px',
                      color: '#070606',
                      textAlign: 'center',
                    },
                  },
                  '& fieldset': {
                    border: '2px solid #418ED1',
                    borderRadius: '6px',
                    boxShadow: '0 0 2px 1px #418ED1',
                    //backgroundColor: "transparent",
                  },
                  '&:hover fieldset': {
                    border: '2px solid #418ED1',
                  },
                  '&.Mui-focused fieldset': {
                    //border: "2px solid #418ED1",
                  },
                  'input.error ': {
                    borderColor: 'red',
                  },
                },
                svg: { color: '#418ED1' },
              }}
              error={errorDate}
              helperText={
                currentError ? (
                  <div
                    style={{
                      background: '#ff4438',
                      color: 'white',
                      marginBottom: '15px',
                      width: '105%',
                      padding: 7,
                      borderRadius: 5,
                      marginTop: -17,
                      marginLeft: -15,
                    }}
                  >
                    <Typography source={'errorText'}>{currentError}</Typography>
                  </div>
                ) : (
                  <></>
                )
              }
              inputProps={{ ...props.inputProps, placeholder: 'DD/MM/YYYY' }}
              {...props}
            />
          )}
        />
      </div>
    </LocalizationProvider>
  );
}

export default DateSelect;
