export const signup = {
  login: {
    subTitle: "Login To Continue",
    email_label: "Email Address*",
    password_label: "Password*",
    password_confirm_label: "Confirm Password*",
    forgotten_label: "I've forgotten my password",
    submit_label: "Submit",
    activate_label: "Activate Account",
  },
  activate: {
    subTitle: "Activate My Account",
    description: "",
    email_label: "Email Address*",
    password_label: "Password*",
    password_confirm_label: "Confirm Password*",
    reference_label: "Reference*",
    postcode_label: "Postcode*",
    terms_condition_label: "I accept the terms and conditions",
    submit_label: "Submit",
    link_label: "< Back",
  },
  reset: {
    subTitle: "Reset Password",
    lastname_label: "Last Name",
    email_label: "Email Address*",
    password_label: "New Password*",
    password_confirm_label: "Confirm Password*",
    reference_label: "Reference*",
    postcode_label: "Postcode*",
    submit_label: "Submit",
    link_label: "< Back",
  },
  create: {
    subTitle: "Create My Account",
    subTitlePassowrd: "Create a Password",
    description: "",
    email_label: "Email Address*",
    password_label: "Password*",
    password_confirm_label: "Confirm Password*",
    reference_label: "Reference*",
    postcode_label: "Postcode*",
    terms_condition_label: "I accept the terms and conditions",
    submit_label: "Submit",
    link_label: "< Back",
  },
  errors: {
    reset: "credentials are invalid",
    unauthorized:
      "Your username or password is incorrect. Try to reset your password",
    already_activated: "User already activated",
    invalid_credentials: "Invalid credentials, please try again",
    error_activate: "Unable to activate account. Please try again",
    invalid_password_url: "Invalid password reset url.",
    email: {
      required: "Email is a required field",
      invalid: "Invalid email format",
    },
    password: {
      required: "Password is a required field",
      invalid: "Password must be at least 8 characters",
    },
    reference: {
      required: "Reference is a required field",
      invalid: "Invalid reference format",
    },
    postcode: {
      required: "Postcode is a required field",
      invalid: "Invalid postcode format",
    },
  },
  message: {
    activate: "Your account has been created successfully",
    reset:
      "If the details you provided are correct your password will have been reset",
  },
};
