import Button from '@mui/material/Button';
import React, { useState } from 'react';
import { LocalStorageService } from '../../../../services/LocalStorageService';
import {
  getAccessToken,
  WebServiceClient,
} from '../../../../services/WebServiceClient';
import Paper from '@mui/material/Paper';
import { style } from '../../../../style/style';
import Alerts from '../../../shared/Alerts';
import CssCheckbox from './components/CssCheckbox';
import CssSelect from './components/CssSelect';
import { CssTextField } from './components/CssTextField';
import DateSelect from './components/DateSelect';
import DropZone from './components/DropZone';
import { UnexpectedERC } from './custom-forms/UnexpectedERC';
import { IncorrectlySignedMortgageDeed } from './custom-forms/IncorrectlySignedMortgageDeed';
import { LeaseholdDispute } from './custom-forms/LeaseholdDispute';
import { MarriageCertificateNeeded } from './custom-forms/MarriageCertificateNeeded';
import { MoreThanOneLender } from './custom-forms/MoreThanOneLender';
import { MoreThanOneTitleNumber } from './custom-forms/MoreThanOneTitleNumber';
import { MortgageDeedReceived } from './custom-forms/MortgageDeedReceived';
import { MortgageOfferExpire } from './custom-forms/MortgageOfferExpire';
import { NeedCopyLease } from './custom-forms/NeedCopyLease';
import { PendingApplication } from './custom-forms/PendingApplication';
import { PropertyAddressDoesNotMatch } from './custom-forms/PropertyAddressDoesNotMatch';
import { PropertyIsBuyToLet } from './custom-forms/PropertyIsBuyToLet';
import { PropertyIsUnencumbered } from './custom-forms/PropertyIsUnencumbered';
import { RedStatReceived } from './custom-forms/RedStatReceived';
import { MismatchNameOnTitleAndInstruction } from './custom-forms/MismatchNameOnTitleAndInstruction';
import { SignedAuthorityRequired } from './custom-forms/SignedAuthorityRequired';
import { UnableToFindProperty } from './custom-forms/UnableToFindProperty';
import { MissingBankDetails } from './custom-forms/MissingBankDetails';
import { BINSRenewal } from './custom-forms/BINSRenewal';
import { MissingFreeholderDetails } from './custom-forms/MissingFreeholderDetails';
import { MissingBINSExpiryDate } from './custom-forms/MissingBINSExpiryDate';
import { MissingMortgageAccountNumber } from './custom-forms/MissingMortgageAccountNumber';

import dayjs from 'dayjs';
var utc = require("dayjs/plugin/utc");
dayjs.extend(utc);


// Store the custom milestones
// Some are complicated so we hard code them
const customMilestones = {
  MissingMortgageAccountNumber: (
    index,
    customInputSubmit,
    mortgage,
    milestone,
  ) => (
    <MissingMortgageAccountNumber
      index={index}
      submitFnc={customInputSubmit}
      mortgage={mortgage}
      milestone={milestone}
    ></MissingMortgageAccountNumber>
  ),
  'Buildings insurance expiry date required': (
    index,
    customInputSubmit,
    mortgage,
    milestone,
  ) => (
    <MissingBINSExpiryDate
      index={index}
      submitFnc={customInputSubmit}
      mortgage={mortgage}
      milestone={milestone}
    ></MissingBINSExpiryDate>
  ),
  MissingFreeholderDetails: (index, customInputSubmit, mortgage, milestone) => (
    <MissingFreeholderDetails
      index={index}
      submitFnc={customInputSubmit}
      mortgage={mortgage}
      milestone={milestone}
    ></MissingFreeholderDetails>
  ),
  'Buildings insurance renewal date required': (
    index,
    customInputSubmit,
    mortgage,
    milestone,
  ) => (
    <BINSRenewal
      index={index}
      submitFnc={customInputSubmit}
      mortgage={mortgage}
      milestone={milestone}
    ></BINSRenewal>
  ),
  MissingBankDetails: (index, customInputSubmit, mortgage, milestone) => (
    <MissingBankDetails
      index={index}
      submitFnc={customInputSubmit}
      mortgage={mortgage}
      milestone={milestone}
    ></MissingBankDetails>
  ),
  'Name mismatch': (index, customInputSubmit, mortgage, milestone) => (
    <MismatchNameOnTitleAndInstruction
      index={index}
      submitFnc={customInputSubmit}
      mortgage={mortgage}
      milestone={milestone}
    ></MismatchNameOnTitleAndInstruction>
  ),
  'Unexpected Early Repayment Charge': (
    index,
    customInputSubmit,
    mortgage,
    milestone,
  ) => (
    <UnexpectedERC
      index={index}
      submitFnc={customInputSubmit}
      mortgage={mortgage}
      milestone={milestone}
    ></UnexpectedERC>
  ),
  'Mortgage offer expires soon': (
    index,
    customInputSubmit,
    mortgage,
    milestone,
  ) => (
    <MortgageOfferExpire
      index={index}
      submitFnc={customInputSubmit}
      mortgage={mortgage}
      milestone={milestone}
    ></MortgageOfferExpire>
  ),
  'Redemption Statement Received': (
    index,
    customInputSubmit,
    mortgage,
    milestone,
  ) => (
    <RedStatReceived
      index={index}
      submitFnc={customInputSubmit}
      mortgage={mortgage}
      milestone={milestone}
    ></RedStatReceived>
  ),
  'More than one current mortgage': (
    index,
    customInputSubmit,
    mortgage,
    milestone,
  ) => (
    <MoreThanOneLender
      index={index}
      submitFnc={customInputSubmit}
      mortgage={mortgage}
      milestone={milestone}
    ></MoreThanOneLender>
  ),
  'Mortgage deed issued': (index, customInputSubmit, mortgage, milestone) => (
    <MortgageDeedReceived
      index={index}
      submitFnc={customInputSubmit}
      mortgage={mortgage}
      milestone={milestone}
    ></MortgageDeedReceived>
  ),
  MoreThanOneTitleNumber: (index, customInputSubmit, mortgage, milestone) => (
    <MoreThanOneTitleNumber
      index={index}
      submitFnc={customInputSubmit}
      mortgage={mortgage}
      milestone={milestone}
    ></MoreThanOneTitleNumber>
  ),
  IncorrectlySignedMortgageDeed: (
    index,
    customInputSubmit,
    mortgage,
    milestone,
  ) => (
    <IncorrectlySignedMortgageDeed
      index={index}
      submitFnc={customInputSubmit}
      mortgage={mortgage}
      milestone={milestone}
    ></IncorrectlySignedMortgageDeed>
  ),
  'Pending application at land registry': (
    index,
    customInputSubmit,
    mortgage,
    milestone,
  ) => (
    <PendingApplication
      index={index}
      submitFnc={customInputSubmit}
      mortgage={mortgage}
      milestone={milestone}
    ></PendingApplication>
  ),
  'Copy current tenancy agreement required': (
    index,
    customInputSubmit,
    mortgage,
    milestone,
  ) => (
    <PropertyIsBuyToLet
      index={index}
      submitFnc={customInputSubmit}
      mortgage={mortgage}
      milestone={milestone}
    ></PropertyIsBuyToLet>
  ),
  'Signed redemption statement authority required': (
    index,
    customInputSubmit,
    mortgage,
    milestone,
  ) => (
    <SignedAuthorityRequired
      index={index}
      submitFnc={customInputSubmit}
      mortgage={mortgage}
      milestone={milestone}
    ></SignedAuthorityRequired>
  ),
  LeaseholdDispute: (index, customInputSubmit, mortgage, milestone) => (
    <LeaseholdDispute
      index={index}
      submitFnc={customInputSubmit}
      mortgage={mortgage}
    ></LeaseholdDispute>
  ),
  'Property address mismatch': (
    index,
    customInputSubmit,
    mortgage,
    milestone,
  ) => {
    return (
      <PropertyAddressDoesNotMatch
        index={index}
        submitFnc={customInputSubmit}
        mortgage={mortgage}
        milestone={milestone}
      ></PropertyAddressDoesNotMatch>
    );
  },
  'Bank Statement required': (
    index,
    customInputSubmit,
    mortgage,
    milestone,
  ) => {
    return (
      <PropertyIsUnencumbered
        index={index}
        submitFnc={customInputSubmit}
        mortgage={mortgage}
        milestone={milestone}
      ></PropertyIsUnencumbered>
    );
  },
  'Please confirm property Title Number': (
    index,
    customInputSubmit,
    mortgage,
    milestone,
  ) => {
    return (
      <UnableToFindProperty
        index={index}
        submitFnc={customInputSubmit}
        mortgage={mortgage}
        milestone={milestone}
      ></UnableToFindProperty>
    );
  },
  'Copy lease required': (index, customInputSubmit, mortgage, milestone) => {
    return (
      <NeedCopyLease
        index={index}
        submitFnc={customInputSubmit}
        mortgage={mortgage}
        milestone={milestone}
      ></NeedCopyLease>
    );
  },
  'Marriage Certificate required': (
    index,
    customInputSubmit,
    mortgage,
    milestone,
  ) => {
    return (
      <MarriageCertificateNeeded
        index={index}
        submitFnc={customInputSubmit}
        mortgage={mortgage}
        milestone={milestone}
      ></MarriageCertificateNeeded>
    );
  },
};

const MilestoneExtras = props => {
  const { data } = props;
  const [formBuilder, setFormBuilder] = useState([...props.data]);
  const [alert, setAlert] = useState({});
  const handleInputData = (index, value, name) => {
    const updatedQuestions = [...formBuilder];
    updatedQuestions[index].fromPortal = {
      ...updatedQuestions[index].fromPortal,
      [name]: value,
    };
    setFormBuilder(updatedQuestions);
  };

  const customInputSubmit = (
    index,
    data,
    complete = true,
    showAlert = true,
  ) => {
    const updatedQuestions = [...formBuilder];
    updatedQuestions[index].fromPortal = data;
    setFormBuilder(updatedQuestions);
    if (complete) {
      checkComplete(index, showAlert);
    } else {
      submitMilestone(showAlert);
    }
  };

  const handleItemComplete = item => {
    switch (item.name) {
      case '':
        return <p>Thank you for providing us with your bank details</p>;
    }
  };
  const checkComplete = (index, showAlert) => {
    if (!Object.values(formBuilder[index].fromPortal).includes('')) {
      setFormBuilder(prevState => {
        const updatedQuestions = [...prevState];
        updatedQuestions[index].completed = true;
        submitMilestone(showAlert);
        return updatedQuestions;
      });
    }
  };
  const handleInput = (item, index) => {
    switch (item.type) {
      case 'text':
        return (
          <div style={{ padding: 5 }}>
            <CssTextField
              name={item.dataTag}
              placeholder={item.label}
              value={formBuilder[index]?.fromPortal?.[item.dataTag]}
              onChange={e => {
                e.preventDefault();
                handleInputData(index, e.target.value, e.target.name);
              }}
            />
          </div>
        );
      case 'select':
        return (
          <CssSelect
            key={index}
            name={item.dataTag}
            placeholder={item.label}
            data={item.options || [{ label: 'Test', value: 'Test' }]}
            value={formBuilder[index]?.fromPortal?.[item.dataTag]}
            onChange={e => {
              e.preventDefault();
              handleInputData(index, e.target.value.value, item.dataTag);
            }}
          />
        );
      case 'checkbox':
        return (
          <CssCheckbox
            key={index}
            name={item.dataTag}
            label={item.label}
            checked={formBuilder[index]?.fromPortal?.[item.dataTag]}
            onClick={e => {
              console.log(formBuilder[index]?.fromPortal);
              handleInputData(
                index,
                !formBuilder[index]?.fromPortal?.[item.dataTag],
                e.target.name,
              );
            }}
          />
        );
      case 'download':
        return (
          <a name={item.dataTag} href={item.fileUrl} download key={index}>
            <Button
              variant="contained"
              style={{ marginTop: '10px' }}
              sx={style.darkBlueButton}
            >
              Download
            </Button>
          </a>
        );
      case 'upload':
        return (
          <DropZone
            key={index}
            name={item.dataTag}
            setFile={value => handleInputData(index, value, item.dataTag)}
            logo={formBuilder[index]?.fromPortal?.[item.dataTag].logo?.fileUrl}
          />
        );
      case 'datetime':
        // Set date time first
        return (
          <DateSelect
            key={index}
            label={item.label}
            date={formBuilder[index]?.fromPortal?.[item.dataTag] || null}
            setDate={value => {
              value = dayjs(value).utc(true).toISOString()
              handleInputData(index, value, item.dataTag);
            }}
          />
        );
      default:
        // code block
        return <></>;
    }
  };
  const submitMilestone = showAlert => {
    const org = JSON.parse(LocalStorageService.get('organisation'));
    const config = {
      headers: { Authorization: getAccessToken() },
    };
    const params = {
      orgId: org.id,
      siteId: null,
    };

    WebServiceClient.patch(
      '/mortgage/' + props.mortgage.Mortgage.id + '/milestone',
      formBuilder,
      params,
      config,
    )
      .then(function (response) {
        if (showAlert) {
          setAlert({
            open: true,
            message: 'Thank you for providing this. The milestone action could still show as outstanding until we review this. If we require anything further we will contact you.',
            severity: 'success',
          });
        }
      })
      .catch(function (error) {
        if (showAlert) {
          setAlert({
            open: true,
            message: error.toString(),
            severity: 'error',
          });
        }
      });
  };
  if (data.length < 1) return <></>;
  return (
    <div
      onClick={e => {
        e.preventDefault();
        e.stopPropagation();
      }}
    >
      <div
        style={{
          display: 'flex',
          height: 'auto',
          flexDirection: 'row',
          paddingTop: 20,
        }}
      >
        <div>
          {formBuilder?.map((item, index) =>
            item.completed === false ? (
              // Is this a custom form ?
              customMilestones[item.name] ? (
                customMilestones[item.name](
                  index,
                  customInputSubmit,
                  props.mortgage,
                  item,
                )
              ) : (
                <Paper style={style.remortgage}>
                  <div style={{ display: 'flex' }}>
                    <div key={index}>
                      <p>
                        {index + 1}. {item.name}
                      </p>
                      {item?.toPortal.length > 0 ? (
                        <p style={{ fontSize: '12px', margin: '1', whiteSpace: 'pre-line' }}>
                          {item.bodyText}
                        </p>
                      ) : (
                        <></>
                      )}
                      {item?.toPortal?.map(portalItem => {
                        return <>{handleInput(portalItem, index)}</>;
                      })}
                    </div>
                    <Button
                      style={{
                        color: 'white',
                        height: '40px',
                        width: '100px',
                        marginTop: 'auto',
                        marginRight: 'auto',
                      }}
                      sx={style.darkGreenButton}
                      onClick={() => {
                        checkComplete(index);
                      }}
                    >
                      Submit
                    </Button>
                  </div>
                </Paper>
              )
            ) : (
              <React.Fragment key={index}>{}</React.Fragment>
            ),
          )}
        </div>
      </div>
      <Alerts alert={alert} setAlert={setAlert} />
    </div>
  );
};

export default MilestoneExtras;
