import React, { useState, useEffect } from "react";

// mui
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";

//icon
import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";
import SentimentSatisfiedAltIcon from "@mui/icons-material/SentimentSatisfiedAlt";

// style
import { style } from "../../../../style/style";
import { color } from "../../../../style/color";

export default function SuccessPage(props) {
  return (
    <Grid
      item
      xs={12}
      style={{ padding: "15px", paddingTop: "100px", boxShadow: 3 }}
      sx={style.successPage}
    >
      <Box sx={style.trophyBox}>
        <EmojiEventsIcon style={{ fontSize: "130px" }} />
        <Typography
          style={{ fontSize: "25px", fontWeight: "700", color: color.smoke }}
        >
          <Box> Questionnaire </Box> <Box>Complete! </Box>
        </Typography>
      </Box>
      {/*<Box>
        <SentimentSatisfiedAltIcon sx={style.smileyIcon} />
        <Typography style={{ fontSize: "20px" }} sx={style.successPageText}>
          How did we do?
        </Typography>
        <Typography sx={style.successPageText} style={{ fontSize: "15px" }}>
          Give use your feedback to help improve our service.
        </Typography>
  </Box>*/}
      {/* <Button sx={style.successButton} fullWidth onClick={() => {}}>
        Take our short survey
      </Button> */}
    </Grid>
  );
}
