import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Formik } from "formik";

// components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import { CardMedia } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Link from "@mui/material/Link";
import Alerts from "../shared/Alerts";
import CircularProgress from "@mui/material/CircularProgress";
// data
import { signup } from "../../resources/signup-data";
// style
import { style } from "../../style/style";
import { styled } from "@mui/material/styles";

// services
import InputAdornment from "@mui/material/InputAdornment";
import { password_reset_schema } from "../../config/validation";
import zxcvbn from "zxcvbn";
import { BsEyeFill, BsEyeSlashFill } from "react-icons/bs";
import { WebServiceClient } from "../../services/WebServiceClient";
import Tooltip from "@mui/material/Tooltip";

const CssTextField = styled(TextField)({
  "& .MuiOutlinedInput-root": {
    paddingTop: "0px",
    "& input": {
      color: "white",
      "&::placeholder": {
        color: "white",
        textAlign: "center",
      },
    },
    "& fieldset": {
      borderColor: "white",
      backgroundColor: "transparent",
    },
    "&:hover fieldset": {
      borderColor: "white",
    },
    "&.Mui-focused fieldset": {
      borderColor: "white",
    },
  },
});

export default function CreatePassword() {
  const navigate = useNavigate();
  const location = useLocation();
  const [alert, setAlert] = useState({});
  const [loading, setLoading] = useState(false);
  const [strength, setStrength] = useState(0);
  const [passVisible, setPassVisible] = useState(false);
  const [passCheckVisible, setPassCheckVisible] = useState(false);
  const [signupData, setSignupData] = useState(signup.create);
  const checkPassword = (value) => {
    const { score } = zxcvbn(value);
    setStrength(score);
  };
  const handleStrength = () => {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-around",
        }}
      >
        <div
          style={{
            backgroundColor: strength >= 1 ? "#ff000b" : "#707070FF",
            margin: 5,
            width: 50,
            height: 4,
            borderRadius: 10,
          }}
        ></div>
        <div
          style={{
            backgroundColor: strength >= 2 ? "#f68339" : "#707070FF",
            margin: 5,
            width: 50,
            height: 4,
            borderRadius: 10,
          }}
        ></div>
        <div
          style={{
            backgroundColor: strength >= 3 ? "#fffc00" : "#707070FF",
            margin: 5,
            width: 50,
            height: 4,
            borderRadius: 10,
          }}
        ></div>
        <div
          style={{
            backgroundColor: strength >= 4 ? "#35e503" : "#707070FF",
            margin: 5,
            width: 50,
            height: 4,
            borderRadius: 10,
          }}
        ></div>
      </div>
    );
  };
  const onSubmit = (values) => {
    if (strength < 3) {
      setAlert({
        open: true,
        severity: "error",
        message:
          "Password must at least reach the yellow indicator on the strength meter",
        action: false,
      });
      return;
    }
    const data = {
      email: location.state.email,
      matterReference: location.state.reference,
      postcode: location.state.postcode,
      // token: location.state.token,
      password: values.password,
    };
    WebServiceClient.post("/knights-auth/password-reset", data, {}, {})
      .then(function (response) {
        navigate("/login", {
          state: {
            email: location.state.email,
            alert: {
              open: true,
              severity: "success",
              message: signup.message.activate,
              action: false,
            },
          },
        });
      })
      .catch(function (error) {
        if (error.response.status === 400) {
          navigate("/login", {
            state: {
              email: location.state.email,
              alert: {
                open: true,
                severity: "error",
                message: signup.errors.invalid_credentials,
                action: false,
              },
            },
          });
        } else {
          setAlert({
            open: true,
            severity: "error",
            message: signup.errors.error_activate,
            action: false,
          });
        }
      });
  };

  const onClickLink = (values) => {
    navigate("/activate");
  };

  return (
    <Grid item xs={12}>
      {loading ? (
        <CircularProgress
          size={68}
          sx={{
            color: "white",
            position: "absolute",
            margin: "auto",
            zIndex: 1,
          }}
        />
      ) : (
        <Card sx={style.signup} elevation={0}>
          <CardMedia style={style.logo} alt="Integrar Logo" />
          <CardHeader
            title=""
            subheader={signupData.subTitlePassowrd}
            titleTypographyProps={{ variant: "h2" }}
            subheaderTypographyProps={{
              variant: "h5",
              color: "secondary.main",
            }}
          />
          <Formik
            validationSchema={password_reset_schema}
            initialValues={{
              password: "",
              passwordCheck: "",
              terms: false,
            }}
            onSubmit={(values) => {
              onSubmit(values);
            }}
          >
            {({ values, errors, touched, handleChange, handleSubmit }) => {
              return (
                <form noValidate onSubmit={handleSubmit}>
                  <CardContent>
                    {signupData.description !== "" ? (
                      <Typography variant="caption">
                        {signupData.description}
                      </Typography>
                    ) : null}
                    <CssTextField
                      type={passVisible ? "text" : "password"}
                      name="password"
                      placeholder={signupData.password_label}
                      sx={style.cssTextField}
                      value={values.password}
                      onChange={(e) => {
                        handleChange(e);
                        checkPassword(e.target.value);
                      }}
                      InputProps={{
                        [`endAdornment`]: (
                          <Tooltip title="Your password should be a least 12 characters long and contain a mix of uppercase and lowercase letters, numbers and symbols.">
                            <div
                              onClick={() => setPassVisible(!passVisible)}
                              style={{
                                cursor: "pointer",
                              }}
                            >
                              {passVisible ? (
                                <InputAdornment position={"end"}>
                                  <BsEyeFill
                                    style={{
                                      width: 40,
                                      height: 40,
                                      paddingRight: 10,
                                    }}
                                  />
                                </InputAdornment>
                              ) : (
                                <InputAdornment position={"end"}>
                                  <BsEyeSlashFill
                                    style={{
                                      width: 40,
                                      height: 40,
                                      paddingRight: 10,
                                    }}
                                  />
                                </InputAdornment>
                              )}
                            </div>
                          </Tooltip>
                        ),
                      }}
                    />
                    <Card
                      className="error"
                      sx={
                        errors.password && touched.password && errors.password
                          ? style.error
                          : ""
                      }
                    >
                      {errors.password && touched.password && errors.password}
                    </Card>
                    {handleStrength()}
                    <CssTextField
                      type={passCheckVisible ? "text" : "password"}
                      name="passwordCheck"
                      placeholder={signupData.password_confirm_label}
                      sx={style.cssTextField}
                      style={{ marginTop: 20 }}
                      value={values.passwordCheck}
                      onChange={handleChange}
                      InputProps={{
                        [`endAdornment`]: (
                          <Tooltip title="This must match the password in the field above.">
                            <div
                              onClick={() =>
                                setPassCheckVisible(!passCheckVisible)
                              }
                              style={{
                                cursor: "pointer",
                              }}
                            >
                              {passCheckVisible ? (
                                <InputAdornment position={"end"}>
                                  <BsEyeFill
                                    style={{
                                      width: 40,
                                      height: 40,
                                      paddingRight: 10,
                                    }}
                                  />
                                </InputAdornment>
                              ) : (
                                <InputAdornment position={"end"}>
                                  <BsEyeSlashFill
                                    style={{
                                      width: 40,
                                      height: 40,
                                      paddingRight: 10,
                                    }}
                                  />
                                </InputAdornment>
                              )}
                            </div>
                          </Tooltip>
                        ),
                      }}
                    />
                    <Card
                      className="error"
                      sx={
                        errors.passwordCheck &&
                        touched.passwordCheck &&
                        errors.passwordCheck
                          ? style.error
                          : ""
                      }
                    >
                      {errors.passwordCheck &&
                        touched.passwordCheck &&
                        errors.passwordCheck}
                    </Card>
                    <CardActions
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                      }}
                    >
                      <Button
                        type="submit"
                        variant="contained"
                        color="button"
                        sx={{ width: "350px", color: "white" }}
                      >
                        {signupData.submit_label}
                      </Button>
                    </CardActions>
                  </CardContent>

                  <CardContent>
                    <Box direction="row">
                      <Link
                        style={{ cursor: "pointer" }}
                        underline="hover"
                        variant="caption2"
                        color="secondary.main"
                        onClick={() => onClickLink()}
                      >
                        {signupData.link_label}
                      </Link>
                    </Box>
                  </CardContent>
                </form>
              );
            }}
          </Formik>
        </Card>
      )}
      <Alerts alert={alert} setAlert={setAlert} />
    </Grid>
  );
}
