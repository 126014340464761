import { React, useEffect } from 'react'

import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import { style } from "../../style/style";


function Cookies(props) {

  return (
    <Grid container spacing={2} sx={style.staticPage}>
      <Paper style={style.staticPage}>
        <Grid container>

          <Grid item xs={12}>
            <Stack
              direction="column"
              justifyContent="flex-start"
              alignItems="flex-start"
              textAlign="left"
              spacing={0}
            >  
              <h2>Integrar - Cookie Policy</h2>
              <h3>Information about our use of cookies</h3>
              <p>
                  Our portal uses cookies to distinguish you from other users of our portal. This helps us to provide you with a good experience when you browse our portal and also allows us to improve our portal. By continuing to browse the portal, you are agreeing to our use of cookies.

              </p>
              <p>
              A cookie is a small file of letters and numbers that we store on your browser or the hard drive of your computer if you agree. Cookies contain information that is transferred to your computer's hard drive.
              </p>
              <p>
                We use the following cookies:
              </p>
              <ul>
                <li>
                  <strong>Strictly necessary cookies</strong>
                  . These are cookies that are required for the operation of our portal.
                  They include, for example, cookies that enable you to log into secure
                  areas of our portal.
                </li>
                <li>
                  <strong>Analytical/performance cookies</strong>
                  . They allow us to recognise and count the number of visitors and to
                  see how visitors move around our portal when they are using it. This
                  helps us to improve the way our portal works, for example, by ensuring
                  that users are finding what they are looking for easily.
                </li>
              </ul>
              <p>
                You can find more information about the individual cookies we use and the
                purposes for which we use them in the list below:
              </p>

              <ul>
                <li><strong>Analytics Cookies</strong>
                  <ul>
                    <li><strong>_ga:</strong> Registers a unique ID Cookies that is used to generate statistical data on how the visitor uses the portal.
                       <strong>Expires in 2 Years</strong>
                    </li>
                    <li><strong>_gat:</strong> Used by Google Analytics to throttle request rate.&nbsp;
                      <strong>Expires at end of browser</strong>
                    </li>
                    <li><strong>_gat:</strong> Registers a unique ID that is used to generate statistical data on how the visitor uses the portal.&nbsp;
                      <strong>Expires in 24 hours</strong>
                    </li>
                  </ul>
                </li>
                <li>
                  <strong>Session</strong>
                  <ul>
                    <li>
                      <strong>_gid:</strong> Registers a unique ID
                      that is used to generate
                      statistical data on how the
                      visitor uses the portal. 
                       <strong>Expires in 24 hours</strong>
                    </li>
                  </ul>
                </li>
                <li>
                  <strong>Necessary:</strong>
                  <ul>
                    <li>
                      <strong>cookieAcceptance:</strong> Stores the user's cookie consent state for the current portal. 
                      <strong>Expires in 1 Year</strong>
                    </li>
                  </ul>
                </li>
              </ul>

              <p>Please note that third parties (including, for example, advertising networks and providers of external services like web traffic analysis services) may also use cookies, over which we have no control. These cookies are likely to be analytical/performance cookies or targeting cookies.</p>
              <p>You can block cookies by activating the setting on your browser that allows you to refuse the setting of all or some cookies. However, if you use your browser settings to block all cookies (including essential cookies) you may not be able to access all or parts of our portal.</p>
            </Stack>
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  )
}

export default Cookies
