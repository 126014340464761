import { useState } from "react";

// mui
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";

// data
import { form } from "../../resources/form-data";
import { data } from "../../config/data";
import DragDropFileDialog from "./DragDropFileDialog";
import { color } from "../../style/color";

export default function UploadDocumentDialog(props) {
  const [documentSelected, setDocumentSelected] = useState("BQ");
  const [isOther, setIsOther] = useState(false);
  const [isUpload, setIsUpload] = useState(false);
  const [documentType, setDocumentType] = useState("");

  const handleClose = () => {
    props.setOpenDialog(false);
    setIsUpload(false);
    setDocumentSelected("BQ");
    setDocumentType("");
    setIsOther(false);
  };

  const handleChange = (e) => {
    setDocumentSelected(e.target.value);
    if (e.target.value === "OTH") {
      setIsOther(true);
    } else {
      setIsOther(false);
    }
  };

  const handleDocumentTypeChange = (event) => {
    setDocumentType(event.target.value);
  };
  return (
    <Dialog open={props.openDialog} onClose={handleClose}>
      <DialogTitle style={{ fontSize: "20px" }}>
        {form.upload_dialog.title_label}
      </DialogTitle>
      <div
        style={{
          fontSize: ".7em",
          paddingLeft: 10,
          paddingRight: 10,
          width: "80%",
          textAlign: "center",
          alignSelf: "center",
        }}
      >
        Select the type of document you&apos;re uploading from the list below
      </div>
      <DialogContent>
        {!isUpload ? (
          <>
            <FormControl fullWidth>
              <Select value={documentSelected} onChange={handleChange}>
                {data.documents.map((d) => (
                  <MenuItem
                    style={{ color: "black" }}
                    key={d.value}
                    value={d.value}
                  >
                    {d.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            {isOther ? (
              <div style={{ paddingTop: "10px" }}>
                <TextField
                  placeholder="Enter the type of document"
                  value={documentType}
                  onChange={handleDocumentTypeChange}
                />
              </div>
            ) : null}
            <div style={{ padding: "10px" }}>
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                spacing={2}
              >
                <Button onClick={() => handleClose()}>
                  {form.upload_dialog.button_cancel_label}
                </Button>
                <Button
                  onClick={() => setIsUpload(true)}
                  variant="contained"
                  style={{ backgroundColor: color.brandDarkBlue }}
                >
                  {form.upload_dialog.button_next_label}
                </Button>
              </Stack>
            </div>
          </>
        ) : (
          <DragDropFileDialog
            documentSelected={documentSelected}
            documentType={documentType}
            handleClose={handleClose}
            setIsUpload={setIsUpload}
            mortgage={props.mortgage}
            setRecords={props.setRecords}
            records={props.records}
            setExpanded={props.setExpanded}
          />
        )}
      </DialogContent>
    </Dialog>
  );
}
