// React
import React, { useState } from "react";
// import PropTypes from 'prop-types'
// mui
import Typography from "@mui/material/Typography";
import { FaPlus } from "react-icons/fa";
import { questionType } from "../../../../enums/QuestionType";
import QuestionDate2 from "./QuestionDate2";
import QuestionTextField from "./QuestionTextField";
import QuestionOccupierSelect from "./QuestionOccupierSelect";
// style
import { style } from "../../../../style/style";
import { actionType } from "../../../../enums/ActionType";
import { v4 as uuid } from "uuid";

function QuestionOccupier(props) {
  const [actions, setActions] = useState(props.question.actions);
  const [listIndex, setListIndex] = useState(2);
  const getType = (question) => {
    switch (question.questionType) {
      case questionType.PLAINTEXT:
        return (
          <Typography style={{ color: "black" }}>
            {question.questionText}
          </Typography>
        );

      case questionType.DATE:
        return (
          <QuestionDate2
            question={question}
            handleOnChange={props.handleOnChange}
            formValuesMulti={props.formValuesMulti}
            setFormValuesMulti={props.setFormValuesMulti}
            formValues={props.formValues}
          />
        );

      case questionType.TEXT:
        return (
          <QuestionTextField
            question={question}
            handleOnChange={props.handleOnChange}
            formValuesMulti={props.formValuesMulti}
            setFormValuesMulti={props.setFormValuesMulti}
            formValues={props.formValues}
            style={{ width: 180 }}
          />
        );

      case questionType.SELECT:
        return (
          <QuestionOccupierSelect
            question={question}
            handleOnChange={props.handleOnChange}
            formValuesMulti={props.formValuesMulti}
            setFormValuesMulti={props.setFormValuesMulti}
            formValues={props.formValues}
            style={{ width: 180 }}
          />
        );
      default:
    }
  };

  const chunks = actions.reduce((acc, curr, index) => {
    if (index % 3 === 0) {
      return [...acc, [curr]];
    }
    acc[acc.length - 1].push(curr);
    return acc;
  }, []);

  // const handleDelete = (id) => {
  //   setActions(actions.filter((item) => item.id !== id));
  // };

  const handleAdd = () => {
    if (listIndex <= 10) {
      setListIndex(listIndex + 1);
      const id = uuid();
      props.question.actions.push(
        {
          id,
          name: `OccupierNamesName${listIndex}`,
          questionId: 0,
          questionText: "Name",
          questionType: questionType.TEXT,
        },
        {
          id,
          questionType: questionType.SELECT,
          matchTextBox: true,
          name: `OccupierNamesRelationship${listIndex}`,
          questionText: "Relationship",
          actions: [
            {
              type: actionType.NONE,
              key: "Dependant",
              label: "Dependant",
            },
            {
              type: actionType.NONE,
              key: "Family Member",
              label: "Family Member",
            },
            {
              type: actionType.NONE,
              key: "Friend",
              label: "Friend",
            },
            {
              type: actionType.NONE,
              key: "Other",
              label: "Other",
            },
          ],
        },
        {
          id,
          name: `OccupierNamesAge${listIndex}`,
          questionId: 0,
          questionText: "Age",
          questionType: questionType.TEXT,
        }
      );
      setActions([...props.question.actions]);
    } else {
      alert("You can only add up to 10 occupiers");
    }
  };

  return (
    <>
      {chunks.map((q, index) => {
        return (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              marginTop: 10,
              height: 50,
              overflow: "hidden",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
                gap: 35,
                width: '100%'
              }}
            >
              {q.map((item) => getType(item))}
              {props.multiRequired.includes(q.name) && (
                <Typography style={[style.questionErrorMulti]}>
                  {q.error}
                </Typography>
              )}
            </div>
          </div>
        );
      })}
      <div
        style={{
          borderRadius: 180,
          backgroundColor: "#65BA98",
          color: "white",
          width: 40,
          height: 40,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginTop: 5,
          marginRight: 0,
          marginLeft: "auto",
        }}
        onClick={handleAdd}
      >
        <FaPlus />
      </div>
    </>
  );
}

export default QuestionOccupier;
