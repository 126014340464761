export const milestonesData = [
  {
    name: "Welcome pack issued",
    key: "WelcomePackIssued",
    actionKey: "WelcomePackIssued",
    incomplete: "Login To Continue",
    complete:
      "You have now received our welcome letter introducing ourselves together with further details on how your remortgage will progress and a list of the agreed additional fees should any additional work be required.",
  },
  {
    name: "Questionnaire",
    key: "QuestionnaireReceived",
    actionKey: "Questionnaire",
    incomplete:
      "Your new lender has sent us some information about your Remortgage application. So that we can progress your case further, please can you check that the information we have received is correct and provide us with some additional information by completing the questionnaire.",
    complete:
      "Thank you for providing us with the information about you and your property. We are now using that information to obtain other information from third parties in the transaction so that we can progress your case to the next stage.",
  },
  {
    name: "Property details checked",
    key: "PropertyRegisterChecked",
    actionKey: "PropertyRegisterChecked",
    incomplete:
      "If your property is registered at HM Land Registry, we will obtain a copy of the Property Register. We are required to check this on behalf of your new lender and ensure that the information they hold on their mortgage offer matches the property register. We are also required to ensure that your new lender's charge/mortgage can be registered as a first ranking charge on the property register.",
    complete:
      "We have obtained a copy of your title and have checked this in accordance with our clients instructions. If any additional legal work has resulted from our carrying out this check we will contact you to explain the next steps.",
  },
  {
    name: "Redemption Statement",
    key: "RedemptionStatementReceived",
    actionKey: "RedemptionStatement",
    incomplete:
      "If you have a current mortgage registered against your property and you are repaying this mortgage on completion of your new mortgage, we will need to obtain a redemption statement from your existing lender. A redemption statement is only valid for a short period of time, so when we request this will depend upon when you have indicated to us that you wish to complete the Remortgage or whether you have an early redemption charge. We will use the redemption statement to calculate the redemption figure for the proposed completion date. We can then alert you as to whether there is a surplus due to you or some monies required from you, called a shortfall, in order to complete the Remortgage.",
    complete:
      "We have received a redemption statement from your existing lender. This is shown in the Existing lender section. The statement will tell us how much we are required to send to your existing lender to pay off their mortgage on completion. The redemption figure changes on a daily basis and most lenders provide a daily rate of interest to add to the redemption figure if completion takes place after the date of the redemption statement. Please note that the redemption statement might expire and in that case we will request an updated copy.",
  },
  {
    name: "Mortgage Offer",
    key: "MortgageOfferReceived",
    actionKey: "MortgageOffer",
    incomplete:
      "Your lender will send a Mortgage Offer to us usually after you have received your copy. The Mortgage Offer will provide us with details of the amount your new lender is lending and will send to us on completion. It will also tell us if your new lender has placed any special conditions you need to adhere to in exchange for their lending.",
    complete:
      "We have received and checked your mortgage offer. If there are any special conditions which we need to ensure are completed prior to your new lender releasing the advance then we will contact you. Please read the Mortgage Offer in detail and pay particular attention to any special conditions which you need to action.",
  },
  {
    name: "Mortgage Deed",
    key: "MortgageDeedReceived",
    actionKey: "MortgageDeed",
    incomplete:
      "We will ask you to sign a Mortgage Deed. The Mortgage Deed is the document which shows that you have agreed to repay the mortgage on the terms of the Mortgage Offer and conditions. If we make available to you a Mortgage Deed then you need to sign this in front of a witness and we need you to post the original signed copy to us. We cannot accept an electronic copy. Your new lender may have the facility for you to sign your Mortgage deed electronically directly with the Land Registry. If your new lender does this, we will inform you and provide you with that option.",
    complete: "we have received your signed Mortgage Deed.",
  },
  {
    name: "Proposed Completion",
    key: "PreCompletionSetup",
    actionKey: "ProposedCompletion",
    incomplete:
      "Once we have every piece of information and every document we require, we will set a completion date taking into account your preferred date or whether any early repayment charges need to expire. At this stage we will also request that your new lender releases your mortgage advance to us in time for completion. Should your new mortgage be an amount less than needed to pay off your old mortgage we will inform you of the amount of money we need you to send to us and how you can pay this to us.",
    complete:
      "We have set a completion date {date} and requested the mortgage monies from your new lender.",
  },
  {
    name: "Completion",
    key: "CompletionDate",
    actionKey: "Completion",
    incomplete:
      "On the day of completion we will repay your current mortgage and send any surplus monies to you. We will inform you by email when your case has completed. You do not need to check with us.",
    complete:
      "We have completed your new mortgage and informed you. We will now register the new mortgage against your property.",
  },
];
