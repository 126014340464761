export const data = {
  documents: [
    { label: "Questionnaire", value: "BQ" },
    {
      label: "Authority to Update Land Registry",
      value: "LR",
    },
    { label: "Bank Details", value: "BD" },
    { label: "Bank Statement", value: "BS" },
    { label: "Authority and Confirmation form", value: "AC" },
    { label: "Mortgage Offer Acceptance", value: "MOA" },
    { label: "BTL Confirmation Form", value: "BTL" },
    { label: "Occupiers Waiver/Consent", value: "OCC" },
    { label: "Marriage Certificate", value: "MC" },
    { label: "Certified ID", value: "ID" },
    { label: "Signed Terms of Business", value: "TOB" },
    { label: "Tenancy Agreement", value: "TA" },
    { label: "Lease", value: "LS" },
    { label: "Other", value: "OTH" },
  ],
  phones: [
    { key: "W00000000001542", value: "0113 513 5376" },
    { key: "A00000000001023", value: "0113 513 5382" },
    { key: "T00000000022117", value: "0113 513 5381" },
    { key: "L00000000016476", value: "0113 513 5378" },
    { key: "A00000000055598", value: "0113 513 5379" },
    { key: "A00000000001000", value: "0113 513 5385" },
    { key: "N00000000022124", value: "0113 513 5375" },
    { key: "A00000000047257", value: "0113 513 5379" },
    { key: "B00000000032500", value: "(0113) 513 5390" },
    { key: "H00000000022500", value: "(0113) 513 5390" },
    { key: "L00000000029000", value: "(0113) 513 5390" },
  ],
};
