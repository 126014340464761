import React, { useState } from "react";
import Box from "@mui/material/Box";
import { FileUploader } from "react-drag-drop-files";
import { Button } from "@mui/material";
import { style } from "../../../../../style/style";

function DropZone(props) {
  const { setFile, logo } = props;
  const [fileSelected, setFileSelected] = useState(logo);
  const [error, setError] = useState(null);

  const checkFile = (file) => {
    const extensions = ["jpeg", "png", "gif"];
    const type = file.type.split("/")[1];
    if (extensions.includes(type)) {
      setFileSelected(URL.createObjectURL(file));
      setError(null);
      createFile(file);
    } else {
      setFileSelected(null);
      setError("only jpg, png and gif");
    }
  };
  const createFile = async (file) => {
    const formData = new FormData();
    formData.append("file", file);
    setFile(formData);
  };
  const dragDropFileTypes = ["JPG", "PNG", "GIF", "PDF", "JPEG"];
  return (
    <div style={{ width: 350 }}>
      <FileUploader
        handleChange={checkFile}
        name="file"
        types={dragDropFileTypes}
        classes={"drag"}
      >
        <label htmlFor="file_upload">
          <Button
            variant="contained"
            style={{ marginTop: "10px" }}
            sx={style.darkBlueButton}
            style={{ width: 200 }}
          >
            Upload
          </Button>

          {fileSelected ? (
            <img
              key={fileSelected}
              src={fileSelected}
              style={{
                height: 150,
                width: 350,
                backgroundRepeat: "no-repeat",
                position: "absolute",
                "object-fit": "contain",
              }}
              alt=" "
              onError={(ev) => {
                ev.target.src = " ";
              }}
            />
          ) : (
            <></>
          )}
          <input
            id="file_upload"
            type="file"
            onChange={(ev) => {
              checkFile(ev.target.files[0]);
            }}
            style={{ display: "none" }}
          />
          {fileSelected && (
            <Box>{fileSelected ? fileSelected?.name : null}</Box>
          )}
          {error && <Box>{error}</Box>}
        </label>
      </FileUploader>
    </div>
  );
}

export default DropZone;
