import Stack from "@mui/material/Stack";
import Paper from "@mui/material/Paper";
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import React, { useState } from 'react';
import DragDropFileDialog from '../../../../../components/dialogs/DragDropFileDialog';
import { style } from '../../../../../style/style';
import CssCheckbox from '../components/CssCheckbox';
import { CssTextField } from '../components/CssTextField';

const STEPS = Object.freeze({
	getDisputeStatus: 1,
	messageOrDocument: 2,
	messageBox: 3,
	uploadDocument: 4
})

export const LeaseholdDispute = props => {
  const [outputs, setOutputs] = useState({
    onGoingDispute: false,
    noDispute: true,
  });

  const [step, setStep] = useState(STEPS.getDisputeStatus);
  const [openDialog, setOpenDialog] = useState(true);
  const onChangeCheckbox = e => {
    const updatedQuestions = { ...outputs };

    switch (e.target.name) {
      case 'onGoingDispute':
        updatedQuestions['onGoingDispute'] = !!e.target.value;
        updatedQuestions['noDispute'] = false;
        break;

      case 'noDispute':
        updatedQuestions['onGoingDispute'] = false;
        updatedQuestions['noDispute'] = !!e.target.value;
        break;
    }

    setOutputs(updatedQuestions);
  };
  const onChange = e => {
    const updatedQuestions = { ...outputs };
    updatedQuestions[e.target.name] = e.target.value;

    setOutputs(updatedQuestions);
  };

  const renderStep = step => {
    switch (step) {
      case STEPS.getDisputeStatus:
        return (
          <Stack>
            <div style={{ padding: 5 }}>
              <CssCheckbox
                name="onGoingDispute"
                label="Yes, we have an ongoing dispute and will send you the details of this."
                checked={outputs['onGoingDispute']}
                onClick={e => {
                  onChangeCheckbox(e);
                }}
              />
              <CssCheckbox
                name="noDispute"
                label="No, we do not have an ongoing dispute."
                checked={outputs['noDispute']}
                onClick={e => {
                  onChangeCheckbox(e);
                }}
              />
            </div>
            <Button
              variant="contained"
              style={{
                color: 'white',
                // height: '40px',
                // width: '100px',
                margin: '10px',
              }}
              sx={style.darkGreenButton}
              onClick={() => {
                // Validate
                // Return run on submit function from parent
                // Return
                if (outputs['noDispute'])
                  return props.submitFnc(props.index, outputs);

                setStep(STEPS.messageOrDocument);
              }}
            >
              Submit
            </Button>
          </Stack>
        );
      case STEPS.messageOrDocument:
        return (
          <>
            <Stack>
              <p style={{ fontSize: '12px', margin: '1' }}>
                Please send us the details of the ongoing dispute
              </p>
              <Button
                variant="contained"
                style={{
                  color: 'white',
                  // height: '40px',
                  // width: '100px',
                  margin: '10px',
                }}
                sx={style.darkGreenButton}
                onClick={() => {
                  // Show the user a message box
                  setStep(STEPS.messageBox);
                }}
              >
                Message
              </Button>
              <Button
                variant="contained"
                style={{
                  color: 'white',
                  // height: '40px',
                  // width: '100px',
                  margin: '10px',
                }}
                sx={style.darkGreenButton}
                onClick={() => {
                  // Show the user a message box
                  setStep(STEPS.uploadDocument);
                }}
              >
                Upload A Document
              </Button>
              <Button
                variant="contained"
                style={{
                  color: 'white',
                  // height: '40px',
                  // width: '100px',
                  margin: '10px',
                }}
                sx={style.darkGreenButton}
                onClick={() => {
                  setStep(STEPS.getDisputeStatus);
                }}
              >
                Back
              </Button>
            </Stack>
          </>
        );
      case STEPS.messageBox:
        return (
          <>
            <Stack>
              <p style={{ fontSize: '12px', margin: '1' }}>
                Send a message to Integrar
              </p>
              <CssTextField
                name={'messageToIntegrar'}
                placeholder="Message"
                value={outputs['messageToIntegrar']}
                onChange={e => {
                  onChange(e);
                }}
                style={{
                  margin: '10px',
                }}
              />
              <Button
                variant="contained"
                style={{
                  color: 'white',
                  // height: '40px',
                  // width: '100px',
                  margin: '10px',
                }}
                sx={style.darkGreenButton}
                onClick={() => {
                  return props.submitFnc(props.index, outputs);
                }}
                disabled={!outputs?.messageToIntegrar?.length}
              >
                Send Message
              </Button>
              <Button
                variant="contained"
                style={{
                  color: 'white',
                  // height: '40px',
                  // width: '100px',
                  margin: '10px',
                }}
                sx={style.darkGreenButton}
                onClick={() => {
                  let newOutputs = { ...outputs };
                  delete newOutputs['messageToIntegrar'];
                  setOutputs(newOutputs);
                  setStep(STEPS.messageOrDocument);
                }}
              >
                Back
              </Button>
            </Stack>
          </>
        );
      case STEPS.uploadDocument:
        return (
          <>
            <p style={{ fontSize: '12px', margin: '1' }}>
              Send a file to Integrar
            </p>
            <Dialog open={openDialog}>
              <DialogContent>
                <DragDropFileDialog
                  documentSelected="OTH"
                  documentType="leaseholddispute"
                  handleClose={cancelled => {
                    setOpenDialog(false);
                    if (!cancelled) {
                      return props.submitFnc(props.index, outputs);
                    } else {
                      setStep(STEPS.messageOrDocument); 
                      setOpenDialog(true);
                    }
                  }}
                  setIsUpload={() => {}}
                  mortgage={props.mortgage}
                  setRecords={() => {}}
                  records={[]}
                  setExpanded={() => {}}
                />
              </DialogContent>
            </Dialog>
          </>
        );
    }
  };

  return (
    <Paper style={style.remortgage}>
      <div>
        <div style={{ display: 'flex' }}>
          <div key={props.index}>
            <p style={{ fontSize: '16px', margin: '5' }}>Leasehold dispute</p>
            <p style={{ fontSize: '12px', margin: '1' }}>
              Your current lender requires us to ask if you have any disbutes
              with your landlord or management company. Please can you confirm
              this for us ?
            </p>
          </div>
        </div>
        <div>{renderStep(step)}</div>
      </div>
    </Paper>
  );
};
