import { useEffect, useState } from "react";
import ReactGA from 'react-ga4';

// components
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
// data
import { style } from "../../../style/style";

export default function MakeAPayment(props) {
  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: '/payment' })
  }, []);
  return (
    <Paper style={style.remortgage}>
      <Grid container>
        <Grid item xs={12}>
          <Stack
            direction="column"
            justifyContent="flex-start"
            alignItems="flex-start"
            spacing={0}
            textAlign="left"
          >
            {props.mortgage.Mortgage && (
              <Typography style={style.bodyBoldTitle} sx={{ paddingTop: "0" }}>
                Make a payment{" "}
                {props.mortgage.Mortgage.RegMatterReference}
              </Typography>
            )}

            <Grid item md={8} lg={8} xl={8} sm={12} xs={12}>
              <Typography style={style.bodyBold} sx={{ paddingTop: "10px" }}>
                Integrar Bank Details are:
              </Typography>

              <Typography style={style.bodyText} sx={{ paddingTop: "18px" }}>
                <Box component="span" style={style.bodyBold}>
                  {" "}
                  Payee –{" "}
                </Box>
                Knights Shulmans LLP clients premium account
              </Typography>
              <Typography style={style.bodyText}>
                Barclays Bank UK Plc
              </Typography>
              <Typography style={style.bodyText}>PO BOX 100 LEEDS</Typography>
              <Typography style={style.bodyText} sx={{ paddingTop: "10px" }}>
                <Box component="span" style={style.bodyBold}>
                  {" "}
                  Sort Code{" "}
                </Box>
                20-48-46
              </Typography>
              <Typography style={style.bodyText}>
                <Box component="span" style={style.bodyBold}>
                  {" "}
                  Account No.{" "}
                </Box>
                10807044
              </Typography>

              <Typography
                variant="body2"
                style={style.bodyText}
                sx={{ paddingTop: "20px" }}
              >
                Please do ensure that you quote our reference number in order
                that we can allocate your payment to your file.
              </Typography>

              <Typography
                variant="body2"
                style={style.bodyText}
                sx={{ paddingTop: "10px" }}
              >
                Please note we cannot accept cash deposits over the counter,
                cash payments to our offices or payments from Third Parties.
              </Typography>
            </Grid>
          </Stack>
        </Grid>
      </Grid>
    </Paper>
  );
}
