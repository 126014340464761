import { LocalStorageService } from './LocalStorageService';
import { getAccessToken, WebServiceClient } from './WebServiceClient';

export const getMessages = async mortgageId => {
  const org = JSON.parse(LocalStorageService.get('organisation'));
  const config = {
    headers: { Authorization: getAccessToken() },
  };
  const params = {
    orgId: org.id,
    siteId: null,
  };
  let data = await WebServiceClient.get(
    `/message/${mortgageId}`,
    params,
    config,
  );
  return data.data;
};

export const postMessage = async (regMatterReference, subject, body) => {
  const org = JSON.parse(LocalStorageService.get('organisation'));
  const config = {
    headers: { Authorization: getAccessToken() },
  };
  const params = {
    orgId: org.id,
    siteId: null,
  };
  return await WebServiceClient.post(
    `/message`,
    {
      RegMatterReference: regMatterReference,
      MessageSubject: subject,
      MessageBody: body,
    },
    params,
    config,
  );
};
