import React, { useEffect } from "react";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";

import { style } from "../../style/style";

import { LocalStorageService } from "../../services/LocalStorageService";

// Make sure to bind modal to your appElement
export const TimeoutWarningModal = ({ isOpen, onRequestClose }) => {
  const onLogOffCall = () => {
    LocalStorageService.clear();
    // Implement your logout functionality here, e.g. clear the users login cache or hit your signout server
    window.location.href = "/landing";
  };

  useEffect(() => {
    setTimeout(() => {
      onLogOffCall();
    }, 60000);
  }, []);

  return (
    <div>
      <Dialog open={isOpen} style={style.timeoutStyle}>
        <DialogTitle>Session Timeout</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <Typography style={{ color: "black" }}>
              You're being timed out due to inactivity. Please choose to stay
              signed in or to logoff in your account. Otherwise, You will be
              automatically logged out in 60 seconds.
            </Typography>
            <br />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={onLogOffCall}>Log off</Button>
          <Button onClick={onRequestClose}>Stay Logged In</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
