// React
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

// Appt
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { Typography } from "@mui/material";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { LocalizationProvider } from "@mui/lab";
import { DatePicker, CalendarPicker } from "@mui/lab";

// style
import { styled } from "@mui/material/styles";
import { color } from "../../../../style/color";

const CssDatePicker = styled(DatePicker)({
  "& .MuiCalendarPicker-root": {
    // "&.$-MuiTypography-root":{},
    "& .Mui-selected": {
      backgroundcolor: color.brandDarkGreen,
    },
  },
  " & .MuiTouchRipple-root": {
    backgroundcolor: color.brandDarkGreen,
  },
});

function QuestionDate(props) {
  const [value, setValue] = React.useState(new Date());

  const handleChange = (newValue) => {
    setValue(newValue);
    props.handleOnChange(newValue, props.question);
  };

  useEffect(() => {
    props.handleOnChange(new Date(), props.question);
  }, []);

  return (
    <Box direction="row">
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <CalendarPicker
          disableFuture
          label={props.question.name}
          //views={["year", "month", "day"]}
          value={value}
          onChange={(newValue) => {
            handleChange(newValue);
          }}
          renderInput={(params) => <TextField {...params} />}
          sx={{
            backgroundcolor: color.brandDarkGreen,
            " & .MuiTouchRipple-root": {
              backgroundcolor: color.brandDarkGreen,
            },
          }}
        />
      </LocalizationProvider>
    </Box>
  );
}

export default QuestionDate;
