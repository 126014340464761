import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import React, { useState } from 'react';
import { style } from '../../../../../style/style';
import { CssTextField } from '../components/CssTextField';
import Paper from '@mui/material/Paper';
import { Formik } from 'formik';
import * as yup from 'yup';

const STEPS = Object.freeze({
  initial: 1,
});

export const MissingFreeholderDetails = props => {
  const [outputs, setOutputs] = useState({
    FullName: '',
    EmailAddress: '',
    AddressLine1: '',
    AddressLine2: '',
    TownCity: '',
    PostCode: '',
  });

  const schema = yup.object().shape({
    FullName: yup.string().required(`Full Name is required`),
    EmailAddress: yup.string().required(`Email Address is required`),
    AddressLine1: yup.string().required(`Address Line 1 is required`),
    AddressLine2: yup.string().required(`Address Line 2 is required`),
    TownCity: yup.string().required(`Town or City is required`),
    PostCode: yup.string().required(`Postcode is required`),
  });

  const [step, setStep] = useState(STEPS.initial);

  const renderStep = step => {
    switch (step) {
      case STEPS.initial:
        return (
          <Formik
            initialValues={{}}
            validationSchema={schema}
            onSubmit={values => {
              return props.submitFnc(props.index, outputs);
            }}
          >
            {({
              errors,
              handleBlur,
              touched,
              submitForm,
              setFieldValue,
              isValid,
              /* and other goodies */
            }) => (
              <Stack>
                <p style={{ fontSize: '12px', margin: '1', whiteSpace: 'pre-line' }}>
                  {props.milestone.bodyText}
                </p>
                <div style={{ padding: 5 }}>
                  <CssTextField
                    name={'FullName'}
                    placeholder={'Full Name'}
                    value={outputs.FullName}
                    onBlur={handleBlur}
                    error={touched.FullName && Boolean(errors.FullName)}
                    helperText={touched.FullName && errors.FullName}
                    onChange={e => {
                      e.preventDefault();
                      let updatedOutputs = {
                        ...outputs,
                      };
                      updatedOutputs[e.target.name] = e.target.value;
                      setOutputs(updatedOutputs);
                      setFieldValue(e.target.name, e.target.value);
                    }}
                  />
                </div>
                <div style={{ padding: 5 }}>
                  <CssTextField
                    name={'EmailAddress'}
                    placeholder={'Email Address'}
                    onBlur={handleBlur}
                    value={outputs.EmailAddress}
                    error={touched.EmailAddress && Boolean(errors.EmailAddress)}
                    helperText={touched.EmailAddress && errors.EmailAddress}
                    onChange={e => {
                      e.preventDefault();
                      let updatedOutputs = {
                        ...outputs,
                      };
                      updatedOutputs[e.target.name] = e.target.value;
                      setOutputs(updatedOutputs);
                      setFieldValue(e.target.name, e.target.value);
                    }}
                  />
                </div>
                <div style={{ padding: 5 }}>
                  <CssTextField
                    name={'AddressLine1'}
                    placeholder={'Address Line 1'}
                    onBlur={handleBlur}
                    value={outputs.AddressLine1}
                    error={touched.AddressLine1 && Boolean(errors.AddressLine1)}
                    helperText={touched.AddressLine1 && errors.AddressLine1}
                    onChange={e => {
                      e.preventDefault();
                      let updatedOutputs = {
                        ...outputs,
                      };
                      updatedOutputs[e.target.name] = e.target.value;
                      setOutputs(updatedOutputs);
                      setFieldValue(e.target.name, e.target.value);
                    }}
                  />
                </div>
                <div style={{ padding: 5 }}>
                  <CssTextField
                    name={'AddressLine2'}
                    placeholder={'Address Line 2'}
                    onBlur={handleBlur}
                    value={outputs.AddressLine2}
                    error={touched.AddressLine2 && Boolean(errors.AddressLine2)}
                    helperText={touched.AddressLine2 && errors.AddressLine2}
                    onChange={e => {
                      e.preventDefault();
                      let updatedOutputs = {
                        ...outputs,
                      };
                      updatedOutputs[e.target.name] = e.target.value;
                      setOutputs(updatedOutputs);
                      setFieldValue(e.target.name, e.target.value);
                    }}
                  />
                </div>
                <div style={{ padding: 5 }}>
                  <CssTextField
                    name={'TownCity'}
                    placeholder={'Town / City'}
                    onBlur={handleBlur}
                    value={outputs.TownCity}
                    error={touched.TownCity && Boolean(errors.TownCity)}
                    helperText={touched.TownCity && errors.TownCity}
                    onChange={e => {
                      e.preventDefault();
                      let updatedOutputs = {
                        ...outputs,
                      };
                      updatedOutputs[e.target.name] = e.target.value;
                      setOutputs(updatedOutputs);
                      setFieldValue(e.target.name, e.target.value);
                    }}
                  />
                </div>
                <div style={{ padding: 5 }}>
                  <CssTextField
                    name={'PostCode'}
                    placeholder={'Post Code'}
                    value={outputs.PostCode}
                    onBlur={handleBlur}
                    error={touched.PostCode && Boolean(errors.PostCode)}
                    helperText={touched.PostCode && errors.PostCode}
                    onChange={e => {
                      e.preventDefault();
                      let updatedOutputs = {
                        ...outputs,
                      };
                      updatedOutputs[e.target.name] = e.target.value;
                      setOutputs(updatedOutputs);
                      setFieldValue(e.target.name, e.target.value);
                    }}
                  />
                </div>
                <Button
                  variant="contained"
                  style={{
                    color: 'white',
                    margin: '10px',
                  }}
                  sx={style.darkGreenButton}
                  onClick={submitForm}
                  disabled={!isValid || !touched.FullName}
                >
                  Submit
                </Button>
              </Stack>
            )}
          </Formik>
        );
        break;
    }
  };

  return (
    <Paper style={style.remortgage}>
      <div>
        <p style={{ fontSize: '16px', margin: '5', bold: true }}>
          Missing Freeholder Details
        </p>
        <div style={{ display: 'flex' }}>
          <div key={props.index}>{renderStep(step)}</div>
        </div>
      </div>
    </Paper>
  );
};
