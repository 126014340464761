import { style } from "../style/style";
import { color } from "../style/color";

export const form = {
  shared: {
    path: "home >",
  },
  back_button_label: "Back",
  tabs: [
    {
      key: 1,
      title: "Remortgage Process",
      style: style.darkGreenSideBar,
      color: color.brandDarkGreen,
      hover: color.brandHoverDarkGreen,
    },
    {
      key: 3,
      title: "Your Documents",
      style: style.lightGreen,
      color: color.brandLightGreen,
      hover: color.brandHoverLightGreen,
    },
    {
      key: 4,
      title: "Your Information",
      style: style.lightBlue,
      color: color.brandLightBlue,
      hover: color.brandHoverLightBlue,
    },
    {
      key: 5,
      title: "Contact Us",
      style: style.darkBlue,
      color: color.brandDarkBlue,
      hover: color.brandHoverDarkBlue,
    },
    {
      key: 6,
      title: "How to Make a Payment",
      style: style.olive,
      color: color.brandOlive,
      hover: color.brandHoverOlive,
    },
  ],
  matter_dialog: {
    title_label: "Select a matter",
    description:
      "Your account is associated with multiples remortgages. Please select the remortgage you would like to log in",
    button_label: "Continue",
  },
  upload_dialog: {
    title_label: "Upload a new document",
    button_cancel_label: "Cancel",
    button_next_label: "Next",

    button_upload_label: "Submit",
  },
  your_information: {
    title_label: "Integrar Login",
    username_label: "Username",
    details_label: "My Details",
    name_label: "Name:",
    dob_label: "Date of Birth:",
    address_label: "Address:",
    change_label: "Change Password",
  },
  help_support: {
    title_label: "Contact Us",
    return_label:
      "Please use the following address for returning the signed Mortgage Deed to us:",
    phone_label: "Telephone: ",
    email_label: "Email: ",
    quote_label: "Please quote your reference number",
    send_label: "Send us an email",
    messaging_show_title: "Your Messages",
    messaging_title: "Secure Messaging",
    messaging_button: "Send Secure Message",
    messaging_description: "When messagng us please summarise your problem in the subject line. Please then put the full property details and your questions in the body of your message. Messages that have been responded to will show as replied. Messages that have not been repiled to yet will show as pending.",
    send_description:
      "When emailling us please only put your Integrar file reference and nothing else in the subject line. Please then put the full property details in the body of the your email.",
  },
};
