import Stack from '@mui/material/Stack';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import React, { useState } from 'react';
import { style } from '../../../../../style/style';
import DragDropFileDialog from '../../../../dialogs/DragDropFileDialog';
import { CssTextField } from '../components/CssTextField';

const STEPS = Object.freeze({
  initial: 1,
  sendMessage: 2,
});

export const PendingApplication = props => {
  const [outputs, setOutputs] = useState({});
  const [openDialog, setOpenDialog] = useState(false);
  const [step, setStep] = useState(STEPS.initial);

  const onChange = e => {
    const updatedQuestions = { ...outputs };
    updatedQuestions[e.target.name] = e.target.value;

    setOutputs(updatedQuestions);
  };

  const renderStep = step => {
    switch (step) {
      case STEPS.sendMessage:
        return (
          <>
            <Paper style={style.remortgage}>
              <Stack>
                <p style={{ fontSize: '12px', margin: '1' }}>Send a message:</p>
                <CssTextField
                  name={'messageToIntegrar'}
                  placeholder="Message"
                  value={outputs['messageToIntegrar']}
                  onChange={e => {
                    onChange(e);
                  }}
                />
                <Button
                  variant="contained"
                  style={{
                    color: 'white',
                    margin: '10px',
                  }}
                  sx={style.darkGreenButton}
                  onClick={() => {
                    props.submitFnc(props.index, outputs, false);
                    setStep(STEPS.initial);
                  }}
                  disabled={!outputs['messageToIntegrar']}
                >
                  {' '}
                  Submit{' '}
                </Button>
              </Stack>
            </Paper>
          </>
        );
      case STEPS.initial:
        return (
          <>
            <Stack>
              <p style={{ fontSize: '12px', margin: '1', whiteSpace: 'pre-line' }}>
                {props.milestone.bodyText}
              </p>
              <Button
                variant="contained"
                style={{
                  color: 'white',
                  margin: '10px',
                }}
                sx={style.darkGreenButton}
                onClick={() => {
                  setOpenDialog(true);
                }}
              >
                Upload Document
              </Button>
              <p style={{ fontSize: '12px', margin: '1' }}>
                Unfortunately, we are unable to progress to completion of your
                remortgage until this application has either been completed or
                removed.
              </p>
              <Dialog open={openDialog}>
                <DialogContent>
                  <DragDropFileDialog
                    documentSelected="UTR"
                    handleClose={cancelled => {
                      setOpenDialog(false);
                      if (!cancelled) {
                        return props.submitFnc(props.index, outputs, true);
                      }
                    }}
                    setIsUpload={() => {}}
                    mortgage={props.mortgage}
                    setRecords={() => {}}
                    records={[]}
                    setExpanded={() => {}}
                  />
                </DialogContent>
              </Dialog>
            </Stack>
          </>
        );
    }
  };

  return (
    <Paper style={style.remortgage}>
      <div>
        <p style={{ fontSize: '16px', margin: '5' }}>
          {' '}
          Pending application at land registry
        </p>
        <div style={{ display: 'flex' }}>
          <div key={props.index}>{renderStep(step)}</div>
        </div>
      </div>
    </Paper>
  );
};
