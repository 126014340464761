import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import { Formik } from 'formik';
import React, { useState, useEffect } from 'react';
import * as yup from 'yup';
import { style } from '../../../../../style/style';
import { CssTextField } from '../components/CssTextField';
import { set } from 'date-fns';
const STEPS = Object.freeze({
  initial: 1,
});

export const MissingMortgageAccountNumber = props => {

  const [lenderKey] = useState(['First', 'Second', 'Third', 'Fourth']);
  const [outputs, setOutputs] = useState({
    FirstLenderName: '',
    FirstLenderMortgageAccountNumber: '',
    FirstLenderVisible: false,
    FirstLenderEditable: false,
    SecondLenderName: '',
    SecondLenderMortgageAccountNumber: '',
    SecondLenderVisible: false,
    SecondLenderEditable: false,
    ThirdLenderName: '',
    ThirdLenderMortgageAccountNumber: '',
    ThirdLenderVisible: false,
    ThirdLenderEditable: false,
    FourthLenderName: '',
    FourthLenderMortgageAccountNumber: '',
    FourthLenderVisible: false,
    FourthLenderEditable: false
  });

  useEffect(() => {
      // add existing keys to the outputs, we hide them from the user and don't want them to be erased
      let outputToUpdate = {}
      for (const i in lenderKey) {
        let key = lenderKey[i]
        let lenderName = props.milestone.toPortal?.filter(i => i.dataTag === key + 'LenderName')?.[0]?.label
        outputToUpdate[key + 'LenderName'] = lenderName
      }
      setOutputs(prevState => ({ ...prevState, ...outputToUpdate }))
  }, [props.milestone.fromPortal]);

  const [step, setStep] = useState(STEPS.initial);
  const renderStep = step => {

    let newOutputs = Object.assign({}, outputs)

    var schemaShape = {}
    // loop through the number of lenders
    for (const i in lenderKey) {
      let key = lenderKey[i]
      
      // get the lender name
      let lenderName = props.milestone.toPortal?.filter(i => i.dataTag === key + 'LenderName')?.[0]?.label
      
      newOutputs[key + 'LenderName'] = lenderName
      newOutputs[key + 'LenderVisible'] = lenderName ? true : false
      newOutputs[key + 'LenderEditable'] = lenderName ? true : false

      if (newOutputs[key + 'LenderVisible'] && newOutputs[key + 'LenderEditable']) {
        schemaShape[key + 'LenderMortgageAccountNumber'] = yup.string()
          .required()
          .min(4)
          .max(255)
          .label("The account number for " + lenderName)
      }
    }

    const schema = yup.object().shape(schemaShape);


    switch (step) {
      case STEPS.initial:
        return (
          <Formik
            initialValues={{}}
            validationSchema={schema}
            onSubmit={values => {
            
              // tidy up the output before we submit
              if (!outputs.FirstLenderName || outputs.FirstLenderName.length <= 0) {
                delete outputs['FirstLenderName']
                delete outputs['FirstLenderMortgageAccountNumber']
              }

              if (!outputs.SecondLenderName || outputs.SecondLenderName.length <= 0) {
                delete outputs['SecondLenderName']
                delete outputs['SecondLenderMortgageAccountNumber']
              }

              if (!outputs.ThirdLenderName || outputs.ThirdLenderName.length <= 0) {
                delete outputs['ThirdLenderName']
                delete outputs['ThirdLenderMortgageAccountNumber']
              }

              if (!outputs.FourthLenderName || outputs.FourthLenderName.length <= 0) {
                delete outputs['FourthLenderName']
                delete outputs['FourthLenderMortgageAccountNumber']
              }

              delete outputs['FirstLenderEditable']
              delete outputs['FirstLenderVisible']
              delete outputs['SecondLenderEditable']
              delete outputs['SecondLenderVisible']
              delete outputs['ThirdLenderEditable']
              delete outputs['ThirdLenderVisible']
              delete outputs['FourthLenderEditable']
              delete outputs['FourthLenderVisible']

              return props.submitFnc(props.index, outputs, true, true);
            }}
          >
            {({
              errors,
              handleBlur,
              touched,
              submitForm,
              setFieldValue,
              isValid,
              validateForm
              /* and other goodies */
            }) => (
              <Stack>
                <p style={{ fontSize: '12px', margin: '1', whiteSpace: 'pre-line' }}>
                  {props.milestone.bodyText}
                </p>
                {lenderKey.map((key, index) => { return (<>
                  {newOutputs[key + 'LenderVisible'] && <div style={{ padding: 5 }}>
                    <label
                      style={{ display: 'flex', width: '100%' }}
                    >
                      {newOutputs[key + 'LenderName']}
                    </label>
                    <CssTextField
                      sx={{
                        width: '100%'
                      }}
                      name={key + 'LenderMortgageAccountNumber'}
                      placeholder={'Account Number'}
                      value={outputs[key + 'LenderMortgageAccountNumber'] || ''}
                      onBlur={handleBlur}
                      error={
                        touched[key + 'LenderMortgageAccountNumber'] &&
                        Boolean(errors[key + 'LenderMortgageAccountNumber'])
                      }
                      helperText={
                        touched[key + 'LenderMortgageAccountNumber'] &&
                        errors[key + 'LenderMortgageAccountNumber']
                      }
                      disabled={!newOutputs[key + 'LenderEditable']}
                      onChange={e => {
                        e.preventDefault();
                        let updatedOutputs = {
                          ...outputs,
                        };
                        updatedOutputs[e.target.name] = e.target.value;
                        setFieldValue(e.target.name, e.target.value);
                        setOutputs(updatedOutputs);
                        validateForm()
                      }}
                    />
                  </div>}
                </>)})}             
                <Button
                  variant="contained"
                  style={{
                    color: 'white',
                    margin: '10px',
                  }}
                  sx={style.darkGreenButton}
                  onClick={submitForm}
                  disabled={!isValid}
                >
                  Submit
                </Button>
              </Stack>
            )}
          </Formik>
        );
        break;
    }
  };

  return (
    <Paper style={style.remortgage}>
      <div>
        <p style={{ fontSize: '16px', margin: '5', bold: true }}>
          Missing Mortgage Account Number
        </p>
        <div style={{ display: 'flex' }}>
          <div key={props.index}>{renderStep(step)}</div>
        </div>
      </div>
    </Paper>
  );
};
