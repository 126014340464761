import * as Yup from "yup";
import { signup } from "../resources/signup-data";

export const login_schema = Yup.object().shape({
  email: Yup.string()
    .required(signup.errors.email.required)
    .email(signup.errors.email.invalid),
  password: Yup.string()
    .required(signup.errors.password.required)
    .min(5, signup.errors.password.invalid),
});

export const activate_schema = Yup.object().shape({
  email: Yup.string()
    .required(signup.errors.email.required)
    .email(signup.errors.email.invalid),
  password: Yup.string()
    .required(signup.errors.password.required)
    .min(5, signup.errors.password.invalid),
  reference: Yup.string().required(signup.errors.reference.required),
  postcode: Yup.string().required(signup.errors.postcode.required),
  terms: Yup.bool().oneOf([true], "Accept Terms & Conditions is required"),
});
export const password_schema = Yup.object().shape({
  password: Yup.string()
    .required(signup.errors.password.required)
    .min(5, signup.errors.password.invalid),
  terms: Yup.bool().oneOf([true], "Accept Terms & Conditions is required"),
});
export const create_schema = Yup.object().shape({
  email: Yup.string()
    .required(signup.errors.email.required)
    .email(signup.errors.email.invalid),
  reference: Yup.string().required(signup.errors.reference.required),
  postcode: Yup.string().required(signup.errors.postcode.required),
  terms: Yup.bool().oneOf([true], "Accept Terms & Conditions is required"),
});

export const reset_schema = Yup.object().shape({
  email: Yup.string()
    .required(signup.errors.email.required)
    .email(signup.errors.email.invalid),
  password: Yup.string()
    .required(signup.errors.password.required)
    .min(5, signup.errors.password.invalid),
  reference: Yup.string().required(signup.errors.reference.required),
  postcode: Yup.string().required(signup.errors.postcode.required),
});
export const email_reset_schema = Yup.object().shape({
  email: Yup.string()
    .required(signup.errors.email.required)
    .email(signup.errors.email.invalid),
});

export const password_reset_schema = Yup.object().shape({
  password: Yup.string()
    .required("Password is a required field")
    .min(5, signup.errors.password.invalid),
  passwordCheck: Yup.mixed().test(
    "match",
    "Passwords do not match",
    function () {
      return this.parent.password === this.parent.passwordCheck;
    }
  ),
});

export const reset = Yup.object().shape({
  password: Yup.string()
    .required("Password is a required field")
    .min(5, signup.errors.password.invalid),
  passwordCheck: Yup.mixed().test(
    "match",
    "Passwords do not match",
    function () {
      return this.parent.password === this.parent.passwordCheck;
    }
  ),
  terms: Yup.bool().oneOf([true], "Accept Terms & Conditions is required"),
});
