import React, { useEffect, useState } from "react";
import { TimeoutWarningModal } from "./TimeoutWarningModal";
import { LocalStorageService } from "../../services/LocalStorageService";

export const TimeoutLogicDialog = () => {

  const [position, setPosition] = useState({ x: 0, y: 0 });
  useEffect(() => {
    const setFromEvent = (e) => setPosition({ x: e.clientX, y: e.clientY });
    window.addEventListener("mousemove", setFromEvent);
    return () => {
      window.removeEventListener("mousemove", setFromEvent);
    };
  }, []);

  const [isWarningModalOpen, setWarningModalOpen] = useState(false);
  const [timerWarning, setTimerWarning] = useState();
  const [timerLogout, setTimerLogout] = useState();
  const [lastActiveTimer, setLastActiveTimer] = useState();

  const createlastActiveTimer = () =>
    // Every minute we will create a local storage entry with our time 
    // We do this so we can log you out after 30 minutes 
    setInterval(() => {
      LocalStorageService.set('lastActiveTimer', (new Date()).getTime() )
    }, 1000);

  const createTimeoutWarning = () =>
    // set time for log out dialog
    setTimeout(() => {
      setWarningModalOpen(true);
    }, 29 * 60 * 1000);

  const createTimeoutLogout = () =>
    setTimeout(() => {
      LocalStorageService.clear()
      window.location.href = "/landing";
    }, 30 * 60 * 1000);

  useEffect(() => {
    // if the mouse position has changed, clear the timeouts and try again
    if (timerWarning) { clearTimeout(timerWarning) }
    if (timerLogout) { clearTimeout(timerLogout) }
   
    // Check if we need to log out
    const lastLoggedIn = LocalStorageService.get('lastActiveTimer')
    if (lastLoggedIn && (new Date()).getTime() - lastLoggedIn >= 30 * 60 * 1000) {
      LocalStorageService.clear()
      window.location.href = "/landing"
    }

    if (!lastActiveTimer) { 
      setLastActiveTimer(createlastActiveTimer())
    }

    // create new timeouts
    setTimerWarning(createTimeoutWarning())
    setTimerLogout(createTimeoutLogout())
  }, [position]);
  
  return (
    <div>
      {isWarningModalOpen && (
        <TimeoutWarningModal
          isOpen={isWarningModalOpen}
          onRequestClose={() => setWarningModalOpen(false)}
        />
      )}
    </div>
  );
};
