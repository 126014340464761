export class LocalStorageService {
  static get(key) {
    try {
      var value = sessionStorage.getItem(key);

      // somewhere undefined is being stored as "undefined"
      // if that happens, make it actually undefined
      if (value === 'undefined') {
        value = undefined;
      }

      return value;
    } catch (err) {
      console.error(err);
      return null;
    }
  }

  static set(key, value) {
    try {
      if (typeof value === 'object') {
        value = JSON.stringify(value);
      }
      sessionStorage.setItem(key, value);
    } catch (err) {
      console.error(err);
      return null;
    }
  }

  static removeItem(key) {
    sessionStorage.removeItem(key);
  }

  static clear() {
    sessionStorage.clear();
  }
}
