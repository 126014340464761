import Stack from '@mui/material/Stack';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import React, { useState } from 'react';
import { style } from '../../../../../style/style';
import DragDropFileDialog from '../../../../dialogs/DragDropFileDialog';
import CssCheckbox from '../components/CssCheckbox';

const STEPS = Object.freeze({
  initial: 1,
  uploadCopy: 2,
});

export const NeedCopyLease = props => {
  const [outputs, setOutputs] = useState({
    hasLeaseCopy: false,
    getOneForMe: false,
  });
  const [openDialog, setOpenDialog] = useState(false);

  const [step, setStep] = useState(STEPS.initial);
  const onChangeCheckbox = e => {
    const updatedQuestions = { ...outputs };

    updatedQuestions['hasLeaseCopy'] = false;
    updatedQuestions['getOneForMe'] = false;

    updatedQuestions[e.target.name] = !!e.target.value;

    setOutputs(updatedQuestions);
  };

  const renderStep = step => {
    switch (step) {
      case STEPS.initial:
        return (
          <>
            <p style={{ fontSize: '12px', margin: '1', whiteSpace: 'pre-line' }}>
              {props.milestone.bodyText}
            </p>
            <p style={{ fontSize: '12px', margin: '1' }}>
              Please select one of the following options:
            </p>
            <CssCheckbox
              name="getOneForMe"
              label="Please obtain a copy of my lease for me. I confirm I have made the required payment."
              checked={outputs['getOneForMe']}
              onClick={e => {
                onChangeCheckbox(e);
              }}
            />
            <CssCheckbox
              name="hasLeaseCopy"
              label="I have a copy lease and will upload this."
              checked={outputs['hasLeaseCopy']}
              onClick={e => {
                onChangeCheckbox(e);
              }}
            />
            <Button
              variant="contained"
              style={{
                color: 'white',
                // height: '40px',
                // width: '100px',
                margin: '10px',
              }}
              sx={style.darkGreenButton}
              onClick={() => {
                props.submitFnc(props.index, outputs, false, false);
                // Validate
                // Return run on submit function from parent
                // Return
                if (outputs['getOneForMe']) {
                  return props.submitFnc(props.index, outputs);
                }

                return setStep(STEPS.uploadCopy);
              }}
            >
              Submit
            </Button>
          </>
        );
      case STEPS.uploadCopy:
        return (
          <>
            <p style={{ fontSize: '12px', margin: '1' }}>
              Your new lender needs us to check a copy of your current lease.
            </p>
            <p style={{ fontSize: '12px', margin: '1' }}>
              Please upload a copy of your lease.
            </p>
            <Button
              variant="contained"
              style={{
                color: 'white',
                // height: '40px',
                // width: '100px',
                margin: '10px',
              }}
              sx={style.darkGreenButton}
              onClick={() => {
                setOpenDialog(true);
              }}
            >
              Upload Document
            </Button>

            <Dialog open={openDialog}>
              <DialogContent>
                <DragDropFileDialog
                  documentSelected="LS"
                  handleClose={cancelled => {
                    setOpenDialog(false);
                    if (!cancelled) {
                      return props.submitFnc(props.index, outputs);
                    }
                  }}
                  setIsUpload={() => {}}
                  mortgage={props.mortgage}
                  setRecords={() => {}}
                  records={[]}
                  setExpanded={() => {}}
                />
              </DialogContent>
            </Dialog>
          </>
        );
    }
  };

  return (
    <Paper style={style.remortgage}>
      <div>
        <p style={{ fontSize: '16px', margin: '5', bold: true }}>
          Copy lease required
        </p>
        <div style={{ display: 'flex' }}>
          <div key={props.index}>{renderStep(step)}</div>
        </div>
      </div>
    </Paper>
  );
};
