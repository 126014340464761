// React
import React, { useState, useEffect } from "react";
// import PropTypes from 'prop-types'

// mui
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

import { questionType } from "../../../../enums/QuestionType";
import QuestionDate2 from "./QuestionDate2";
import QuestionTextField from "./QuestionTextField";
import QuestionSelect from "./QuestionSelect";

// style
import { styled } from "@mui/material/styles";
import { style } from "../../../../style/style";

const CssTextField = styled(TextField)({
  "& .MuiOutlinedInput-root": {
    paddingTop: "0px",
    marginBottom: "10px",
    "& input": {
      color: "#070606",
      padding: "5px",
      "&::placeholder": {
        color: "#070606",
        textAlign: "left",
        textTransform: "capitalize",
      },
    },
    "& fieldset": {
      borderColor: "#4B7AB7",
      backgroundColor: "transparent",
    },
    "&:hover fieldset": {
      borderColor: "#4B7AB7",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#4B7AB7",
    },
    "input.error ": {
      borderColor: "red",
    },
  },
});

function QuestionMulti(props) {
  const getType = (question) => {
    switch (question.questionType) {

      case questionType.PLAINTEXT:
        return (
          <Typography style={{ color: "black" }}>
            {question.questionText}
          </Typography>
        );
        
      case questionType.DATE:
        return (
          <QuestionDate2
            question={question}
            handleOnChange={props.handleOnChange}
            formValuesMulti={props.formValuesMulti}
            setFormValuesMulti={props.setFormValuesMulti}
            formValues={props.formValues}
          />
        );

      case questionType.TEXT:
        return (
          <QuestionTextField
            question={question}
            handleOnChange={props.handleOnChange}
            formValuesMulti={props.formValuesMulti}
            setFormValuesMulti={props.setFormValuesMulti}
            formValues={props.formValues}
          />
        );

      case questionType.SELECT:
        return (
          <QuestionSelect
            question={question}
            handleOnChange={props.handleOnChange}
            formValuesMulti={props.formValuesMulti}
            setFormValuesMulti={props.setFormValuesMulti}
            formValues={props.formValues}
          />
        );
      default:
    }
  };

  return (
    <>
      {props.question.actions.map((q, index) => {
        var question = getType(q);
        return (
          <div style={{ padding: "10px" }} key={index}>
            <Box direction="row">{question}</Box>
            {props.multiRequired.includes(q.name) && (
              <Typography style={style.questionErrorMulti}>
                {q.error}
              </Typography>
            )}
          </div>
        );
      })}
    </>
  );
}

export default QuestionMulti;
