import { React, useEffect } from 'react'

import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import { style } from "../../style/style";


function Cookies(props) {

  return (
    <Grid container spacing={2} sx={style.staticPage}>
      <Paper style={style.staticPage}>
        <Grid container>

          <Grid item xs={12}>
            <Stack
              direction="column"
              justifyContent="flex-start"
              alignItems="flex-start"
              textAlign="left"
              spacing={0}
            >

              <h1>Integrar - Portal Privacy Policy</h1>
              <h2>Welcome to the Portal Privacy Policy (“the Portal Policy”) for Integrar.</h2>

              <p><strong>Integrar respects your privacy and is committed to protecting your personal data. Our Portal Policy applies when you visit our portal as well as when we are in contact with you in other ways - this may be in your capacity as an individual or as director, shareholder, partner, employee or representative of a company or organisation.</strong></p>

              <p><strong>It sets out how we look after your personal data when you visit our portal your privacy rights; and how the law protects you.</strong></p>
              
              <h3>Important information and who we are</h3>

              <p><strong>Purpose of our Portal Policy</strong></p>

              <p>This Portal Policy provides you with information on how Integrar collects and processes your personal data. Our portal is not intended for children and we do not knowingly collect data relating to children.</p>
              <p>In order that you are fully aware of how and why we are using your data, it is important that you read this Portal Policy in conjunction with any other privacy policies/notices or fair processing notices we may provide on particular occasions when we are collecting or processing personal data about you. This Portal Policy supplements all of Integrars’ other notices/policies and is not intended to override them.</p>
              <p>As a controller for your data, data protection law requires Integrar to:</p>
              <ul>
                <li>process your data in a lawful, fair and transparent way</li>
                <li>collect your data only for explicit and legitimate purposes</li>
                <li>collect only data that is relevant, and limited to the purpose(s) we have told you about</li>
                <li>ensure that your data is accurate and up to date</li>
                <li>ensure that your data is only kept as long as necessary for the purpose(s) we have told you about</li>
                <li>ensure that appropriate security measures are used to protect your data</li>
              </ul>

              <p><strong>Controller</strong></p>
              
              <p>Knights Professional Services Limited (“Knights”). is the parent company of Integrar. Knights is the data controller who is responsible for your personal data.</p>
              <p>Although we are not obliged by law to appoint a Data Protection Officer, we have appointed a Data Privacy Manager (“DPM”) who is responsible for overseeing questions in relation to this Portal Policy.  If you have any questions about this Portal Policy, including any requests to exercise your legal rights, please contact the DPM using the details set out below.</p>
              
              <p>Our contact details</p>
              <p>Full name of legal entity: Knights Professional Services Limited.</p>
              <p>We are a company registered in England and Wales under Company Number 08453370 and our registered office is at The Brampton, Brampton Road, Newcastle-under-Lyme, Staffordshire ST5 0QW.</p>
              <p>Our main trading address is also The Brampton, Newcastle-under-Lyme, Staffordshire ST5 0QW. Our VAT registration number is 208 8271 04.</p>
              <p>We are authorised and regulated by the Solicitors Regulatory Authority.</p>
              
              
              <p>Email address: <a href='mailto:customerservicesmanager@integrar.services'>customerservicesmanager@integrar.services</a>.</p>
              
              <p>You have the right to make a complaint at any time to the Information Commissioner's Office (“ICO”), which is the UK supervisory authority for data protection issues (www.ico.org.uk). Knights is registered with the ICO, registration number ZA112722. We would, however, appreciate the chance to deal with your concerns before you approach the ICO so please contact us in the first instance.</p>
              
              <p><strong>Changes to the PortalPolicy and your duty to inform us of changes</strong></p>
              
              <p>We may need to update this Portal Policy from time to time. If the change is material, we will place a prominent notice on our Portal or update you by other appropriate means, contacting you at the details you have provided to us in your last correspondence.</p>
              
              <p>It is important that the personal data we hold about you is accurate and up to date. We may ask you to confirm updates to your personal data from time to time but please keep us informed if your personal data changes during your relationship with us in the meantime. Please inform the DPM of any changes in your data at the following email address: <a href='mailto:customerservicesmanager@integrar.services'>customerservicesmanager@integrar.services</a></p>
              
              <p><strong>Third-party links</strong></p>
              
              <p>Our portal may include links to third-party information, plug-ins and applications. Clicking on those links or enabling those connections may allow third parties to collect or share data about you. We do not control these third-party sites and we are not responsible for their privacy statements. When you leave our portal we encourage you to read the privacy notice of every website or portal you visit.</p>
              
              <h3>The data we collect about you</h3>

              <p>Personal data, or personal information, means any information about an individual from which that person can be identified. It does not include data where the person’s identity has been removed (anonymous data).</p>
              <p>We may collect, use, store and transfer different kinds of personal data about you which we have grouped together into different types below:</p>

              <ul>
                <li>Identity Data includes first name, maiden name, last name, username or similar identifier, marital status, title, date of birth, gender and photographs;</li>
                <li>Contact Data includes postal address, email address and telephone numbers;</li>
                <li>Professional Data includes academic qualifications and achievements, references, profession, job title, employer and professional history of these things;</li>
                <li>Welfare Data includes grievance and disciplinary information, information relating to criminal convictions and allegations of criminal activity, medical records and information including dietary requirements and religious information;</li>
                <li>Financial Data includes bank account, payment card details and pension details;</li>
                <li>Transaction Data includes details about payments to and from you and other details of services you have purchased from us;</li>
                <li>Technical Data includes internet protocol (IP) address, your login data, browser type and version, time zone setting and location, browser plug-in types and versions, operating system and platform and other technology on the devices you use to access this portal;</li>
                <li>Profile Data includes your username and password to any Integrar online service, your interests, preferences, feedback and survey responses;</li>
                <li>Usage Data includes information about how you use our portal and services;</li>
                <li>Marketing and Communications Data includes your preferences in receiving marketing from us and our third parties and your communication preferences; and</li>
                <li>Other personal data contained in correspondence and documents we receive in relation to a matter which you may be a party or otherwise concerned.</li>
              </ul>
              <p>We also collect, use and share Aggregated Data such as statistical or demographic data for any purpose. Aggregated Data may be derived from your personal data but is not considered personal data in law as it does not directly or indirectly reveal your identity. We may, for example, aggregate your Usage Data to calculate the percentage of users accessing a specific portal feature. However, if we combine or connect Aggregated Data with your personal data so that it can directly or indirectly identify you, we treat the whole of the combined data as personal data which will be used in accordance with this Portal Policy.</p>
              
              <p><strong>If you fail to provide personal data</strong></p>
              
              <p>Where we need to collect personal data by law, or under the terms of a contract we have with you and you fail to provide that data when requested, we may not be able to perform the contract we have, or are trying to enter into, with you (for example, to provide you with services). In this case, we may have to cancel a product or service you have with us but we will notify you if this is the case at the time.</p>
              
              <h3>How is your personal data collected?</h3>
              
              <p>We use different methods to collect data from and about you including through:</p>
              
              <p><strong>Direct interactions</strong></p>
              
              <p>You may give us your Identity, Contact and Financial Data by completing information on the Portal, filling in forms or by corresponding with us by post, phone, email or otherwise. This includes personal data you provide when you:</p>

              <ul>
                <li>enter into a contract with us;</li>
                <li>make a payment to us for events or services;</li>
                <li>subscribe to our services or publications;</li>
                <li>request marketing to be sent to you;</li>
                <li>attend a networking event;</li>
                <li>complete a survey; and</li>
                <li>provide us with feedback.</li>
              </ul>                

              <p><strong>Automated technologies or interactions</strong></p>
              
              <p>As you interact with our portal, we may automatically collect Technical Data about your equipment, browsing actions and patterns. We collect this personal data by using cookies, server logs and other similar technologies. We may also receive Technical Data about you if you visit other website or portals employing our cookies. Please see our <a href="/cookies">Cookie Policy</a> for further details.</p>
              
              <p><strong>Third parties or publicly available sources</strong></p>
              
              <p>We may receive personal data about you from various third parties and public sources as set out below:</p>
              
                <ul>
                  <li>Technical Data from analytics providers or search information providers, such as Google and Lead Forensics</li>
                </ul>
             
              <h3>How we use your personal data</h3>
              
              <p>We will only use your personal data when the law allows us to. Most commonly, we will use your personal data in the following circumstances:</p>
                
              <ul>
                <li>where we need to perform the contract we are about to enter into or have entered into with you or our clients;</li>
                <li>where it is necessary for our legitimate interests (or those of a third party) and your interests and fundamental rights do not override those interests;</li>
                <li>where we need to comply with a legal or regulatory obligation; and</li>
                <li>where this is necessary to protect your vital interests, or someone else’s vital interests this is typically in an emergency.</li>
              </ul>
              
              <p>Generally, we do not rely on consent as a legal basis for processing your personal data other than we may send third party direct marketing communications to you by email or text message. You have the right to withdraw consent to marketing at any time by contacting us at <a href='mailto:customerservicesmanager@integrar.services'>customerservicesmanager@integrar.services</a>.</p>
              
              <p><strong>Marketing</strong></p>
              
              <p>Integrar strives to provide you with choices regarding certain personal data uses, particularly around marketing and advertising. We have established the following personal data control mechanisms:</p>
                
              <ul>
                <li>we may use your Identity, Contact, Technical, Usage and Profile Data to form a view on what we think you may want or need, or what may be of interest to you;</li>
                <li>you will receive marketing communications from us if you have requested information from us, or purchased services from us and, in each case, you have not opted out of receiving that marketing;</li>
                <li>for email marketing to an individual subscriber (i.e. a non-corporate email address) with whom we have no pre-existing relationship, we will need your consent to send you unsolicited email marketing;</li>
                <li>we will get your express opt-in consent before we share your personal data with any company outside the Knights Group for marketing purposes; and</li>
                <li>where you provide consent, you can ask us - or third parties - to stop sending you marketing messages at any time by unsubscribing or updating your preferences below; following the opt-out links on any marketing message sent to you; or contacting our DPM (<a href='mailto:customerservicesmanager@integrar.services'>customerservicesmanager@integrar.services</a>) at any time. Please note that where you opt out of receiving these marketing messages, this will not apply to personal data provided to us as a result of a service purchase or other transactions.</li>
              </ul>
              
              
              <p><strong>Cookies</strong></p>
              
              <p>You can set your browser to refuse some, or all, browser cookies, or to alert you when websites or portals set or access cookies. If you disable or refuse cookies, please note that some parts of our portal may become inaccessible or not function properly. For more information about how to manage the use of cookies, please see our Cookie Policy.</p>
              
              <p><strong>Change of purpose</strong></p>
              
              <p>We will only use your personal data for the purposes for which we collected it, unless we reasonably consider that we need to use it for another reason and that reason is compatible with the original purpose. If you would like an explanation as to how the processing for the new purpose is compatible with the original purpose, please contact us at <a href='mailto:customerservicesmanager@integrar.services'>customerservicesmanager@integrar.services</a> If we need to use your personal data for an unrelated purpose, we will notify you and we will explain the legal basis which allows us to do so.</p>
              
              <p>Please note that we may process your personal data without your knowledge or consent, in compliance with the above rules, where this is required or permitted by law.</p>
              
              <h3>Disclosures of your personal data</h3>
              
              <p>We do not share or disclose any of your personal information without your consent, other than for the purposes specified in this Portal Policy; where there is a legal requirement; or it is necessary in order to protect the vital interests of a person. We may have to share your personal data with the parties set out below for the purposes set out in the table in paragraph 4 above.</p>
              
              <p><b>Internal Third Parties</b> as set out in the Glossary below</p>
              <p><b>External Third Parties</b> as set out in the Glossary below.</p>
              
              <p>Third parties to whom we choose to sell, transfer, or merge parts of our business or our assets. Alternatively, we may seek to acquire other businesses or merge with them. If a change happens to our business, then the new owners may use your personal data in the same way as set out in this privacy notice.</p>
              
              <p>We require all third parties to respect the security and confidentiality of your personal data and to treat it in accordance with the law. We do not allow our third-party service providers to use your personal data for their own purposes and only permit them to process your personal data for specified purposes and in accordance with our instructions.</p>
              
              <h3>International transfers</h3>
              
              <p>We do not transfer your personal data outside the European Economic Area (EEA) without your consent. Please contact us at <a href='mailto:customerservicesmanager@integrar.services'>customerservicesmanager@integrar.services</a> if you want further information on the specific mechanism used by us when transferring your personal data out of the EEA.</p>
              
              <h3>Data security</h3>
              
              <p>We have appropriate security measures in place to prevent your personal data from being accidentally lost, used or accessed in an unauthorised way, altered or disclosed. In addition, we limit access to your personal data to those employees, agents, contractors and other third parties who have a business need to know. They will only process your personal data on our instructions and they are subject to a duty of confidentiality.</p>
              
              <p>Integrar cannot guarantee the security of information transmitted over the internet or that unauthorised persons will not obtain access to personal data. We have put in place procedures to deal with any suspected personal data breach and will notify you and any applicable regulator of a breach.</p>
              
              <h3>Data retention</h3>
              
              <p>We will only retain your personal data for as long as is necessary to fulfil the purpose(s) for which we collected and processed it, including for the purposes of satisfying any legal, accounting, or reporting requirements.</p>
              <p>In determining the appropriate retention period for personal data, we consider the amount, nature, and sensitivity of the personal data, the potential risk of harm from unauthorised use or disclosure of your personal data, the purposes for which we process your personal data and whether we can achieve those purposes through other means, and the applicable legal requirements.</p>
              <p>Details of retention periods for different aspects of your personal data are available in our retention policy a copy of which you can request from us by contacting us at <a href='mailto:customerservicesmanager@integrar.services'>customerservicesmanager@integrar.services</a>, Due to legal and regulatory requirements, we may need to retain personal data for up to seven years after we cease providing services and products to you, where necessary, to comply with our legal obligations, resolve disputes or enforce our terms and conditions.</p>
              <p>In some circumstances, you can ask us to delete your data: see request erasure of your personal data below for further information.</p>
              <p>In some circumstances, we may anonymise your personal data (so that it can no longer be associated with you) for research or statistical purposes in which case we may use this information indefinitely without further notice to you.</p>
              
              <h3>Your legal rights</h3>
              
              <p>Under certain circumstances, you have rights under data protection laws in relation to your personal data. You have the right to:</p>
              
              <p><strong>Request access</strong> to your personal data (commonly known as a "data subject access request"). This enables you to receive a copy of the personal data we hold about you and to check that we are processing it lawfully.</p>
              <p><strong>Request correction</strong> of the personal data that we hold about you. This enables you to have any incomplete or inaccurate data we hold about you corrected, though we may need to verify the accuracy of the new data you provide to us.</p>
              <p><strong>Request erasure</strong> of your personal data. You may ask us to delete or remove personal data where there is no good reason for us continuing to process it. You also have the right to ask us to delete or remove your personal data where you have successfully exercised your right to object to processing (see below), where we may have processed your information unlawfully or where we are required to erase your personal data to comply with local law. There may be specific legal reasons why we cannot comply with your request of erasure. If there are, we will notify you at the time of your request.</p>
              <p><strong>Object to processing</strong> of your personal data where we are relying on a legitimate interest (or those of a third party) and there is something about your particular situation which makes you want to object to processing on this ground as you feel it impacts on your fundamental rights and freedoms. You also have the right to object where we are processing your personal data for direct marketing purposes. In some cases, we may demonstrate that we have compelling legitimate grounds to process your information which override your rights and freedoms.</p>
              <p><strong>Request restriction</strong>  of processing of your personal data. This enables you to ask us to suspend the processing of your personal data in the following scenarios: (a) if you want us to establish the accuracy of the data; (b) where our use of the data is unlawful but you do not want us to erase it; (c) where you need us to hold the data even if we no longer require it as you need it to establish, exercise or defend legal claims; or (d) you have objected to our use of your data but we need to verify whether we have overriding legitimate grounds to use it.</p>
              <p><strong>Request the transfer</strong>  of your personal data to you or to a third party. We will provide to you, or a third party you have chosen, your personal data in a structured, commonly used, machine-readable format. This right only applies to automated information for which you initially provided consent to us to use or where we used the information to perform a contract with you.</p>
              <p><strong>Withdraw consent at any time</strong>  where we are relying on consent to process your personal data. However, this will not affect the lawfulness of any processing carried out before you withdraw your consent. If you withdraw your consent, we may not be able to provide certain products or services to you. We will advise you if this is the case at the time you withdraw your consent.</p>
              
              <p>If you wish to exercise any of the rights set out above, please contact us at <a href='mailto:customerservicesmanager@integrar.services'>customerservicesmanager@integrar.services</a></p>
              
              <p><strong>No fee usually required</strong></p>
              
              <p>You will not have to pay a fee to access your personal data (or to exercise any of the other rights). However, we may charge a reasonable fee if your request is clearly unfounded, repetitive or excessive. Alternatively, we may refuse to comply with your request in these circumstances.</p>
              
              <p><strong>What we may require from you</strong></p>
              <p>We may need to request specific information from you to help us confirm your identity and ensure your right to access your personal data (or to exercise any of your other rights). This is a security measure to ensure that personal data is not disclosed to any person who has no right to receive it. We may also contact you to ask you for further information about your request so that we may speed up our response.</p>
              
              <p><strong>Time limit to respond</strong></p>
              <p>We try to respond to all legitimate requests within one month. Occasionally, it may take us longer than a month if your request is particularly complex or you have made a number of requests. In this case, we will notify you and keep you updated.</p>
              
              <p><strong>Glossary</strong></p>
              
              <p><strong>Legitimate Interest</strong> means the interest of our business in conducting and managing our business to enable us to give you the best service/product and the best and most secure experience. We make sure that we consider and balance any potential impact on you (both positive and negative) and your rights before we process your personal data for our legitimate interests. We do not use your personal data for activities where our interests are overridden by the impact on you (unless we have your consent or are otherwise required or permitted to by law). You can obtain further information about how we assess our legitimate interests against any potential impact on you in respect of specific activities by contacting us at <a href='mailto:customerservicesmanager@integrar.services'>customerservicesmanager@integrar.services</a>.</p>
              <p><strong>Performance of Contract</strong> means processing your data where it is necessary for the performance of a contract to which you are a party or to take steps at your request before entering into such a contract.</p>
              <p><strong>Comply with a legal or regulatory obligation</strong> means processing your personal data where it is necessary for compliance with a legal or regulatory obligation that we are subject to.</p>
              
              <p>The Knights Group means Knights Group Holdings plc which includes Knights Professional Services Limited and any of its current direct or indirect subsidiaries and/or controlled affiliates as well as any successors to Knights Professional Services Limited and any of its direct or indirect subsidiaries and/or controlled affiliates.</p>

            </Stack>
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  )
}

export default Cookies
