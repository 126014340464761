import Stack from '@mui/material/Stack';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import React, { useState } from 'react';
import { style } from '../../../../../style/style';
import {
  getAccessToken,
  WebServiceClient,
} from '../../../../../services/WebServiceClient';
import { downloadFile } from '../../../../../utils/DownloadFile';
import DragDropFileDialog from '../../../../dialogs/DragDropFileDialog';
import { LocalStorageService } from '../../../../../services/LocalStorageService';

const STEPS = Object.freeze({
  initial: 1,
  upload: 2,
});

export const SignedAuthorityRequired = props => {
  const [outputs, setOutputs] = useState({});
  const [openDialog, setOpenDialog] = useState(false);
  const [docID, setDocID] = useState('');
  const [step, setStep] = useState(STEPS.initial);
  const org = JSON.parse(LocalStorageService.get('organisation'));

  const renderStep = step => {
    switch (step) {
      case STEPS.initial:
        return (
          <Stack container>
            <p style={{ fontSize: '12px', marginTop: '1px', whiteSpace: 'pre-line' }}>
              {props.milestone.bodyText}
            </p>
            <Button
              variant="contained"
              style={{
                color: 'white',
                // height: '40px',
                // width: '100px',
                margin: '10px',
              }}
              sx={style.darkGreenButton}
              onClick={async () => {
                const config = {
                  headers: { Authorization: getAccessToken() },
                };
                const params = {
                  orgId: org.id,
                  siteId: null,
                  documentName: props.milestone.toPortal.find(
                    x => x.dataTag === 'DownloadButton',
                  ).file,
                };
                let ret = await WebServiceClient.get(
                  `/supporting-document/${props.mortgage.Mortgage.id}`,
                  params,
                  config,
                );

                if (ret.status != 200) {
                  /// error
                } else {
                  setDocID(ret.data.id);
                  downloadFile(ret.data.fileUrl, props.milestone.file);
                  setStep(STEPS.upload);
                }
              }}
            >
              Download Form
            </Button>
          </Stack>
        );
      case STEPS.upload:
        return (
          <Stack container>
            <p style={{ fontSize: '12px', marginTop: '1px' }}>
              Upload document
            </p>
            <Button
              variant="contained"
              style={{
                color: 'white',
                // height: '40px',
                // width: '100px',
                margin: '10px',
              }}
              sx={style.darkGreenButton}
              onClick={() => {
                setOpenDialog(true);
              }}
            >
              Upload Document
            </Button>
            <Dialog open={openDialog}>
              <DialogContent>
                <DragDropFileDialog
                  documentSelected={'SRSA'}
                  handleClose={cancelled => {
                    setOpenDialog(false);
                    if (!cancelled) {
                      return props.submitFnc(props.index, outputs);
                    }
                  }}
                  mortgage={props.mortgage}
                  setExpanded={() => {}}
                />
              </DialogContent>
            </Dialog>
          </Stack>
        );
    }
  };

  return (
    <div>
      <Paper style={style.remortgage}>
        <p style={{ fontSize: '16px', marginTop: '10px' }}>
          Signed redemption statement authority required
        </p>
        <div style={{ display: 'flex' }}>
          <div key={props.index}>{renderStep(step)}</div>
        </div>
      </Paper>
    </div>
  );
};
