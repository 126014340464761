import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import { Formik } from 'formik';
import React, { useState } from 'react';
import * as yup from 'yup';
import { style } from '../../../../../style/style';
import { CssTextField } from '../components/CssTextField';
const STEPS = Object.freeze({
  initial: 1,
});

export const MissingBankDetails = props => {
  const schema = yup.object().shape({
    BankName: yup.string().required(),
    AccountHolderFullName: yup.string().required(),
    BankAccountNumber: yup
      .string()
      .required()
      .min(8)
      .matches(/^[0-9]+$/, 'Must be only digits'),
    BankSortCode: yup
      .string()
      .required()
      .min(6)
      .max(6)
      .matches(/^[0-9]+$/, 'Must be only digits'),
  });

  const [outputs, setOutputs] = useState({
    BankName: '',
    AccountHolderFullName: '',
    BankAccountNumber: '',
    BankSortCode: '',
  });
  const [step, setStep] = useState(STEPS.initial);

  const renderStep = step => {
    switch (step) {
      case STEPS.initial:
        return (
          <Formik
            initialValues={{}}
            validationSchema={schema}
            onSubmit={values => {
              return props.submitFnc(props.index, outputs);
            }}
          >
            {({
              errors,
              handleBlur,
              touched,
              submitForm,
              setFieldValue,
              isValid,
              /* and other goodies */
            }) => (
              <Stack>
                <p style={{ fontSize: '12px', margin: '1', whiteSpace: 'pre-line' }}>
                  {props.milestone.bodyText}
                </p>
                <p style={{ fontSize: '12px', margin: '10' }}>
                  Please complete the details in the relevant section.
                </p>
                <div style={{ padding: 5 }}>
                  <CssTextField
                    name={'BankName'}
                    placeholder={'Bank Name'}
                    value={outputs.BankName}
                    onBlur={handleBlur}
                    error={touched.BankName && Boolean(errors.BankName)}
                    helperText={touched.BankName && errors.BankName}
                    onChange={e => {
                      e.preventDefault();
                      let updatedOutputs = {
                        ...outputs,
                      };
                      updatedOutputs[e.target.name] = e.target.value;
                      setFieldValue(e.target.name, e.target.value);
                      setOutputs(updatedOutputs);
                    }}
                  />
                </div>
                <div style={{ padding: 5 }}>
                  <CssTextField
                    name={'AccountHolderFullName'}
                    placeholder={'Account Holder Full Name'}
                    value={outputs.AccountHolderFullName}
                    onBlur={handleBlur}
                    error={
                      touched.AccountHolderFullName &&
                      Boolean(errors.AccountHolderFullName)
                    }
                    helperText={
                      touched.AccountHolderFullName &&
                      errors.AccountHolderFullName
                    }
                    onChange={e => {
                      e.preventDefault();
                      let updatedOutputs = {
                        ...outputs,
                      };
                      updatedOutputs[e.target.name] = e.target.value;
                      setFieldValue(e.target.name, e.target.value);
                      setOutputs(updatedOutputs);
                    }}
                  />
                </div>
                <div style={{ padding: 5 }}>
                  <CssTextField
                    name={'BankAccountNumber'}
                    placeholder={'Bank Account Number'}
                    value={outputs.BankAccountNumber}
                    onBlur={handleBlur}
                    error={
                      touched.BankAccountNumber &&
                      Boolean(errors.BankAccountNumber)
                    }
                    helperText={
                      touched.BankAccountNumber && errors.BankAccountNumber
                    }
                    onChange={e => {
                      e.preventDefault();
                      let updatedOutputs = {
                        ...outputs,
                      };
                      updatedOutputs[e.target.name] = e.target.value;
                      setFieldValue(e.target.name, e.target.value);
                      setOutputs(updatedOutputs);
                    }}
                  />
                </div>
                <div style={{ padding: 5 }}>
                  <CssTextField
                    name={'BankSortCode'}
                    placeholder={'Bank Sort Code'}
                    value={outputs.BankSortCode}
                    onBlur={handleBlur}
                    error={touched.BankSortCode && Boolean(errors.BankSortCode)}
                    helperText={touched.BankSortCode && errors.BankSortCode}
                    onChange={e => {
                      e.preventDefault();
                      let updatedOutputs = {
                        ...outputs,
                      };
                      updatedOutputs[e.target.name] = e.target.value;
                      setFieldValue(e.target.name, e.target.value);
                      setOutputs(updatedOutputs);
                    }}
                  />
                </div>
                <Button
                  variant="contained"
                  style={{
                    color: 'white',
                    // height: '40px',
                    // width: '100px',
                    margin: '10px',
                  }}
                  sx={style.darkGreenButton}
                  onClick={submitForm}
                  disabled={!isValid || !touched.BankAccountNumber}
                >
                  Submit
                </Button>
              </Stack>
            )}
          </Formik>
        );
        break;
    }
  };

  return (
    <Paper style={style.remortgage}>
      <div>
        <p style={{ fontSize: '16px', margin: '5', bold: true }}>
          Missing Bank Details
        </p>
        <div style={{ display: 'flex' }}>
          <div key={props.index}>{renderStep(step)}</div>
        </div>
      </div>
    </Paper>
  );
};
