import Button from '@mui/material/Button';
import React, { useState } from 'react';
import { style } from '../../../../../style/style';
import CssCheckbox from '../components/CssCheckbox';
import Paper from '@mui/material/Paper';

const STEPS = Object.freeze({
  initial: 1,
});

export const MortgageOfferExpire = props => {
  const [step, setStep] = useState(STEPS.initial);
  const [outputs, setOutputs] = useState({
    stop: false,
    continue: false,
  });

  const onChangeCheckbox = e => {
    const updatedQuestions = { ...outputs };
    updatedQuestions['stop'] = false;
    updatedQuestions['continue'] = false;
    updatedQuestions[e.target.name] = !!e.target.value;
    setOutputs(updatedQuestions);
  };

  const renderStep = step => {
    switch (step) {
      case STEPS.initial:
        return (
          <>
            <p style={{ fontSize: '12px', margin: '0' }}>
              Please note that your mortgage offer is due to expire on [date].
              If completion does not take place by this date you will need to
              obtain an extension to the original offer or make a further
              application via the same methods which you previously applied.
            </p>
            <p style={{ fontSize: '12px', margin: '0' }}>
              If you are no longer proceeding with this transaction, you will
              need to let us know and we will close our file.
            </p>
            <p style={{ fontSize: '12px', margin: '0' }}>
              Please select an option below:
            </p>

            <CssCheckbox
              name="continue"
              label="I acknowledge that if completion does not take place by the expiry date, I will need to obtain an extension to the original offer or make a
further application."
              checked={outputs['continue']}
              onClick={e => {
                onChangeCheckbox(e);
              }}
            />
            <CssCheckbox
              name="stop"
              label="I no longer wish to proceed with this transaction."
              checked={outputs['stop']}
              onClick={e => {
                onChangeCheckbox(e);
              }}
            />

            <Button
              variant="contained"
              style={{
                color: 'white',
                // height: '40px',
                // width: '100px',
                margin: '10px',
              }}
              sx={style.darkGreenButton}
              onClick={() => {
                return props.submitFnc(props.index, outputs);
              }}
              disabled={!outputs['continue'] && !outputs['stop']}
            >
              Submit
            </Button>
          </>
        );
    }
  };

  return (
    <Paper style={style.remortgage}>
      <div>
        <p style={{ fontSize: '16px', margin: '5' }}>
          Mortgage offer expires soon
        </p>
        <div style={{ display: 'flex' }}>
          <div key={props.index}>{renderStep(step)}</div>
        </div>
      </div>
    </Paper>
  );
};
