import Stack from '@mui/material/Stack';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import React, { useState } from 'react';
import { style } from '../../../../../style/style';
import {
  getAccessToken,
  WebServiceClient,
} from '../../../../../services/WebServiceClient';
import { downloadFile } from '../../../../../utils/DownloadFile';
import { LocalStorageService } from '../../../../../services/LocalStorageService';

const STEPS = Object.freeze({
  initial: 1,
});

export const RedStatReceived = props => {
  const [step, setStep] = useState(STEPS.initial);
  const [docID, setDocID] = useState('');
  const org = JSON.parse(LocalStorageService.get('organisation'));
  const renderStep = step => {
    switch (step) {
      case STEPS.initial:
        return (
          <>
            <p style={{ fontSize: '12px', margin: '1' }}>
              We've received your redemption statement from your current lender
              and have uploaded a copy for you in the "Your Documents" section
              of the Portal. Please open this to see the figures. If you don't
              agree or understand the figures and wish to query this in any way
              then please:
            </p>
            <ol type="1" style={{ fontSize: '12px', margin: '1' }}>
              <li>
                Do this as quickly as possible with your current lender. We
                can't unfortunately answer any questions relating to their
                figures.
              </li>
              <li>
                Inform us immediately if you do not wish us to proceed to
                completion using these figures.
              </li>
            </ol>
            <Button
              variant="contained"
              style={{
                color: 'white',
                margin: '10px',
              }}
              sx={style.darkGreenButton}
              onClick={async () => {
                const config = {
                  headers: { Authorization: getAccessToken() },
                };
                const params = {
                  orgId: org.id,
                  siteId: null,
                  documentName: props.milestone.toPortal.find(
                    x => x.dataTag === 'DownloadButton',
                  ).file,
                };
                let ret = await WebServiceClient.get(
                  `/supporting-document/${props.mortgage.Mortgage.id}`,
                  params,
                  config,
                );

                if (ret.status != 200) {
                  /// error
                } else {
                  setDocID(ret.data.id);
                  downloadFile(ret.data.fileUrl, props.milestone.file);
                }
              }}
            >
              Download Document
            </Button>
            <p style={{ fontSize: '12px', margin: '1' }}>
              Don't worry there's no need to contact us if you agree with the
              figures in the redemption statement.
            </p>
          </>
        );
    }
  };

  return (
    <Paper style={style.remortgage}>
      <div>
        <p style={{ fontSize: '16px', margin: '5' }}>
          Redemption Statement Received
        </p>
        <div style={{ display: 'flex' }}>
          <div key={props.index}>{renderStep(step)}</div>
        </div>
      </div>
    </Paper>
  );
};
