// React
import React from "react";

// Appt
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
// style
import { Typography } from "@mui/material";
import { MdRadioButtonChecked, MdRadioButtonUnchecked } from "react-icons/md";

function CssCheckbox(props) {
  const { checked } = props;
  return (
    <div style={{ textAlign: 'left' }}>
      <FormControlLabel
        control={
          <Checkbox
            checkedIcon={<MdRadioButtonChecked size={20} />}
            icon={<MdRadioButtonUnchecked size={20} />}
            sx={{
              '& .MuiSvgIcon-root': { fontSize: 20 },
              color: '#000000',
              '&.Mui-checked': {
                color: '#000000',
              }
            }}
            value={checked}
            checked={checked}
            {...props}
          />
        }
        label={<Typography   style={{ fontSize: '12px', textAlign: 'left'}} variant="">{props.label}</Typography>}
      />
    </div>
  );
}

export default CssCheckbox;
