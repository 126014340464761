// React
import React, { useState, useEffect } from "react";
// import PropTypes from 'prop-types'

// Appt
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";

// style
import { styled } from "@mui/material/styles";

const CssTextField = styled(TextField)({
  "& .MuiOutlinedInput-root": {
    paddingTop: "0px",
    "& input": {
      color: "#070606",
      fontWeight: 600,
      padding: "5px",
      "&::placeholder": {
        color: "#070606",
        textAlign: "center",
      },
    },
    "& fieldset": {
      border: "2px solid #418ED1",
      borderRadius: "6px",
      boxShadow: "0 0 2px 1px #418ED1",
      //backgroundColor: "transparent",
    },
    "&:hover fieldset": {
      border: "2px solid #418ED1",
    },
    "&.Mui-focused fieldset": {
      //border: "2px solid #418ED1",
    },
    "input.error ": {
      borderColor: "red",
    },
  },
});

function QuestionTextField(props) {
  const [isNumber, setIsNumber] = useState(true);
  const [currentError, setCurrentError] = useState(null);
  const [errorEmail, setErrorEmail] = useState(false);
  const [errorPhone, setErrorPhone] = useState(false);
  const isValidEmail = (email) => {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  };

  useEffect(() => {
    // reset errors if the property type changes
    setErrorEmail(false)
    setErrorPhone(false)
    setCurrentError(null)
  }, [props.question.type])

  const isValidPhone = (phone) => {
    return /^(((\+44\s?\d{4}|\(?0\d{4}\)?)\s?\d{3}\s?\d{3})|((\+44\s?\d{3}|\(?0\d{3}\)?)\s?\d{3}\s?\d{4})|((\+441\s?\d{3}|\(?01\d{3}\)?)\s?\d{5})|((\+44\s?\d{2}|\(?0\d{2}\)?)\s?\d{4}\s?\d{4}))(\s?\#(\d{4}|\d{3}))?$/
    .test(
      phone
    );
  };

  const handleChange = (value) => {
    var input = value;
    if (props.question.isNumber) {
      if (isNaN(value)) {
        setIsNumber(false);
      } else {
        setIsNumber(true);
        props.handleOnChange(value, props.question);
      }
    } else if (props.question.isCurrency) {
      if (isNaN(value.replace(/\,/g, ""))) {
        setIsNumber(false);
      } else {
        setIsNumber(true);
        input = value.replace(/\,/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        props.handleOnChange(value.replace(/\,/g, ""), props.question);
      }
    } else if (props.question.type === "email") {
      input = input.trim()
      value = value.trim()
      if (!isValidEmail(value)) {
        setErrorEmail(true);
        setCurrentError("This is not a valid email");
      } else {
        setErrorEmail(false);
        setCurrentError();
      }
      props.handleOnChange(value, props.question);
    } else if (props.question.type === "phone") {
      if (!isValidPhone(value)) {
        setErrorPhone(true);
        setCurrentError("This is not a valid number");
      } else {
        setErrorPhone(false);
        setCurrentError();
      }
      props.handleOnChange(value, props.question);
    } else {
      props.handleOnChange(value, props.question);
    }
    props.setFormValuesMulti((prevState) => ({
      ...prevState,
      [props.question.name]: input,
    }));
    getValue();
  };
  const getValue = () => {
    if (
      props.formValues !== undefined &&
      props.formValues[props.question.name] !== null &&
      props.formValues[props.question.name] !== undefined &&
      props.formValues[props.question.name] !== ""
    ) {
      return props.formValues[props.question.name]
    }
    return "";
  };

  return (
    <Box direction="row">
      <CssTextField
        error={errorEmail || errorPhone}
        helperText={currentError ?? currentError}
        placeholder={props.question.questionText}
        value={getValue()}
        multiline={props.question.rows}
        rows={props.question.rows ? props.question.rows : 1}
        name={props.question.name}
        type="text"
        onChange={(e) => handleChange(e.target.value)}
        style={props?.style || { width: "300px" }}
        inputProps={{
          maxLength: props.question.maxLength ? props.question.maxLength : 255,
        }}
        InputProps={{
          startAdornment: props.question.adornments ? (
            <InputAdornment position="start">
              <span style={{ color: "black", fontWeight: "bold" }}>
                {props.question.adornments}
              </span>
            </InputAdornment>
          ) : null,
        }}
      />{" "}
      {props.question.adornments || props.question.isNumber
        ? !isNumber && <p style={{ color: "red" }}>Please type a number</p>
        : ""}
    </Box>
  );
}

export default QuestionTextField;
