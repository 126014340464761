export const color = {
  gray: "#808080",
  lightGray: "#827C74",
  red: "#FF0000",
  olive: "#50bba2",
  smoke: "#F5F5F5",
  black: "rgba(0, 0, 0, 0.87)",
  brandDarkGreen: "#65ba98",
  brandHoverDarkGreen: "#62A687",
  brandOlive: "#b6c22f",
  brandHoverOlive: "#b6b321",
  brandLightGreen: "#90cbb5",
  brandHoverLightGreen: "#90b8A5",
  brandLightBlue: "#97c9d6",
  brandHoverLightBlue: "#97b9c8",
  brandDarkBlue: "#408ed0",
  brandHoverDarkBlue: "#407fc6",
  pastelGreen: "#DFF0DD",
  hoverGreen: "#65B67B",
  navyBlue: "#4B7AB7",
  white: "#FFFFFF",
  hoverGrey: "#8D7E7F",
  errorYellow: "#F8C600",
};
