import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import { Formik } from 'formik';
import moment from 'moment';
import React, { useState } from 'react';
import * as yup from 'yup';
import { style } from '../../../../../style/style';
import DateSelect from '../components/DateSelect';

import dayjs from 'dayjs';
var utc = require("dayjs/plugin/utc");
dayjs.extend(utc);

const STEPS = Object.freeze({
  initial: 1,
});

export const MissingBINSExpiryDate = props => {
  const schema = yup.object().shape({
    BINSExpiryDate: yup
      .date()
      .min(moment().toDate(), 'Date cannot be in the past')
      .max(
        moment().add(1, 'year').toDate(),
        'Date cannot be more than 1 year in the future',
      ),
  });

  const [outputs, setOutputs] = useState({
    Accepted: true,
    BINSExpiryDate: null,
  });
  const [step, setStep] = useState(STEPS.initial);

  const renderStep = step => {
    switch (step) {
      case STEPS.initial:
        return (
          <Formik
            initialValues={{
              checked: false,
            }}
            validationSchema={schema}
            onSubmit={values => {
              return props.submitFnc(props.index, outputs);
            }}
          >
            {({
              errors,
              handleBlur,
              touched,
              submitForm,
              setFieldValue,
              isValid,
              /* and other goodies */
            }) => (
              <Stack>
                <p style={{ fontSize: '12px', margin: '1', whiteSpace: 'pre-line' }}>
                  {props.milestone.bodyText}
                </p>
                <div style={{ padding: 5 }}>
                  <DateSelect
                    key={1}
                    label={'Insurance Expiry Date'}
                    date={outputs.BINSExpiryDate}
                    setDate={value => {
                      let updatedOutputs = {
                        ...outputs,
                      };
                      value = dayjs(value).utc(true).toISOString()
                      updatedOutputs.BINSExpiryDate = value;
                      setOutputs(updatedOutputs);
                      setFieldValue('BINSExpiryDate', value);
                    }}
                  />
                  <p
                    style={{
                      color: 'red',
                    }}
                  >
                    {errors.BINSExpiryDate}
                  </p>
                </div>
                <Button
                  variant="contained"
                  style={{
                    color: 'white',
                    // height: '40px',
                    // width: '100px',
                    margin: '10px',
                  }}
                  sx={style.darkGreenButton}
                  onClick={submitForm}
                  disabled={!isValid || !outputs.BINSExpiryDate}
                >
                  Submit
                </Button>
              </Stack>
            )}
          </Formik>
        );
        break;
    }
  };

  return (
    <Paper style={style.remortgage}>
      <div>
        <p style={{ fontSize: '16px', margin: '5' }}>
          Buildings insurance expiry date required
        </p>
        <div style={{ display: 'flex' }}>
          <div key={props.index}>{renderStep(step)}</div>
        </div>
      </div>
    </Paper>
  );
};
