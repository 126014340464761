import { SignupType } from "../enums/SignupType";
import { signup } from "../resources/signup-data";

export class SignupUtils {
  static getSignupData(signupType) {
    switch (signupType) {
      case SignupType.LOGIN:
        return signup.login;
      case SignupType.ACTIVATE:
        return signup.activate;
      case SignupType.RESET:
        return signup.reset;
      default:
        return signup.login;
    }
  }
}
