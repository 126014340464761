import { useContext, useEffect, useState } from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';

// mui
import Grid from '@mui/material/Grid';
import { AppBar, Typography } from '@mui/material';
import { CardMedia } from '@mui/material';
import Box from '@mui/material/Box';
import { style } from '../../../style/style';
import MenuIcon from '@mui/icons-material/Menu';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import IconButton from '@mui/material/IconButton';
import Link from '@mui/material/Link';
// data
import { form } from '../../../resources/form-data';

// setting
import { defaultValues } from '../../../config/setting';

//drawer elements used
import Drawer from '@mui/material/Drawer';
import CloseIcon from '@mui/icons-material/Close';

import AppContext from '../../../AppContext';

// utils
import { RemortgageUtils } from '../../../utils/RemortgageUtils';
import { SignupService } from '../../../services/SignupService';

export default function Sidebar(props) {
  const navigate = useNavigate();
  const appContext = useContext(AppContext);
  const [matterReference, setMatterReference] = useState('');
  const [open, setState] = useState(false);
  const [isHovering, setIsHovering] = useState();

  const activeSideBar = bgcolor => {
    return {
      width: '350px',
      borderRadius: '0',
      marginLeft: 'auto',
      marginRight: 'auto',
      '&::before': {
        backgroundColor: bgcolor,
        content: "''",
        position: 'absolute',
        width: '25px',
        height: '25px',
        right: '-12px',
        transform: 'rotate(45deg)',
      },
    };
  };

  const toggleDrawer = (open, index) => event => {
    console.log('index====>', index);
    if (
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }
    //changes the function state according to the value of open
    setState(open);
    if (index) {
      props.setTab(index);
    }
  };

  const handleChange = event => {
    setMatterReference(event.target.value);
    const mortgageSelected = props.mortgages.find(
      m => m.MatterReference === parseInt(event.target.value),
    );
    props.setMortgage(mortgageSelected);
  };

  const handleTabChange = index => {
    // creating a stack with the history from the user when switching tabs
    let stack = [...appContext.stack];
    stack.push(index);
    appContext.setStack(stack);
    props.setTab(index);
    toggleDrawer(false);
  };

  const logout = async () => {
    await SignupService.logout();
    navigate('/landing');
  };

  useEffect(() => {
    if (props.mortgage.MatterReference) {
      setMatterReference(props.mortgage.MatterReference.toString());
    }
  }, [props.mortgage]);
  return (
    <Grid>
      <Box
        style={style.sideBar}
        sx={{
          display: {
            md: 'block',
            lg: 'block',
            xl: 'block',
            xs: 'none',
            sm: 'none',
          },
        }}
      >
        <CardMedia
          style={style.darkLogo}
          alt="Integrar Logo"
          onClick={() => props.setTab(defaultValues.defaultTab)}
        />
        {props.mortgage.Mortgage && (
          <div style={{ padding: '10px' }}>
            <Typography variant="h6" style={{ fontSize: '15px' }}>
              {props.mortgage.Mortgage.PropertyDetails.RemortgageAddressLine1}
            </Typography>
            <Typography
              variant="h6"
              style={{
                fontSize: '12px',
                paddingRight: '10px',
                lineHeight: '1.2',
              }}
            >
              {RemortgageUtils.getBorrowers(props.mortgage.Mortgage.Borrowers)}
            </Typography>
          </div>
        )}
        {/*} {props.mortgages?.length > 1 ? (
          <FormControl
            style={{
              width: "300px",
              paddingTop: "20px",
              paddingBottom: "20px",
            }}
          >
            <Select
              style={{ height: "30px" }}
              value={matterReference}
              onChange={handleChange}
            >
              {props.mortgages?.map((item, index) => (
                <MenuItem
                  style={{ color: "black" }}
                  key={index}
                  value={item.MatterReference}
                >
                  {item.MatterReference}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
              ) : null}*/}
        <List>
          {form.tabs.map((t, index) => (
            <ListItem disablePadding key={t.key}>
              <ListItemButton
                className="sidebarButton"
                style={{
                  paddingTop: 0,
                  paddingBottom: 0,
                  transition: 'backgroundColor .2s ease-out 100ms',
                  backgroundColor: isHovering !== t.key ? t.color : t.hover,
                }}
                sx={
                  props.tab === t.key
                    ? activeSideBar(isHovering !== t.key ? t.color : t.hover)
                    : ''
                }
                onClick={() => handleTabChange(t.key)}
                onMouseEnter={() => setIsHovering(t.key)}
                onMouseLeave={() => setIsHovering(-1)}
              >
                <ListItemText
                  style={{
                    fontWeight: props.tab === t.key ? 900 : 100,
                    textAlign: 'center',
                  }}
                  primary={
                    <Typography variant="tabs">
                      {props.getTitle(t.key)}
                    </Typography>
                  }
                />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </Box>
      <Box
        textAlign="left"
        style={{ padding: '10px' }}
        sx={{
          display: {
            xs: 'none',
            sm: 'none',
            md: 'block',
            lg: 'block',
            xl: 'block',
          },
        }}
      >
        <Link
          underline="none"
          component="button"
          onClick={() => {
            logout();
          }}
        >
          Logout
        </Link>
      </Box>
      {/* mobile menu */}
      <AppBar
        style={style.appBar}
        sx={{ display: { md: 'none', lg: 'none', xl: 'none' } }}
      >
        <CardMedia style={style.darkLogo} alt="Integrar Logo" />
        <IconButton
          edge="start"
          color="inherit"
          aria-label="open drawer"
          onClick={toggleDrawer(true)}
          sx={{
            mr: 2,
            display: {
              md: 'none',
              lg: 'none',
              xl: 'none',
            },
          }}
        >
          <MenuIcon aria-label="Open navigation menu" />
        </IconButton>

        {/* The outside of the drawer */}

        <Drawer
          //from which side the drawer slides in
          anchor="right"
          //if open is true --> drawer is shown
          open={open}
        >
          {/* The inside of the drawer */}
          <Box
            sx={{
              padding: '10px 0',
              height: 1,
              backgroundColor: 'white',
              width: '100vw',
            }}
          >
            <IconButton sx={{ mb: 2 }} onClick={toggleDrawer(false)}>
              <CloseIcon />
            </IconButton>

            <Box sx={{ mb: 2 }}>
              {form.tabs.map(t => (
                <ListItemButton
                  key={t.key}
                  style={t.style}
                  onClick={toggleDrawer(false, t.key)}
                >
                  <ListItemText
                    style={{ textAlign: 'left', fontWeight: '700' }}
                    primary={t.title}
                  />
                </ListItemButton>
              ))}
            </Box>
            <Box textAlign="center" style={{ padding: '10px' }}>
              <Link
                underline="none"
                component="button"
                onClick={() => {
                  logout();
                }}
              >
                Logout
              </Link>
            </Box>
          </Box>
        </Drawer>
      </AppBar>
    </Grid>
  );
}
