import Stack from '@mui/material/Stack';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import React, { useState } from 'react';
import { style } from '../../../../../style/style';
import CssCheckbox from '../components/CssCheckbox';
import DragDropFileDialog from '../../../../dialogs/DragDropFileDialog';
import DragDropFileDialogMilestoneUpload from '../../../../dialogs/DragDropFileDialogMilestoneUpload';
import {
  getAccessToken,
  WebServiceClient,
} from '../../../../../services/WebServiceClient';
import { downloadFile } from '../../../../../utils/DownloadFile';
import { LocalStorageService } from '../../../../../services/LocalStorageService';

const STEPS = Object.freeze({
  initial: 1,
  uploadCopy: 2,
  downloadForm: 3,
  uploadAuthority: 4,
  uploadEvidenceOfChange: 5,
});

export const MarriageCertificateNeeded = props => {
  const [outputs, setOutputs] = useState({
    updateForMe: false,
    willUpload: true,
  });
  const [openDialog, setOpenDialog] = useState(false);
  const [docID, setDocID] = useState('');
  const [step, setStep] = useState(STEPS.initial);
  const onChangeCheckbox = e => {
    const updatedQuestions = { ...outputs };

    updatedQuestions['updateForMe'] = false;
    updatedQuestions['willUpload'] = false;

    updatedQuestions[e.target.name] = !!e.target.value;

    setOutputs(updatedQuestions);
  };
  const org = JSON.parse(LocalStorageService.get('organisation'));
  const renderStep = step => {
    switch (step) {
      case STEPS.initial:
        return (
          <>
            <Stack>
              <p style={{ fontSize: '12px', margin: '1', whiteSpace: 'pre-line' }}>
                {props.milestone.bodyText}
              </p>
              <CssCheckbox
                name="updateForMe"
                label="Please update the Land Registry on my behalf."
                checked={outputs['updateForMe']}
                onClick={e => {
                  onChangeCheckbox(e);
                }}
              />
              <CssCheckbox
                name="willUpload"
                label="I will update Land Registry myself. I will then upload the
updated register from the Land Registry."
                checked={outputs['willUpload']}
                onClick={e => {
                  onChangeCheckbox(e);
                }}
              />
              <Button
                variant="contained"
                style={{
                  color: 'white',
                  // height: '40px',
                  // width: '100px',
                  margin: '10px',
                }}
                sx={style.darkGreenButton}
                onClick={() => {
                  props.submitFnc(
                    props.index,
                    {
                      MarriageCertOption: outputs['updateForMe'] ? 1 : 2,
                    },
                    false,
                    false,
                  );

                  if (outputs['willUpload']) return setStep(STEPS.uploadCopy);
                  if (outputs['updateForMe'])
                    return setStep(STEPS.downloadForm);

                  return setStep(STEPS.uploadCopy);
                }}
              >
                Submit
              </Button>
            </Stack>
          </>
        );
      case STEPS.uploadCopy:
        return (
          <>
            <Stack>
              <p style={{ fontSize: '12px', margin: '1' }}>
                Please upload a copy of the correctly amended register.
              </p>
              <Button
                variant="contained"
                style={{
                  color: 'white',
                  // height: '40px',
                  // width: '100px',
                  marginTop: '10px',
                  marginRight: 'auto',
                }}
                sx={style.darkGreenButton}
                onClick={() => {
                  setOpenDialog(true);
                }}
              >
                Upload Document
              </Button>

              <Dialog open={openDialog}>
                <DialogContent>
                  <DragDropFileDialog
                    documentSelected={'UTR'}
                    handleClose={cancelled => {
                      setOpenDialog(false);
                      if (!cancelled) {
                        return props.submitFnc(props.index, {
                          MarriageCertOption: outputs['updateForMe'] ? 1 : 2,
                        });
                      }
                    }}
                    setIsUpload={() => {}}
                    mortgage={props.mortgage}
                    setRecords={() => {}}
                    records={[]}
                    setExpanded={() => {}}
                  />
                </DialogContent>
              </Dialog>
            </Stack>
          </>
        );
      case STEPS.downloadForm:
        return (
          <>
            <Stack>
              <p style={{ fontSize: '12px', margin: '1' }}>
                Please download the authority form to provide us consent to
                update the Land Registry on your behalf.
              </p>
              <Button
                variant="contained"
                style={{
                  color: 'white',
                  margin: '10px',
                }}
                sx={style.darkGreenButton}
                onClick={async () => {
                  const config = {
                    headers: { Authorization: getAccessToken() },
                  };
                  const params = {
                    orgId: org.id,
                    siteId: null,
                    documentName: props.milestone.toPortal.find(
                      x => x.label === 'Download',
                    ).file,
                  };
                  let ret = await WebServiceClient.get(
                    `/supporting-document/${props.mortgage.Mortgage.id}`,
                    params,
                    config,
                  );

                  if (ret.status != 200) {
                    /// error
                  } else {
                    setDocID(ret.data.id);
                    downloadFile(ret.data.fileUrl, props.milestone.file);
                  }
                }}
              >
                Download
              </Button>
              <Button
                variant="contained"
                style={{
                  color: 'white',
                  margin: '10px',
                }}
                sx={style.darkGreenButton}
                onClick={() => {
                  setStep(STEPS.uploadAuthority);
                }}
              >
                Continue {'>>'}
              </Button>
            </Stack>
          </>
        );
      case STEPS.uploadAuthority:
        return (
          <>
            <Stack>
              <p style={{ fontSize: '12px', margin: '1' }}>
                Please upload the signed authority form.
              </p>
              <Button
                variant="contained"
                style={{
                  color: 'white',
                  // height: '40px',
                  // width: '100px',
                  marginTop: '10px',
                  marginRight: 'auto',
                }}
                sx={style.darkGreenButton}
                onClick={() => {
                  setOpenDialog(true);
                }}
              >
                Upload Document
              </Button>

              <Dialog open={openDialog}>
                <DialogContent>
                  <DragDropFileDialog
                    documentSelected={'LR'}
                    documentType="updatedtitleregister"
                    handleClose={cancelled => {
                      setOpenDialog(false);
                      if (!cancelled) {
                        setStep(STEPS.uploadEvidenceOfChange);
                      }
                    }}
                    setIsUpload={() => {}}
                    mortgage={props.mortgage}
                    setRecords={() => {}}
                    records={[]}
                    setExpanded={() => {}}
                  />
                </DialogContent>
              </Dialog>
            </Stack>
          </>
        );
      case STEPS.uploadEvidenceOfChange:
        return (
          <>
            <Stack>
              <p style={{ fontSize: '12px', margin: '1' }}>
                Please upload any evidence of a name change if required, such as a copy of your Change of Name,
                birth certificate or decree absolute.
              </p>
              <Button
                variant="contained"
                style={{
                  color: 'white',
                  // height: '40px',
                  // width: '100px',
                  marginTop: '10px',
                  marginRight: 'auto',
                }}
                sx={style.darkGreenButton}
                onClick={() => {
                  setOpenDialog(true);
                }}
              >
                Upload Document
              </Button>

              <Dialog open={openDialog}>
                <DialogContent>
                  <DragDropFileDialog
                    documentSelected={'MC'}
                    handleClose={cancelled => {
                      setOpenDialog(false);
                      if (!cancelled) {
                        return props.submitFnc(props.index, {
                          MarriageCertOption: outputs['updateForMe'] ? 1 : 2,
                        });
                      }
                    }}
                    setIsUpload={() => {}}
                    mortgage={props.mortgage}
                    setRecords={() => {}}
                    records={[]}
                    setExpanded={() => {}}
                  />
                </DialogContent>
              </Dialog>
            </Stack>
          </>
        );
    }
  };

  return (
    <Paper style={style.remortgage}>
      <div>
        <p style={{ fontSize: '16px', margin: '5', bold: true }}>
          Marriage Certificate required
        </p>
        <div style={{ display: 'flex' }}>
          <div key={props.index}>{renderStep(step)}</div>
        </div>
      </div>
    </Paper>
  );
};
