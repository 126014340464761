import Stack from '@mui/material/Stack';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import React, { useState } from 'react';
import { style } from '../../../../../style/style';
import CssCheckbox from '../components/CssCheckbox';

const STEPS = Object.freeze({
  initial: 1,
});

export const MoreThanOneLender = props => {
  const [step, setStep] = useState(STEPS.initial);
  const [outputs, setOutputs] = useState({});

  const [checkBoxes, setCheckBoxes] = useState({
    keepMortgage2: false,
    redeemMortgage2: false,
    keepMortgage3: false,
    redeemMortgage3: false,
    keepMortgage4: false,
    redeemMortgage4: false,
  });

  const onChangeCheckbox = (e, i) => {
    const updatedQuestions = { ...checkBoxes };

    updatedQuestions[`keepMortgage${i}`] = false;
    updatedQuestions[`redeemMortgage${i}`] = false;
    updatedQuestions[e.target.name] = true;

    setCheckBoxes(updatedQuestions);

    let newOutputs = { ...outputs };
    if (updatedQuestions[`keepMortgage${i}`]) {
      newOutputs[`Lender${i}Option`] = 1;
    } else if (updatedQuestions[`redeemMortgage${i}`]) {
      newOutputs[`Lender${i}Option`] = 2;
    }
    setOutputs(newOutputs);
  };

  const renderStep = step => {

    let displayMortgageTwoKeep = props.milestone.toPortal?.filter(i => i.dataTag === 'Lender2Option1')?.[0]?.required || false;
    let displayMortgageTwoRedeem = props.milestone.toPortal?.filter(i => i.dataTag === 'Lender2Option2')?.[0]?.required || false;

    let displayMortgageThreeKeep = props.milestone.toPortal?.filter(i => i.dataTag === 'Lender3Option1')?.[0]?.required || false;
    let displayMortgageThreeRedeem = props.milestone.toPortal?.filter(i => i.dataTag === 'Lender3Option2')?.[0]?.required || false;

    let displayMortgageFourKeep = props.milestone.toPortal?.filter(i => i.dataTag === 'Lender4Option1')?.[0]?.required || false;
    let displayMortgageFourRedeem = props.milestone.toPortal?.filter(i => i.dataTag === 'Lender4Option2')?.[0]?.required || false;

    switch (step) {
      case STEPS.initial:
        return (
          <>
            <p style={{ fontSize: '12px', margin: '1', whiteSpace: 'pre-line' }}>
              {props.milestone.bodyText}
            </p>

            {displayMortgageTwoKeep && <CssCheckbox
              name="keepMortgage2"
              label="Yes, I wish to keep the additional mortgage."
              checked={checkBoxes['keepMortgage2']}
              onClick={e => {
                onChangeCheckbox(e, 2);
              }}
            />}
            {displayMortgageTwoRedeem && <CssCheckbox
              name="redeemMortgage2"
              label="No, I require you to redeem the additional mortgage."
              checked={checkBoxes['redeemMortgage2']}
              onClick={e => {
                onChangeCheckbox(e, 2);
              }}
            />}
            {(displayMortgageThreeKeep || displayMortgageThreeRedeem) && <p style={{ fontSize: '12px', margin: '1' }}>
              Mortgage with [Lender 3]
            </p>}
            {displayMortgageThreeKeep && <CssCheckbox
              name="keepMortgage3"
              label="Yes, I wish to keep the additional mortgage."
              checked={checkBoxes['keepMortgage3']}
              onClick={e => {
                onChangeCheckbox(e, 3);
              }}
            />}
            {displayMortgageThreeRedeem && <CssCheckbox
              name="redeemMortgage3"
              label="No, I require you to redeem the additional mortgage."
              checked={checkBoxes['redeemMortgage3']}
              onClick={e => {
                onChangeCheckbox(e, 3);
              }}
            />}

            {(displayMortgageFourKeep || displayMortgageFourRedeem) && <p style={{ fontSize: '12px', margin: '1' }}>
              Mortgage with [Lender 4]
            </p>}
            {displayMortgageFourKeep && <CssCheckbox
              name="keepMortgage4"
              label="Yes, I wish to keep the additional mortgage."
              checked={checkBoxes['keepMortgage4']}
              onClick={e => {
                onChangeCheckbox(e, 4);
              }}
            />}
            {displayMortgageFourRedeem && <CssCheckbox
              name="redeemMortgage4"
              label="No, I require you to redeem the additional mortgage."
              checked={checkBoxes['redeemMortgage4']}
              onClick={e => {
                onChangeCheckbox(e, 4);
              }}
            />}

            <Button
              variant="contained"
              style={{
                color: 'white',
                // height: '40px',
                // width: '100px',
                margin: '10px',
              }}
              sx={style.darkGreenButton}
              onClick={() => {
                return props.submitFnc(props.index, outputs);
              }}
              disabled={
                ((displayMortgageTwoKeep || displayMortgageTwoRedeem) && (!checkBoxes.keepMortgage2 && !checkBoxes.redeemMortgage2)) ||
                ((displayMortgageThreeKeep || displayMortgageThreeRedeem) && (!checkBoxes.keepMortgage3 && !checkBoxes.redeemMortgage3)) ||
                ((displayMortgageFourKeep || displayMortgageFourRedeem) && (!checkBoxes.keepMortgage4 && !checkBoxes.redeemMortgage4))
              }
            >
              Submit
            </Button>
          </>
        );
    }
  };

  return (
    <Paper style={style.remortgage}>
      <div>
        <p style={{ fontSize: '16px', margin: '5' }}>
          More than one current mortgage
        </p>
        <div style={{ display: 'flex' }}>
          <div key={props.index}>{renderStep(step)}</div>
        </div>
      </div>
    </Paper>
  );
};
