import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import { Formik } from 'formik';
import * as yup from 'yup';
import React, { useState } from 'react';
import { style } from '../../../../../style/style';
import CssCheckbox from '../components/CssCheckbox';
import DateSelect from '../components/DateSelect';

import dayjs from 'dayjs';
var utc = require("dayjs/plugin/utc");
dayjs.extend(utc);

const STEPS = Object.freeze({
  initial: 1,
});

export const BINSRenewal = props => {
  const [outputs, setOutputs] = useState({
    Accepted: false,
    NewExpiryDate: null,
  });
  const [step, setStep] = useState(STEPS.initial);

  const schema = yup.object().shape({
    Accepted: yup.boolean().required().isTrue(),
  });

  const renderStep = step => {
    switch (step) {
      case STEPS.initial:
        return (
          <Formik
            initialValues={{
              checked: false,
            }}
            validationSchema={schema}
            onSubmit={values => {
              return props.submitFnc(props.index, outputs);
            }}
          >
            {({
              errors,
              submitForm,
              setFieldValue,
              isValid,
              /* and other goodies */
            }) => (
              <Stack>
                <p style={{ fontSize: '12px', margin: '1', whiteSpace: 'pre-line' }}>
                  {props.milestone.bodyText}
                </p>
                <div style={{ padding: 5 }}>
                  <CssCheckbox
                    key={1}
                    name={'Accepted'}
                    label={'Yes, I have renewed my building insurance policy.'}
                    checked={outputs.Accepted}
                    onClick={e => {
                      e.preventDefault();
                      let updatedOutputs = {
                        ...outputs,
                      };
                      updatedOutputs[e.target.name] =
                        !updatedOutputs[e.target.name];
                      setFieldValue(
                        e.target.name,
                        updatedOutputs[e.target.name],
                      );
                      setOutputs(updatedOutputs);
                    }}
                  />
                  <p
                    style={{
                      color: 'red',
                    }}
                  >
                    {errors.Accepted}
                  </p>
                </div>

                <div style={{ padding: 5 }}>
                  <DateSelect
                    key={2}
                    label={'New Expiry Date'}
                    date={outputs.NewExpiryDate}
                    setDate={value => {
                      let updatedOutputs = {
                        ...outputs,
                      };

                      value = dayjs(value).utc(true).toISOString()
                      updatedOutputs.NewExpiryDate = value
                      setOutputs(updatedOutputs);
                      setFieldValue('NewExpiryDate', updatedOutputs);
                    }}
                  />
                  {errors.NewExpiryDate}
                </div>
                <Button
                  variant="contained"
                  style={{
                    color: 'white',
                    // height: '40px',
                    // width: '100px',
                    margin: '10px',
                  }}
                  sx={style.darkGreenButton}
                  onClick={submitForm}
                  disabled={!isValid || !outputs.NewExpiryDate}
                >
                  Submit
                </Button>
              </Stack>
            )}
          </Formik>
        );
        break;
    }
  };

  return (
    <Paper style={style.remortgage}>
      <div>
        <p style={{ fontSize: '16px', margin: '3', bold: true }}>
          Buildings insurance renewal date required
        </p>
        <div style={{ display: 'flex' }}>
          <div key={props.index}>{renderStep(step)}</div>
        </div>
      </div>
    </Paper>
  );
};
