// React
import React, { useState, useEffect } from "react";

// Appt
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
//icons
import ThumbUpOffAltIcon from "@mui/icons-material/ThumbUpOffAlt";

// style
import { style } from "../../../../style/style";
import { color } from "../../../../style/color";

function QuestionOption(props) {
  const [selected, setSelected] = useState();
  const handlerOption = (action, index) => {
    setSelected(index);
    props.setFormValuesMulti((prevState) => ({
      ...prevState,
      [props.question.name]: action.when,
    }));
    props.getAction(action);
    props.handleOnChange(action.when, props.question);
  };

  const getColor = (selected) => {
    if (props.formValues) {
      if (props.question.questionId === 46) {
        if (
          props.formValues["AddingRemovingLegalOwnerValue"] &&
          props.formValues["AddingRemovingLegalOwnerValue"] === selected
        ) {
          return color.brandDarkGreen;
        }
      } else {
        if (
          props.formValues[props.question.name] &&
          props.formValues[props.question.name] === selected
        ) {
          return color.brandDarkGreen;
        }
      }
    }
    return "";
  };

  return (
    <Box sx={style.optionButtonRow}>
      {props.question.actions.map((a, index) => (
        <IconButton
          sx={style.optionButton}
          onClick={() => handlerOption(a, index)}
          style={{
            backgroundColor: getColor(a.when),
          }}
        >
          {a.icon}
          {a.label}
        </IconButton>
      ))}
    </Box>
  );
}

export default QuestionOption;
