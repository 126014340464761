import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import React, { useState } from 'react';
import { style } from '../../../../../style/style';
import DragDropFileDialog from '../../../../dialogs/DragDropFileDialog';
import CssCheckbox from '../components/CssCheckbox';
import Paper from '@mui/material/Paper';

const STEPS = Object.freeze({
  initial: 1,
  upload: 2,
});

export const PropertyIsBuyToLet = props => {
  const [outputs, setOutputs] = useState({
    noTenant: false,
    willUpload: false,
  });
  const [openDialog, setOpenDialog] = useState(false);
  const onChangeCheckbox = e => {
    const updatedQuestions = { ...outputs };

    updatedQuestions['noTenant'] = false;
    updatedQuestions['willUpload'] = false;

    updatedQuestions[e.target.name] = !!e.target.value;

    setOutputs(updatedQuestions);
  };

  const [step, setStep] = useState(STEPS.initial);

  const renderStep = step => {
    switch (step) {
      case STEPS.initial:
        return (
          <>
            <p style={{ fontSize: '12px', margin: '1', whiteSpace: 'pre-line' }}>
              {props.milestone.bodyText}
            </p>
            <p style={{ fontSize: '12px', margin: '1' }}>
              Please can you choose one of the following options below:
            </p>
            <CssCheckbox
              name="noTenant"
              label="There is currently no tenant in the property."
              checked={outputs['noTenant']}
              onClick={e => {
                onChangeCheckbox(e);
              }}
            />
            <CssCheckbox
              name="willUpload"
              label="I will upload a copy of my Assured Shorthold Tenancy."
              checked={outputs['willUpload']}
              onClick={e => {
                onChangeCheckbox(e);
              }}
            />
            <Button
              variant="contained"
              style={{
                color: 'white',
                margin: '10px',
              }}
              sx={style.darkGreenButton}
              onClick={() => {
                let localOutputs = {
                  PropertyIsBuyToLetOption: outputs.noTenant ? '1' : '2',
                };

                if (outputs['noTenant']) {
                  return props.submitFnc(props.index, localOutputs);
                }

                return setStep(STEPS.upload);
              }}
              disabled={!outputs.noTenant && !outputs.willUpload}
            >
              Submit
            </Button>
          </>
        );
      case STEPS.upload:
        return (
          <>
            <p style={{ fontSize: '12px', margin: '0' }}>
            Please upload a copy of the current Assured Shorthold Tenancy Agreement for this property
            </p>

            <Dialog open={openDialog}>
              <DialogContent>
                <DragDropFileDialog
                  documentSelected="TA"
                  handleClose={cancelled => {
                    setOpenDialog(false);
                    let localOutputs = {
                      PropertyIsBuyToLetOption: outputs.noTenant ? '1' : '2',
                    };
                    if (!cancelled) {
                      return props.submitFnc(props.index, localOutputs);
                    }
                  }}
                  setIsUpload={() => {}}
                  mortgage={props.mortgage}
                  setRecords={() => {}}
                  records={[]}
                  setExpanded={() => {}}
                />
              </DialogContent>
            </Dialog>

            <Button
              variant="contained"
              style={{
                color: 'white',
                margin: '10px',
              }}
              sx={style.darkGreenButton}
              onClick={() => {
                setOpenDialog(true);
              }}
            >
              Upload Document
            </Button>
          </>
        );
    }
  };

  return (
    <Paper style={style.remortgage}>
      <div>
        <p style={{ fontSize: '16px', margin: '3', bold: true }}>
          {' '}
          Copy current tenancy agreement required
        </p>
        <div style={{ display: 'flex' }}>
          <div key={props.index}>{renderStep(step)}</div>
        </div>
      </div>
    </Paper>
  );
};
