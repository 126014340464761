// React
import React from "react";

// Appt
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

// style
import { color } from "../../../../style/color";

function QuestionSelect(props) {
    const handleChange = (event) => {
        props.setFormValuesMulti((prevState) => ({
            ...prevState,
            [props.question.name]: event.target.value,
        }));
        props.handleOnChange(event.target.value, props.question);
    };
    const getValue = () => {
        if (
            props.formValues !== undefined &&
            props.formValues[props.question.name] !== null &&
            props.formValues[props.question.name] !== undefined &&
            props.formValues[props.question.name] !== ""
        ) {
            return props.formValues[props.question.name];
        }
        return "";
    };

    return (
        <FormControl fullWidth>
            <Select
                IconComponent={KeyboardArrowDownIcon}
                value={getValue()}
                onChange={handleChange}
                displayEmpty
                onClick={(e) => {
                    e.stopPropagation();
                }}
                sx={
                    props.question?.matchTextBox
                        ? { ...matchTextBoxStyle, ...props?.style }
                        : {
                            borderRadius: 3,
                            backgroundColor: color.brandDarkBlue,
                            color: "white",
                            "& .MuiSelect-icon": {
                                color: "white",
                            },
                            ...props?.style,
                        }
                }
                renderValue={(selected) => {
                    if (selected.length === 0) {
                        return (
                            <p style={{ color: "#979797", fontWeight: 600 }}>
                                {props.question.questionText}
                            </p>
                        );
                    }

                    return typeof getValue() === "string" ? getValue() : getValue().label;
                }}
            >
                {props.question.actions.map((a) => (
                    <MenuItem sx={{ color: "black" }} value={a}>
                        {a.label}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
}

// this makes it look the same as the text boxes so the styling doesn't look different,
// it only looks like this if a prop is passed called matchTextBox so the rest will look like they used to
const matchTextBoxStyle = {
    paddingTop: "0px",
    width: 300,
    height: 40,
    color: "#070606",
    fontWeight: 600,
    "& fieldset": {
        border: "2px solid #418ED1",
        borderRadius: "6px",
        boxShadow: "0 0 2px 1px #418ED1",
    },
    "&:hover": {
        "&& fieldset": {
            border: "2px solid #418ED1",
            borderRadius: "6px",
            boxShadow: "0 0 2px 1px #418ED1",
        },
    },
    "&.Mui-focused": {
        "&& fieldset": {
            border: "2px solid #418ED1",
            borderRadius: "6px",
            boxShadow: "0 0 2px 1px #418ED1",
        },
    },
};

export default QuestionSelect;
